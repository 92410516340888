export default {
  hexRadius: 30,
  hexLineWeight: 2,
  hexDoubleLineOffset: 0.3,
  hexMargin: 3,
  gridColor: '#f4f5f6',
  mouseColor: '#e0e1e3',
  mouseActiveColor: '#d5d7d9',
  mouseEraseColor: '#ffffff',
  mouseEraseActiveColor: 'rgba(249, 250, 251, 0.8)',
  mouseEditColor: 'rgba(255, 255, 255, 0)',
  mouseEditActiveColor: '#e0e1e3',
  inactiveColor: '#ffffff',
  activeColor: '#f8f9fa',
  doubleActiveColor: '#e4e5e7',
  lineColor: '#000000',
  lineColorFaded: 'rgba(0, 0, 0, 0.2)',
  cameraSpinSpeed: 50,
  cameraRotateSpeed: 5,
  cameraDampingFactor: 0.2,
  cameraMinDistance: 30,
  cameraDistance: 120,
  cameraMaxDistance: 500,
  animationSpeed: 1.5,
  animationStep: 8 * 6,
  animationRangeMax: 20 * 8 * 6,
  wireLengthPerCm3: 300,
};

export const demoModelConfig = {
  tubeSegments: 20,
  tubeRadiusSegments: 8,
  includeSpruePoints: true,
  includeSprues: false,
};

export const exportModelConfig = {
  tubeSegments: 30,
  tubeRadiusSegments: 20,
  includeSpruePoints: false,
  includeSprues: true,
};
