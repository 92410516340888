import React from 'react';
import PropTypes from 'prop-types';

const Facebook = ({ className }) => (
  <svg className={className} viewBox={'0 0 24 24'}>
    <path
      d={
        'M21.8,1H2.2C1.5,1,1,1.5,1,2.2v19.6C1,22.5,1.5,23,2.2,23h10.5v-8.5H9.9v-3.3h2.9V8.7c0-2.8,1.7-4.4,4.3-4.4 c1.2,0,2.3,0.1,2.6,0.1v3l-1.8,0c-1.4,0-1.6,0.7-1.6,1.6v2.1h3.3L19,14.5h-2.9V23h5.6c0.7,0,1.2-0.5,1.2-1.2V2.2 C23,1.5,22.5,1,21.8,1z'
      }
    />
  </svg>
);

Facebook.propTypes = {
  className: PropTypes.string,
};

export default Facebook;
