import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import AppProvider from './AppProvider';
import { unregister } from './registerServiceWorker';
unregister();

Sentry.init({
  dsn:
    'https://9a6b1c719f234170a301164ad19a1e5a@o499241.ingest.sentry.io/5577547',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(<AppProvider />, document.getElementById('root'));
