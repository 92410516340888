import { action, reaction } from 'mobx';
import { TOOL_MODES } from 'constants/systemOptions';

class GTMTrackingStore {
  constructor(systemStore) {
    this.systemStore = systemStore;
    this.TagManager = undefined;
    this.dataLayer = undefined;
  }

  initialize(TagManager) {
    this.TagManager = TagManager;
    this.dataLayer = window.dataLayer;

    // track initial state and any changes to settings
    // debounced by a second so slider changes aren't over-registered
    this.settingsReaction = reaction(
      () => this.systemStore.settings.trackableSettings,
      (settings) => {
        this.trackEvent('settingsChange', settings);
      },
      {
        fireImmediately: true,
        delay: 1000,
      }
    );

    // track drawing time incl length of interaction
    this.canvasMouseReaction = reaction(
      () => this.systemStore.ui.lastMouseDownTimeTaken,
      (time) => {
        this.trackEvent('mouseDownOverCanvas', {
          timeElapsed: time / 1000,
          toolMode:
            this.systemStore.ui.lastMouseButton == 2
              ? TOOL_MODES.ERASE
              : this.systemStore.settings.toolMode, // todo: refactor actual tool mode
        });
      }
    );
  }

  @action
  trackEvent = (eventName, additionalProps) => {
    this.addToDataLayer({
      event: eventName,
      ...additionalProps,
    });
  };

  addToDataLayer = (data) => {
    if (this.dataLayer) this.dataLayer.push(data);
  };
}

export default GTMTrackingStore;
