import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  getMaterialLabel,
  getRenderMaterial,
  formatDimensions,
} from 'utils/settingsUtils';
import { formatAsGBP } from 'utils/currencyUtils';
import { pluraliseProductType } from 'utils/urlUtils';

import styles from './ProductListItem.sass';

import CloudinaryImage from 'components/common/CloudinaryImage';

const addButtonClasses = classNames({
  [styles.quantityButton]: true,
  [styles.quantityButtonAdd]: true,
});
const removeButtonClasses = classNames({
  [styles.quantityButton]: true,
  [styles.quantityButtonRemove]: true,
});

const ProductListItem = ({ product, onProductQuantityChange }) => (
  <div className={styles.grid}>
    {product.design.media && (
      <figure className={styles.imageWrapper}>
        <Link
          to={
            product.design.name
              ? `/shop/${pluraliseProductType(product.design.productType)}/${
                product.design.slug
              }`
              : `/app/${product.design.slug}`
          }
        >
          <CloudinaryImage
            data={product.design.media[getRenderMaterial(product.material)]}
            className={styles.image}
          />
        </Link>
      </figure>
    )}

    <div className={styles.productDetails}>
      <h3 className={styles.productName}>
        {product.design.name ? product.design.name : 'Custom Design'}
      </h3>
      <p className={styles.productMaterial}>
        {getMaterialLabel(product.material)} with 18&Prime; chain
      </p>
      <p className={styles.productDimensions}>
        Pendant measures {formatDimensions(product.design.dimensions)}
      </p>
    </div>

    <div className={styles.productQuantity}>
      {onProductQuantityChange && (
        <button
          onClick={() => onProductQuantityChange(product, -1)}
          className={removeButtonClasses}
        >
          Remove
        </button>
      )}
      <span className={styles.quantity}>{product.quantity || 1}</span>
      {onProductQuantityChange && (
        <button
          onClick={() => onProductQuantityChange(product, 1)}
          className={addButtonClasses}
        >
          Add
        </button>
      )}
    </div>
    <div className={styles.productPrice}>{formatAsGBP(product.price)}</div>
    <div className={styles.productSubtotal}>
      {formatAsGBP(product.price * (product.quantity || 1))}
    </div>
  </div>
);

ProductListItem.propTypes = {
  product: PropTypes.object.isRequired,
  onProductQuantityChange: PropTypes.func,
};

export default ProductListItem;
