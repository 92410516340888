import * as THREE from 'three';
import { saveAs } from 'file-saver';
import { PIXEL_RATIO } from 'constants/ui';
import { exportModelConfig } from '../config';
import { LIVE_MATERIAL_OPTIONS } from 'constants/systemOptions';

export const downloadPNG = (demo) => {
  const pngData = demo.canvas.toDataURL();
  let link = document.createElement('a');
  link.download = 'hexatope.png';
  link.href = pngData;
  link.click();
};

export const downloadSTL = async (demo) => {
  // bundle dependency seperately
  const {
    default: STLExporter,
  } = await import(/* webpackChunkName: "STLExporter" */ '../vendor/STLExporter');

  const exporter = new STLExporter();
  const meshes = demo.generateMeshes(exportModelConfig).children[0];
  let exportGeometry = new THREE.Geometry();
  meshes.children.forEach((mesh) =>
    exportGeometry.merge(new THREE.Geometry().fromBufferGeometry(mesh.geometry))
  );

  const exportMesh = new THREE.Mesh(exportGeometry, demo.getMaterial(false));

  const blob = new Blob([exporter.parse(exportMesh)], {
    type: 'application/sla',
  });
  saveAs(blob, 'hexatope.stl');
};

export const downloadGIF = async (demo) => {
  // bundle dependency seperately
  const GIF = await import(/* webpackChunkName: "gif" */ '../vendor/gif');

  const currentDimensions = demo.store.ui.demoBoundingBox;
  const dimensions = {
    width: 600,
    height: 600,
  };

  demo.updateDimensions(null, {
    width: dimensions.width / PIXEL_RATIO,
    height: dimensions.height / PIXEL_RATIO,
  });
  demo.renderer.setClearColor(0xf9f9f9, 1);

  const gif = new GIF({
    workers: 2,
    workerScript: '/gif.worker.js',
    quality: 1,
    width: dimensions.width,
    height: (dimensions.height * 2) / 3,
  });

  const image = document.createElement('img');
  const canvas = document.createElement('canvas');
  canvas.width = dimensions.width;
  canvas.height = dimensions.height;
  const c = canvas.getContext('2d');

  const angles = Array.from({ length: 80 }, (v, k) => (-k / 40) * Math.PI);

  let frameCounter = 0;
  const addFrame = (angle) => {
    demo.controls.resetAtAngle(angle);

    demo.renderer.render(demo.scene, demo.camera);

    image.onload = () => {
      c.drawImage(image, 0, 0);
      gif.addFrame(
        c.getImageData(0, 80, dimensions.width, (dimensions.height * 2) / 3),
        {
          delay: 50,
        }
      );

      frameCounter++;
      if (frameCounter === angles.length) {
        return finishGif();
      }
      addFrame(angles[frameCounter]);
    };

    image.src = demo.canvas.toDataURL();
  };

  const finishGif = () => {
    gif.render();

    // unset size and clear color
    demo.updateDimensions(null, currentDimensions);
    demo.renderer.setClearColor(0xffffff, 0);
    demo.controls.resetAtAngle(0);
  };

  // begin loop
  addFrame(angles[frameCounter]);

  gif.on('finished', (blob) => {
    saveAs(blob, 'hexatope.gif');
  });
};

export const exportImages = (demo, onlyCurrentMaterial) => {
  // save current material
  const currentMaterial = demo.store.settings.material;
  const currentDimensions = demo.store.ui.demoBoundingBox;
  const imageData = {};
  const possibleMaterials = onlyCurrentMaterial
    ? [
      {
        value: currentMaterial,
        renderMaterial: demo.store.settings.renderMaterial,
      },
    ]
    : LIVE_MATERIAL_OPTIONS;

  // filter out duplicate render materials
  let renderMaterials = [];
  let materials = [];
  possibleMaterials.forEach((material) => {
    if (renderMaterials.indexOf(material.renderMaterial) >= 0) return;
    renderMaterials.push(material.renderMaterial);
    materials.push(material);
  });

  // reset angle
  demo.controls.resetAtAngle(0);

  // set size to 1000px square
  // we have to multiply the zoom by the pixel ratio so we don't have massively zoomed in images
  // I don't know why it works this way either
  demo.updateDimensions(
    null,
    {
      width: 1000 / PIXEL_RATIO,
      height: 1000 / PIXEL_RATIO,
    },
    PIXEL_RATIO
  );

  // loop through materials
  materials.forEach((opt) => {
    demo.store.settings.setMaterial(opt.value);
    demo.updateCurves();
    demo.renderer.render(demo.scene, demo.camera);
    imageData[opt.renderMaterial] = demo.canvas.toDataURL();
  });

  // unset size
  demo.updateDimensions(null, currentDimensions);

  // return to current material
  demo.store.settings.setMaterial(currentMaterial);
  return imageData;
};
