import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { TOOL_MODES, GRID_ROTATION } from 'constants/systemOptions';

import styles from './CanvasSettings.sass';

@inject('SystemStore', 'DesignStore', 'GTMTracking')
@observer
class CanvasSettings extends Component {
  constructor(props) {
    super(props);
  }

  onDrawButtonClicked = () => {
    this.props.SystemStore.settings.setModeToDraw();
  };

  onEditButtonClicked = () => {
    this.props.SystemStore.settings.setModeToEdit();
  };

  onEraseButtonClicked = () => {
    this.props.SystemStore.settings.setModeToErase();
  };

  onClearButtonClicked = () => {
    this.props.GTMTracking.trackEvent('clickClearCanvas');
    const { SystemStore } = this.props;
    SystemStore.clearHexagons();
    SystemStore.ui.canvasHasBeenCleared();
    SystemStore.settings.setModeToDraw();
  };

  onRotationVerticalButtonClicked = () => {
    this.props.SystemStore.settings.setGridRotation(GRID_ROTATION.VERTICAL);
  };

  onRotationHorizontalButtonClicked = () => {
    this.props.SystemStore.settings.setGridRotation(GRID_ROTATION.HORIZONTAL);
  };

  onUndoButtonClicked = () => {
    this.props.SystemStore.undo();
  };

  onRedoButtonClicked = () => {
    this.props.SystemStore.redo();
  };

  render() {
    const { SystemStore } = this.props;
    const { toolMode, gridRotation } = SystemStore.settings;

    const toolButtonClasses = (buttonMode) =>
      classNames({
        [styles.button]: true,
        [styles.buttonActive]: toolMode === buttonMode,
      });

    const rotationButtonClasses = (rotation) =>
      classNames({
        [styles.button]: true,
        [styles.buttonActive]: gridRotation === rotation,
      });

    const historyButtonClasses = (disabled) =>
      classNames({
        [styles.button]: true,
        [styles.buttonDisabled]: disabled,
      });

    return (
      <div className={styles.settings}>
        <div className={styles.settingsGroup}>
          <legend className={styles.settingsGroupTitle}>Drawing modes</legend>
          <div className={styles.settingsGroupContent}>
            <div className={styles.buttonGroup}>
              <button
                className={toolButtonClasses(TOOL_MODES.DRAW)}
                onClick={this.onDrawButtonClicked}
              >
                Draw
              </button>
              <button
                className={toolButtonClasses(TOOL_MODES.EDIT)}
                onClick={this.onEditButtonClicked}
              >
                Edit
              </button>
              <button
                className={toolButtonClasses(TOOL_MODES.ERASE)}
                onClick={this.onEraseButtonClicked}
              >
                Erase
              </button>
            </div>
            <button
              className={styles.button}
              onClick={this.onClearButtonClicked}
            >
              Clear
            </button>
          </div>
        </div>

        <div className={styles.settingsGroup}>
          <legend className={styles.settingsGroupTitle}>Orientation</legend>
          <div className={styles.settingsGroupContent}>
            <div className={styles.buttonGroup}>
              <button
                className={rotationButtonClasses(GRID_ROTATION.VERTICAL)}
                onClick={this.onRotationVerticalButtonClicked}
                title={'Columns'}
              >
                <svg viewBox={'0 0 18 18'} className={styles.icon}>
                  <polygon
                    strokeMiterlimit={'10'}
                    points={'13,2.1 5,2.1 1,9 5,15.9 13,15.9 17,9'}
                  />
                </svg>
              </button>
              <button
                className={rotationButtonClasses(GRID_ROTATION.HORIZONTAL)}
                onClick={this.onRotationHorizontalButtonClicked}
                title={'Rows'}
              >
                <svg viewBox={'0 0 18 18'} className={styles.icon}>
                  <polygon
                    strokeMiterlimit={'10'}
                    points={'2.1,5 2.1,13 9,17 15.9,13 15.9,5 9,1'}
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className={styles.settingsGroup}>
          <div className={styles.settingsGroupContent}>
            <div className={styles.buttonGroup}>
              <button
                onClick={this.onUndoButtonClicked}
                className={historyButtonClasses(
                  !SystemStore.history.historyExists
                )}
              >
                Undo
              </button>
              <button
                onClick={this.onRedoButtonClicked}
                className={historyButtonClasses(
                  !SystemStore.history.futureExists
                )}
              >
                Redo
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CanvasSettings.propTypes = {
  SystemStore: PropTypes.object,
  DesignStore: PropTypes.object,
  GTMTracking: PropTypes.object,
};

export default CanvasSettings;
