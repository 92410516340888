import React from 'react';

import styles from './Contact.sass';

import GenericHeader from 'components/common/banners/GenericHeader';

const Contact = () => (
  <div>
    <GenericHeader title={'Contact'} />

    <div className={styles.wrapper}>
      <div className={styles.contactDetails}>
        <section className={styles.contactSection}>
          <h3 className={styles.contactSectionTitle}>Email</h3>
          <dt>Order enquiries:</dt>
          <dd className={styles.contactEmail}>
            <a href={'mailto:orders@hexatope.io'}>orders@hexatope.io</a>
          </dd>
          <dt>Press and general enquiries:</dt>
          <dd className={styles.contactEmail}>
            <a href={'mailto:hello@hexatope.io'}>hello@hexatope.io</a>
          </dd>
        </section>

        <section className={styles.contactSection}>
          <h3 className={styles.contactSectionTitle}>Studio Address</h3>
          <p>
            Hexatope<br />
            Unit 18<br />
            Containerville<br />
            1 Emma St<br />
            London<br />
            E2 9FP<br />
            United Kingdom
          </p>
          <p className={styles.studioVisits}>
            Visits can be made via appointment only, please email{' '}
            <a href={'mailto:hello@hexatope.io'}>hello@hexatope.io</a>.
          </p>
        </section>
      </div>

      <div className={styles.social}>
        <h3 className={styles.socialTitle}>Follow us on social media:</h3>
        <ul className={styles.socialList}>
          <li>
            <a href={'https://twitter.com/hexatope'}>Hexatope on Twitter</a>
          </li>
          <li>
            <a href={'https://twitter.com/charlotte_dann'}>
              Charlotte on Twitter
            </a>
          </li>
          <li>
            <a href={'https://instagram.com/hexatope'}>Hexatope on Instagram</a>
          </li>
          <li>
            <a href={'https://instagram.com/charlottedann'}>
              Charlotte on Instagram
            </a>
          </li>
        </ul>
        <iframe
          src={
            'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fhexatope%2F&tabs&width=300&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=149978141742551'
          }
          width={'100%'}
          height={130}
          style={{ border: 'none', overflow: 'hidden' }}
          scrolling={'no'}
          frameBorder={0}
        />
      </div>
    </div>
  </div>
);

export default Contact;
