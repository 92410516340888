import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CountriesList from 'country-list';

import InputElement from 'components/common/forms/InputElement';
import CheckboxElement from 'components/common/forms/CheckboxElement';
import LoadingButton from 'components/common/buttons/LoadingButton';
import PaymentMethods from './PaymentMethods';
import OrderSummary from './OrderSummary';
import OrderCompleted from './OrderCompleted';

import styles from './CheckoutForm.sass';

const countryData = new CountriesList().getData();
const customerInfoPattern = {
  firstName: '',
  lastName: '',
  email: '',
  shippingAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'GB',
  },
};

@inject('CartStore', 'OrderStore', 'GTMTracking')
@observer
class Checkout extends Component {
  @observable customerInfo = customerInfoPattern;
  @observable cardElementFocused = false;
  @observable agreedToTerms = false;
  @observable newsletterSignup = false;
  @observable orderIsProcessing = false;
  @observable errorHasOccured = false;
  @observable successfulOrderDetails = undefined;
  @observable successfulCartSummary = undefined;

  constructor(props) {
    super(props);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.agreedToTerms) return;

    this.orderIsProcessing = true;
    await this.createOrder();
    this.orderIsProcessing = false;
  };

  createOrder = async () => {
    const { CartStore, OrderStore, GTMTracking } = this.props;
    // update address object with country prop
    this.customerInfo.shippingAddress.country = CartStore.shippingCountry;

    const products = CartStore.products.map((product) => ({
      material: product.material,
      price: product.price,
      design: product.design._id,
    }));
    const formatAddress = (addr) =>
      [
        addr.line1,
        addr.line2,
        addr.city,
        addr.state,
        addr.postal_code,
        new CountriesList().getName(addr.country),
      ]
        .filter((part) => !!part)
        .join('\n');

    const data = {
      customer: {
        firstName: this.customerInfo.firstName,
        lastName: this.customerInfo.lastName,
        email: this.customerInfo.email,
        shippingAddress: formatAddress(this.customerInfo.shippingAddress),
        newsletterSignup: this.newsletterSignup,
      },
      products: products,
      shippingCost: CartStore.shippingCost,
      totalCost: CartStore.totalCost,
      discount: CartStore.discount.id,
      stripeData: {
        amount: Math.round(CartStore.totalCost * 100), // in pennies
        currency: 'gbp',
        payment_method_types: ['card'],
        description: CartStore.orderDescription,
        shipping: {
          address: this.customerInfo.shippingAddress,
          name: `${this.customerInfo.firstName} ${this.customerInfo.lastName}`,
        },
      },
    };

    let apiResult;
    try {
      apiResult = await OrderStore.createOrder(data);
    } catch (err) {
      this.errorHasOccured = true;
      return;
    }

    const { order, clientSecret } = apiResult;

    // make payment
    if (!CartStore.complimentaryOrder) {
      const { stripe, elements } = this.props;
      const cardElement = elements.getElement(CardElement);

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement },
      });

      if (error) {
        return (this.errorHasOccured = true);
      }
    }

    this.successfulOrderDetails = order;

    // add purchase to gtm
    GTMTracking.trackEvent('ecommerce', {
      ecommerce: {
        purchase: {
          actionField: {
            id: order.orderNumber,
            revenue: order.totalCost,
            tax: '0',
            shipping: order.shippingCost,
            coupon: CartStore.discount.id ? CartStore.discount.code : undefined,
          },
          products: order.products.map((product) => ({
            name: product.design.name,
            id: product.design._id,
            price: product.price.toString(),
            category: product.design.productType,
            variant: product.material,
            quantity: 1,
          })),
        },
      },
    });

    // save current state of cart for completed summary
    this.successfulCartSummary = CartStore.getCartSummary();
    CartStore.emptyCart();

    // window.fbq('track', 'Purchase', {
    //   content_name: '{{Purchase Product 1 Name}}',
    //   content_category: '{{Purchase Product 1 Category}}',
    //   content_type: 'product',
    //   value: parseFloat('{{Purchase Product 1 Price}}'),
    //   currency: 'GBP',
    //   content_ids: ['{{Purchase Product 1 ID}}-{{Purchase Product 1 Variant}}'],
    // });
  };

  render() {
    const { CartStore } = this.props;
    const shippingCountry = CartStore.shippingCountry;
    const { shippingAddress, firstName, lastName, email } = this.customerInfo;

    const cardWrapperClasses = classNames({
      [styles.cardWrapper]: true,
      [styles.cardWrapperFocused]: this.cardElementFocused,
    });

    return (
      <form className={styles.checkout} onSubmit={(e) => this.handleSubmit(e)}>
        <div className={styles.summary}>
          <OrderSummary
            cart={
              this.successfulCartSummary
                ? this.successfulCartSummary
                : CartStore
            }
          />
        </div>

        {this.successfulOrderDetails ? (
          <div className={styles.main}>
            <OrderCompleted {...this.successfulOrderDetails} />
          </div>
        ) : (
          <div className={styles.main}>
            <section className={styles.formSection}>
              <h2 className={styles.sectionTitle}>Your Details</h2>

              <div className={styles.fieldRow}>
                <InputElement
                  name={'firstName'}
                  label={'First Name'}
                  value={firstName}
                  onChange={(e) => {
                    this.customerInfo.firstName = e.target.value;
                  }}
                  required={true}
                  className={styles.inputField}
                />
                <InputElement
                  name={'lastName'}
                  label={'Last Name'}
                  value={lastName}
                  onChange={(e) => {
                    this.customerInfo.lastName = e.target.value;
                  }}
                  required={true}
                  className={styles.inputField}
                />
              </div>

              <InputElement
                name={'email'}
                label={'Email'}
                type={'email'}
                value={email}
                onChange={(e) => {
                  this.customerInfo.email = e.target.value;
                }}
                required={true}
                className={styles.inputField}
              />

              <CheckboxElement
                name={'newsletter'}
                value={this.newsletterSignup}
                onChange={(e) => {
                  this.newsletterSignup = e.target.checked;
                }}
                className={styles.newsletterField}
              >
                Sign up to our newsletter
              </CheckboxElement>
            </section>
            <section className={styles.formSection}>
              <h2 className={styles.sectionTitle}>Shipping Details</h2>
              <InputElement
                name={'line1'}
                label={'Address Line 1'}
                value={shippingAddress.line1}
                onChange={(e) => {
                  this.customerInfo.shippingAddress.line1 = e.target.value;
                }}
                required={true}
                className={styles.inputField}
              />
              <InputElement
                name={'line2'}
                label={'Address Line 2'}
                value={shippingAddress.line2}
                onChange={(e) => {
                  this.customerInfo.shippingAddress.line2 = e.target.value;
                }}
                className={styles.inputField}
              />
              <InputElement
                name={'city'}
                label={'City'}
                value={shippingAddress.city}
                onChange={(e) => {
                  this.customerInfo.shippingAddress.city = e.target.value;
                }}
                required={true}
                className={styles.inputField}
              />
              <div className={styles.fieldRow}>
                {(shippingCountry === 'US' || shippingCountry === 'GB') && (
                  <InputElement
                    name={'state'}
                    label={shippingCountry === 'US' ? 'State' : 'County'}
                    value={shippingAddress.state}
                    onChange={(e) => {
                      this.customerInfo.shippingAddress.state = e.target.value;
                    }}
                    required={true}
                    className={styles.inputField}
                  />
                )}
                <InputElement
                  name={'postalcode'}
                  label={
                    shippingCountry === 'US'
                      ? 'ZIP'
                      : shippingCountry === 'GB'
                      ? 'Postcode'
                      : 'Postal Code'
                  }
                  value={shippingAddress.postal_code}
                  onChange={(e) => {
                    this.customerInfo.shippingAddress.postal_code =
                      e.target.value;
                  }}
                  required={true}
                  className={styles.inputField}
                />
              </div>
              <InputElement
                type={'select'}
                name={'country'}
                value={shippingCountry}
                label={'Country'}
                required={true}
                onChange={(e) => {
                  if (shippingCountry === 'US' && e.target.value !== 'US') {
                    this.customerInfo.shippingAddress.state = '';
                  }
                  CartStore.setShippingCountry(e.target.value);
                }}
                className={styles.inputField}
              >
                {countryData.map((country, i) => (
                  <option key={i} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </InputElement>
            </section>

            {!CartStore.complimentaryOrder && (
              <section className={styles.formSection}>
                <h2 className={styles.sectionTitle}>Payment Details</h2>
                <div className={cardWrapperClasses}>
                  <CardElement
                    className={styles.card}
                    onFocus={() => {
                      this.cardElementFocused = true;
                    }}
                    onBlur={() => {
                      this.cardElementFocused = false;
                    }}
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontFamily: 'Ratio',
                        },
                      },
                    }}
                  />
                </div>

                <div className={styles.paymentMethods}>
                  <PaymentMethods />
                </div>
              </section>
            )}

            {this.errorHasOccured && (
              <p className={styles.error}>
                An error has occured while processing your order.
                <br />
                Please contact hello@hexatope.io if this continues to occur.
              </p>
            )}
          </div>
        )}

        {!this.successfulOrderDetails && (
          <div className={styles.submitWrapper}>
            <section>
              <CheckboxElement
                name={'terms'}
                value={this.agreedToTerms}
                required={true}
                onChange={(e) => {
                  this.agreedToTerms = e.target.checked;
                }}
                className={styles.checkboxField}
              >
                I agree to the{' '}
                <Link to={'/terms'} target={'_blank'}>
                  Terms and Conditions
                </Link>
              </CheckboxElement>
            </section>

            <LoadingButton
              type={'submit'}
              className={styles.submitButton}
              disabled={this.orderIsProcessing}
              isLoading={this.orderIsProcessing}
            >
              Complete Order
            </LoadingButton>
          </div>
        )}
      </form>
    );
  }
}

Checkout.propTypes = {
  CartStore: PropTypes.object,
  OrderStore: PropTypes.object,
  GTMTracking: PropTypes.object,
  stripe: PropTypes.object,
  elements: PropTypes.object,
};

const CheckoutWithStripe = () => {
  const stripe = useStripe();
  const elements = useElements();

  return <Checkout stripe={stripe} elements={elements} />;
};

export default CheckoutWithStripe;
