import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import MaterialPicker from 'components/common/MaterialPicker';
import PricingWidget from 'components/common/Demo/PricingWidget';
import Scale from 'components/common/Demo/Scale';

import styles from './DemoSettings.sass';

@inject('UIStore', 'SystemStore', 'GTMTracking')
@observer
class DemoSettings extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // randomise depth sliders on load
    this.props.SystemStore.settings.updateDepthOverlap(Math.random());
    this.props.SystemStore.settings.updateDepthCurvature(Math.random());
  }

  onDepthOverlapChanged = (e) => {
    this.props.SystemStore.settings.updateDepthOverlap(e.target.value);
  };

  onDepthCurvatureChanged = (e) => {
    this.props.SystemStore.settings.updateDepthCurvature(e.target.value);
  };

  refreshDemo = () => {
    this.props.SystemStore.demo.updateAndAnimateCurves();
  };

  onAnimateButtonClicked = () => {
    this.props.GTMTracking.trackEvent('clickAnimate');
    this.refreshDemo();
  };

  onHangingPointButtonClicked = () => {
    this.props.GTMTracking.trackEvent('clickHangingPoint');
    this.props.SystemStore.demo.startChosingHangingPoint();
  };

  render() {
    const { UIStore, SystemStore } = this.props;
    const { ui, settings } = SystemStore;

    const refreshButtonClasses = classNames({
      [styles.refreshButton]: true,
      [styles.refreshButtonCentered]: SystemStore.ui.demoIsEmpty,
      [styles.refreshButtonVisible]:
        ui.curvesChangedSinceDemoUpdate && ui.curvesExist,
    });

    const scaleClasses = classNames({
      [styles.scale]: true,
      [styles.scaleHidden]: ui.demoIsAnimating || ui.demoIsEmpty,
    });

    const demoOptionsClasses = classNames({
      [styles.demoOptions]: true,
      [styles.demoOptionsHidden]:
        ui.curvesChangedSinceDemoUpdate ||
        ui.demoIsAnimating ||
        (ui.isChosingHangingPoint && UIStore.onMobile),
    });

    const settingsGroupPurchaseClasses = classNames({
      [styles.settingsGroup]: true,
      [styles.demoPurchase]: true,
      [styles.settingsGroupHidden]:
        ui.curvesChangedSinceDemoUpdate ||
        ui.demoIsAnimating ||
        (ui.isChosingHangingPoint && UIStore.onMobile),
    });

    const hangingButtonClasses = classNames({
      [styles.button]: true,
      [styles.buttonActive]: ui.isChosingHangingPoint,
    });

    const mobileHangingHintClasses = classNames({
      [styles.mobileHangingHint]: true,
      [styles.mobileHangingHintHidden]:
        !UIStore.onMobile || !ui.isChosingHangingPoint,
    });

    return (
      <div className={styles.wrapper}>
        <button
          className={refreshButtonClasses}
          onClick={this.onAnimateButtonClicked}
        >
          Animate
        </button>

        <div className={mobileHangingHintClasses}>
          click and drag<br />to choose hanging point
        </div>

        <div className={styles.settings}>
          <div className={scaleClasses}>
            <Scale />
          </div>

          <div className={demoOptionsClasses}>
            <div className={styles.settingsGroupDepth}>
              <legend className={styles.settingsGroupTitle}>
                Depth control
              </legend>
              <div className={styles.settingsGroupContent}>
                <input
                  type={'range'}
                  className={styles.range}
                  onChange={this.onDepthOverlapChanged}
                  value={settings.depthOverlapScalar}
                  min={'0'}
                  max={'1'}
                  step={'any'}
                  title={'Overlap'}
                />
                <input
                  type={'range'}
                  className={styles.range}
                  onChange={this.onDepthCurvatureChanged}
                  value={settings.depthCurvatureScalar}
                  min={'0'}
                  max={'1'}
                  step={'any'}
                  title={'Double Curve'}
                />
              </div>
            </div>

            <div className={styles.settingsGroupOptions}>
              <legend className={styles.settingsGroupTitle}>
                Product Options
              </legend>
              <div className={styles.settingsGroupContent}>
                <MaterialPicker direction={'up'} />

                {!UIStore.onMobile && (
                  <button
                    onClick={this.onHangingPointButtonClicked}
                    className={hangingButtonClasses}
                  >
                    {ui.isChosingHangingPoint &&
                      !UIStore.onTouchDevice && (
                        <span>Use mouse to rotate piece</span>
                      )}

                    {ui.isChosingHangingPoint &&
                      UIStore.onTouchDevice && (
                        <span>Click and drag piece</span>
                      )}

                    {!ui.isChosingHangingPoint && (
                      <span>Choose Hanging Point</span>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>

          {ui.designPrice && (
            <PricingWidget
              className={settingsGroupPurchaseClasses}
              price={ui.designPrice}
              onBuyButtonClicked={() => {}}
              buyButtonDisabled={
                ui.isChosingHangingPoint ||
                settings.hangingPointAngle === undefined
                  ? 'Please choose a hanging point to continue with your purchase'
                  : ''
              }
            />
          )}
        </div>
      </div>
    );
  }
}

DemoSettings.propTypes = {
  UIStore: PropTypes.object,
  SystemStore: PropTypes.object,
  GTMTracking: PropTypes.object,
};

export default DemoSettings;
