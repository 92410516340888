import { saveAs } from 'file-saver';
import { floorToDecimalPlace } from 'utils/numberUtils';

// Store functions
//=====================================

export const downloadJSON = (store, withSettings) => {
  const data = withSettings ? exportDesignData(store) : exportCanvasData(store);
  const json = JSON.stringify(data);

  const blob = new Blob([json], {
    type: 'application/json',
  });
  saveAs(blob, 'hexatope.json');
};

export const exportDesignData = (store, extraData) => {
  const designData = {
    canvas: {
      curveMapping: store.curveMappingId,
      data: exportCanvasData(store, true), // trim fat on export data
    },
    productType: store.productType,
    settings: store.settings.exportProductSettings,
  };

  return extraData ? Object.assign(extraData, designData) : designData;
};

export const exportCanvasData = ({ hexagons, columns, rows }, trimFat) => {
  let grid = [];

  for (let x = 0; x < columns; x++) {
    grid.push([]);
    for (let y = 0; y < rows; y++) {
      const hexagon = hexagons[x][y];
      let newHexagonData = hexagon.active
        ? [hexagon.active, floorToDecimalPlace(hexagon.layoutSeed, 3)]
        : 0;
      grid[x][y] = newHexagonData;
    }
  }

  if (trimFat) {
    grid = removeUnusedColumns(grid);
    grid = removeUnusedRows(grid);
  }

  return grid;
};

// only used when exporting keyframes
// export const exportCanvasPreviewData = store => {
//   return {
//     canvas: {
//       curveMapping: store.curveMappingId,
//       data: exportCanvasData(store, false),
//     },
//     preview: {
//       mouse: {
//         x: store.mouseTargetHex.pos.x,
//         y: store.mouseTargetHex.pos.y,
//       },
//       isDrawing: store.ui.isDrawing,
//     },
//   };
// };

export const getPieceDimensions = (store) => {
  return store.settings.isHungSideways
    ? {
      width: Math.round(store.ui.pieceDimensions.y),
      height: Math.round(store.ui.pieceDimensions.x),
    }
    : {
      width: Math.round(store.ui.pieceDimensions.x),
      height: Math.round(store.ui.pieceDimensions.y),
    };
};

// Helpers
//=====================================

const removeUnusedColumns = (grid) =>
  // filter out columns that are all false
  grid.filter((column) => column.filter((value) => !!value).length);

const removeUnusedRows = (grid) => {
  // rows need to be removed in pairs
  // because the odd ones are offset

  // loop through rows from the top and remove them
  let triggerTripped = false;
  while (!triggerTripped) {
    grid.forEach((column) => {
      if (column[0] || column[1]) triggerTripped = true;
    });

    if (!triggerTripped) grid = grid.map((column) => column.slice(2));
  }

  // loop through rows from the bottom and remove them
  triggerTripped = false;
  while (!triggerTripped) {
    const rows = grid[0].length;
    grid.forEach((column) => {
      if (column[rows - 1] || column[rows - 2]) triggerTripped = true;
    });

    if (!triggerTripped) grid = grid.map((column) => column.slice(0, rows - 2));
  }

  return grid;
};
