import React from 'react';
import PropTypes from 'prop-types';

const LockIcon = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 100 100'
    version='1.1'
    preserveAspectRatio='xMidYMid meet'
  >
    <title>Lock icon</title>
    <path d='M88 47.1h-8.3V29.5C79.6 13.2 66.3 0 50 0S20.4 13.2 20.3 29.5v17.6H12c-1.8 0-3.3 1.5-3.3 3.3v46.3c0 1.8 1.5 3.3 3.3 3.3h76c1.8 0 3.3-1.5 3.3-3.3V50.4c0-1.8-1.5-3.3-3.3-3.3zM31.8 29.5s0 .1 0 0c.1-9.9 8.2-17.9 18.2-17.9s18.1 8.1 18.2 18v-.1 17.6H31.8V29.5zM60.5 75c0 5.8-4.7 10.5-10.5 10.5S39.5 80.8 39.5 75 44.2 64.5 50 64.5 60.5 69.2 60.5 75z' />
  </svg>
);

LockIcon.propTypes = {
  className: PropTypes.string,
};

export default LockIcon;
