import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ClickOutsideHandler extends Component {
  constructor(props) {
    super(props);

    this.wrapperElement = undefined;
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.onDocumentClicked);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onDocumentClicked);
  }

  onDocumentClicked = (e) => {
    const wrapperBounds = this.wrapperElement.getBoundingClientRect();
    if (
      e.pageX < wrapperBounds.left ||
      e.pageX > wrapperBounds.left + wrapperBounds.width ||
      e.pageY < wrapperBounds.top ||
      e.pageY > wrapperBounds.top + wrapperBounds.height
    ) {
      e.preventDefault();
      this.props.onDocumentClickedOutside();
    }
  };

  render() {
    const { children, className } = this.props;

    return (
      <div
        className={className}
        ref={(element) => (this.wrapperElement = element)}
      >
        {children}
      </div>
    );
  }
}

ClickOutsideHandler.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onDocumentClickedOutside: PropTypes.func.isRequired,
};

export default ClickOutsideHandler;
