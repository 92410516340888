import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import frames from 'constants/previewFrames';

import SystemCanvas from 'system/Canvas';

import styles from './AppBanner.sass';

@inject('SystemStore', 'UIStore')
@observer
class AppBanner extends Component {
  frame = 0;
  frameCount = frames.length;
  frameInterval = undefined;

  constructor(props) {
    super(props);
    this.canvasElement = undefined;
    this.canvasWrapperElement = undefined;
  }

  componentDidMount() {
    const store = this.props.SystemStore;

    // make the tool draw so the mouse is visible
    store.settings.setModeToDraw();
    store.clearHexagons();

    this.initialiseHexagons(store, this.canvasWrapperElement);

    store.canvas = new SystemCanvas(store);
    store.canvas.setup(this.canvasElement, this.canvasWrapperElement);

    this.renderCanvas();

    // render after import
    this.canvasTriggerReaction = reaction(
      () => [store.ui.shouldCanvasRender],
      () => {
        if (!store.ui.shouldCanvasRender) return;
        this.renderCanvas();
        store.ui.canvasHasRendered();
      },
      {
        fireImmediately: true, // it'll never fire if it starts with needing to render
      }
    );

    store.canvas.isMouseInside = true;

    // start frame loop
    this.nextFrame();
    this.frameInterval = setInterval(this.nextFrame, 100);
  }

  componentWillUnmount = () => {
    const store = this.props.SystemStore;
    store.clearHexagons();
    store.canvas = undefined;

    // dispose of reaction
    this.canvasTriggerReaction();
    clearInterval(this.frameInterval);
  };

  preventDefaultEvent = (e) => {
    e.preventDefault();
  };

  nextFrame = () => {
    this.props.SystemStore.importDesign(frames[this.frame]);
    this.frame = (this.frame + 1) % this.frameCount;
  };

  initialiseHexagons = (store, wrapperElement) => {
    const wrapperBox = wrapperElement.getBoundingClientRect();
    const { hexRadius, hexMargin } = store.config;

    // take away the orphan column from the widths
    let columns = Math.ceil(
      (wrapperBox.width - hexMargin * 2) / (hexRadius * 3) - 0.5
    );
    const rows = Math.floor(
      wrapperBox.height / ((Math.sqrt(3) * store.config.hexRadius) / 2) - 1
    );
    store.initialiseHexagons(rows, columns);
  };

  renderCanvas = () => {
    const store = this.props.SystemStore;
    const { canvas } = store;

    if (!store.canvas || !store.canvas.c) return;

    const { drawMouseHexagon } = store.ui;

    store.updateHexagons();
    canvas.draw(drawMouseHexagon);
  };

  render() {
    return (
      <div
        className={styles.canvasWrapper}
        ref={(element) => (this.canvasWrapperElement = element)}
      >
        <canvas
          ref={(element) => (this.canvasElement = element)}
          className={styles.canvas}
        />
      </div>
    );
  }
}

AppBanner.propTypes = {
  SystemStore: PropTypes.object,
  UIStore: PropTypes.object,
};

export default AppBanner;
