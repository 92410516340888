import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';

import IsAdmin from 'components/common/routing/IsAdmin';
import AdminPanel from './AdminPanel';
import DemoSettings from './DemoSettings';
import Demo from 'components/common/Demo';

import { CAN_USE_LOCAL_STORAGE } from 'constants/featureAvailibility';

import styles from './DemoWrapper.sass';

@inject('DesignStore', 'SystemStore')
@observer
class DemoWrapper extends Component {
  constructor(props) {
    super(props);
    this.demoElement = undefined;
    this.demoWrapperElement = undefined;
  }

  componentDidMount() {
    const { SystemStore, designSlug } = this.props;

    // load design as necessary
    if (designSlug) {
      this.loadDesignFromSlug(designSlug);
    } else if (CAN_USE_LOCAL_STORAGE) {
      this.loadDesignFromLocalStorage();
    }

    // render and animate demo when mobile tabs are changed
    this.mobileTabsReaction = reaction(
      () => [SystemStore.ui.demoVisibleOnMobile],
      () => SystemStore.demo.updateAndAnimateCurves()
    );
  }

  componentWillUnmount = () => {
    // dispose of reaction
    this.mobileTabsReaction();

    // unload design so app is free
    this.props.DesignStore.designUnloaded();
  };

  loadDesignFromSlug = (slug) => {
    this.props.DesignStore.loadDesignBySlug(slug, this.props.SystemStore);
  };

  loadDesignFromLocalStorage = () => {
    const design = JSON.parse(localStorage.getItem('design'));

    if (!design) return;

    this.props.SystemStore.importCanvasData(design);
  };

  render() {
    const { designSlug } = this.props;
    return (
      <div className={styles.outerWrapper}>
        <IsAdmin>
          <AdminPanel designSlug={designSlug} />
        </IsAdmin>
        <div className={styles.demoWrapper}>
          <DemoSettings />

          <Demo />
        </div>
      </div>
    );
  }
}

DemoWrapper.propTypes = {
  pageName: PropTypes.string,
  designSlug: PropTypes.string,
  DesignStore: PropTypes.object,
  SystemStore: PropTypes.object,
};

export default DemoWrapper;
