import { observable, computed, action } from 'mobx';
import { TOOL_MODES, GRID_ROTATION, MATERIALS } from 'constants/systemOptions';
import { roundToDecimalPlace } from 'utils/numberUtils';
import { getMaterialLabel, getRenderMaterial } from 'utils/settingsUtils';

class SettingsStore {
  @observable depthOverlapScalar = 0.5;
  @observable depthCurvatureScalar = 0.5;
  @observable toolMode = TOOL_MODES.DRAW;
  @observable gridRotation = GRID_ROTATION.VERTICAL;
  @observable material = MATERIALS.STERLING;
  @observable hangingPointAngle = undefined;

  @observable mmHexRadius = 6;
  @observable mmWireDiameter = 1;

  @computed
  get trackableSettings() {
    return {
      depthOverlapScalar: this.depthOverlapScalar,
      depthCurvatureScalar: this.depthCurvatureScalar,
      toolMode: this.toolMode,
      gridRotation: this.gridRotation,
      material: this.material,
    };
  }

  @computed
  get exportProductSettings() {
    // if being rendered as a vertical we have to remove a turn because it's imported as horizontal
    const rotatedAngle =
      this.gridRotation === GRID_ROTATION.VERTICAL
        ? this.hangingPointAngle
        : (Math.PI * 1.5 + this.hangingPointAngle) % (Math.PI * 2);
    return {
      depthOverlapScalar: roundToDecimalPlace(this.depthOverlapScalar, 3),
      depthCurvatureScalar: roundToDecimalPlace(this.depthCurvatureScalar, 3),
      hangingPointAngle: roundToDecimalPlace(rotatedAngle, 3),
    };
  }

  @computed
  get isHungSideways() {
    if (!this.hangingPointAngle) return false;
    // getting the two quadrants of the circle that are not near 0
    const sideways =
      (this.hangingPointAngle + Math.PI / 4) % Math.PI > Math.PI / 2;
    // grid rotation flips the answer
    return this.gridRotation === GRID_ROTATION.VERTICAL ? sideways : !sideways;
  }

  @computed
  get materialLabel() {
    return getMaterialLabel(this.material);
  }

  @computed
  get renderMaterial() {
    return getRenderMaterial(this.material);
  }

  @action
  importSettings = (settings) => {
    Object.assign(this, settings);
  };

  @action
  setModeToDraw = () => {
    this.toolMode = TOOL_MODES.DRAW;
  };

  @action
  setModeToEdit = () => {
    this.toolMode = TOOL_MODES.EDIT;
  };

  @action
  setModeToErase = () => {
    this.toolMode = TOOL_MODES.ERASE;
  };

  @action
  setGridRotation = (rotation) => {
    this.gridRotation = rotation;
  };

  @action
  resetGridRotation = () => {
    this.gridRotation = GRID_ROTATION.VERTICAL;
  };

  @action
  setMaterial = (material) => {
    this.material = material;
  };

  @action
  updateDepthOverlap = (scalar) => {
    this.depthOverlapScalar = scalar;
  };

  @action
  updateDepthCurvature = (scalar) => {
    this.depthCurvatureScalar = scalar;
  };

  @action
  updateHangingPointAngle = (angle) => {
    this.hangingPointAngle = angle;
  };
}

export default SettingsStore;
