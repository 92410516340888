export default [
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, [1, 0.942], 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, [1, 0.02], [1, 0.942], 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, [1, 0.874], 0, [1, 0.02], [1, 0.942], 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          0,
          0,
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [1, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 7 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          0,
          0,
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [1, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, [1, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          0,
          0,
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [1, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, [1, 0.406], 0, [1, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          0,
          [1, 0.124],
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [1, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, [1, 0.406], 0, [1, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 5 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          [1, 0.86],
          [1, 0.124],
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [1, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, [1, 0.406], 0, [1, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [1, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, [1, 0.406], 0, [1, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [1, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [1, 0.406], 0, [1, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [1, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [1, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [1, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.874],
          [1, 0.973],
          [1, 0.02],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.004],
          [1, 0.973],
          [1, 0.02],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.02],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.867], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.02],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.128], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 8 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.02],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 8 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.28],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.86],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.541],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.121],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.541],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.368], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.121],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.541],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.629], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.121],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.541],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.889], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.186],
          [1, 0.121],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.541],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.149], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          [1, 0.121],
          [1, 0.124],
          [1, 0.356],
          [1, 0.973],
          [1, 0.541],
          [2, 0.942],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.149], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [1, 0.973], [1, 0.541], [2, 0.942], 0, 0, 0],
        [0, 0, 0, 0, [1, 0.149], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [1, 0.973], 0, [1, 0.942], 0, 0, 0],
        [0, 0, 0, 0, [1, 0.149], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, [1, 0.942], 0, 0, 0],
        [0, 0, 0, 0, [1, 0.149], 0, [2, 0.406], 0, [2, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 7 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, [1, 0.942], 0, 0, 0],
        [0, 0, 0, 0, [1, 0.149], 0, [1, 0.406], 0, [1, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, [1, 0.942], 0, 0, 0],
        [0, 0, 0, 0, 0, 0, [1, 0.406], 0, [1, 0.388], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: true },
  },

  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, [1, 0.149], 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 10 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, [1, 0.594], [1, 0.519], 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, [1, 0.149], 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          [1, 0.161],
          0,
          [1, 0.38],
          0,
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, [1, 0.149], 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          0,
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.496], 0, 0, 0, 0, 0, 0, 0, [1, 0.149], 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 2 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          0,
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.496], [1, 0.695], 0, 0, 0, 0, 0, 0, [1, 0.149], 0, 0],
        [0, 0, 0, 0, [1, 0.911], 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 4, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          0,
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.496], [1, 0.695], 0, 0, 0, 0, 0, 0, [1, 0.149], 0, 0],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 4, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          0,
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.496], [1, 0.695], 0, 0, 0, 0, 0, 0, [1, 0.149], 0, 0],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 4, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          0,
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.496],
          [1, 0.695],
          0,
          0,
          0,
          0,
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 9 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          0,
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.496],
          [1, 0.695],
          0,
          0,
          0,
          0,
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 7 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.496],
          [1, 0.695],
          0,
          0,
          0,
          0,
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 7 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.496],
          [1, 0.695],
          0,
          0,
          [1, 0.998],
          0,
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.496],
          [1, 0.695],
          [1, 0.306],
          0,
          [1, 0.998],
          0,
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.496],
          [1, 0.695],
          [1, 0.306],
          0,
          [1, 0.998],
          0,
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.496],
          [1, 0.695],
          [1, 0.306],
          0,
          [1, 0.998],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 7 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.496],
          [1, 0.695],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.506],
          [1, 0.695],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 2 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.766],
          [1, 0.695],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 2 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.706],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.695],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 2 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.966],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.695],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.695],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.955],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.38],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.64],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.9],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.594],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.854],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.519],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.779],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [1, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 7 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.027],
          [1, 0.215],
          [1, 0.306],
          0,
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          0,
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.161],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          0,
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.327],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          0,
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          0,
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.375],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          0,
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [1, 0.149],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          0,
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.911], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 10 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          0,
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 4, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          0,
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          0,
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          [1, 0.883],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          [1, 0.883],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.008],
          [1, 0.168],
          0,
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 8 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          [1, 0.883],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.008],
          [1, 0.168],
          [1, 0.854],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          [1, 0.883],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.008],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          [1, 0.883],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.327],
          [1, 0.143],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 5 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.588],
          [1, 0.143],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.588],
          [1, 0.143],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.588],
          [1, 0.143],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.077], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.598], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 4, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.482],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 4, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.742],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 10 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.061],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.753],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 10 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.321],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.168],
          [1, 0.115],
          [1, 0.012],
          [2, 0.753],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 7 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.321],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.428],
          [1, 0.115],
          [1, 0.012],
          [2, 0.753],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 7 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.321],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.428],
          [1, 0.375],
          [1, 0.012],
          [1, 0.753],
          0,
          0,
        ],
        [0, 0, 0, 0, [2, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 10 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [2, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.321],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.428],
          [1, 0.375],
          [1, 0.012],
          [1, 0.753],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 4, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.321],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [2, 0.268],
          [1, 0.428],
          [1, 0.375],
          [1, 0.012],
          [1, 0.753],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.321],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.044],
          [1, 0.215],
          [1, 0.306],
          [1, 0.12],
          [1, 0.268],
          [1, 0.428],
          [1, 0.375],
          [1, 0.012],
          [1, 0.753],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.321],
          [1, 0.114],
          [1, 0.039],
          0,
          0,
          0,
        ],
        [
          0,
          0,
          0,
          [1, 0.215],
          0,
          [1, 0.12],
          [1, 0.268],
          [1, 0.428],
          [1, 0.375],
          [1, 0.012],
          [1, 0.753],
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.321],
          [1, 0.114],
          0,
          0,
          0,
          0,
        ],
        [
          0,
          0,
          0,
          [1, 0.215],
          0,
          [1, 0.12],
          0,
          [1, 0.428],
          0,
          [1, 0.012],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.226],
          [1, 0.848],
          [1, 0.143],
          [1, 0.161],
          [1, 0.321],
          0,
          0,
          0,
          0,
          0,
        ],
        [
          0,
          0,
          0,
          [1, 0.215],
          0,
          [1, 0.12],
          0,
          [1, 0.428],
          0,
          [1, 0.012],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, [1, 0.226], [1, 0.848], 0, [1, 0.161], 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.215],
          0,
          [1, 0.12],
          0,
          [1, 0.428],
          0,
          [1, 0.012],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.215],
          0,
          [1, 0.12],
          0,
          [1, 0.428],
          0,
          [1, 0.012],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, [1, 0.858], 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [1, 0.428], 0, [1, 0.012], 0, 0, 0],
        [0, 0, 0, 0, 0, 0, [1, 0.173], 0, [1, 0.062], 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 4, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 3, y: 9 }, isDrawing: true },
  },

  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, [1, 0.231], 0, [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, [1, 0.105], [1, 0.231], 0, [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 2 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, [1, 0.105], [1, 0.231], 0, [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.46], 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 5 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.46], 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.46], [1, 0.494], 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.46], [1, 0.494], 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 2 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 1 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 0 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 1 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 2 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.831],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.037],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.244],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.28],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.54],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.8],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.46],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.72],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.981],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [1, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [1, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 2 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 1 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 0 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 1 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 2 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          [1, 0.387],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.451],
          [1, 0.061],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 8 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.711],
          [1, 0.061],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.972],
          [1, 0.061],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.232],
          [1, 0.061],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.061],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.413],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.764],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [2, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [
          0,
          0,
          [2, 0.035],
          [1, 0.314],
          0,
          [1, 0.241],
          [2, 0.494],
          0,
          0,
          0,
          0,
          0,
          0,
        ],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.764],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [1, 0.341], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, [2, 0.035], [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.764],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, [1, 0.903], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, [2, 0.035], [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.764],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, [2, 0.035], [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.285],
          [1, 0.387],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, [2, 0.035], [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.764],
          [1, 0.739],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, [2, 0.035], [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.764],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, [2, 0.035], [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.492],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, [2, 0.035], [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.503],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, [2, 0.035], [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, [1, 0.035], [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 2 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [2, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 0 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          [1, 0.484],
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 0 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [2, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 2 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, [1, 0.269], 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 2 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          [1, 0.105],
          [1, 0.231],
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 2 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          [1, 0.105],
          0,
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, [1, 0.314], 0, [1, 0.241], 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 2 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          [1, 0.763],
          [1, 0.025],
          [1, 0.091],
          0,
          [1, 0.946],
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 0, y: 5 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.025], [1, 0.091], 0, [1, 0.946], 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 4 }, isDrawing: true },
  },

  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 5 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, [1, 0.397], 0, [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 3 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, [1, 0.397], [1, 0.136], [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, [1, 0.397], [1, 0.136], [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], 0, 0, 0, 0, 0, 0],
        [0, 0, 0, [1, 0.397], [1, 0.136], [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.922], 0, 0, 0, 0, 0],
        [0, 0, 0, [1, 0.397], [1, 0.136], [1, 0.28], 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 7 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.922], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.136],
          [1, 0.28],
          0,
          0,
          [1, 0.857],
          0,
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.922], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.136],
          [1, 0.28],
          0,
          0,
          [1, 0.857],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.922], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.136],
          [1, 0.28],
          0,
          [1, 0.854],
          [1, 0.857],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 7 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.922], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.136],
          [1, 0.28],
          [1, 0.714],
          [1, 0.854],
          [1, 0.857],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.922], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.136],
          [1, 0.28],
          [1, 0.714],
          [1, 0.854],
          [1, 0.857],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.922], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.136],
          [1, 0.28],
          [1, 0.975],
          [1, 0.854],
          [1, 0.857],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.274], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.136],
          [1, 0.28],
          [1, 0.975],
          [1, 0.854],
          [1, 0.857],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 7 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.274], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.136],
          [1, 0.28],
          [1, 0.975],
          [1, 0.854],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.274], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.136],
          [1, 0.28],
          [1, 0.975],
          [1, 0.206],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 7 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.274], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.975],
          [1, 0.206],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.975],
          [1, 0.206],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 7 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.975],
          [1, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 7 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.235],
          [1, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.235],
          [1, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [1, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.235],
          [1, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [1, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.235],
          [1, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.235],
          [1, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 7 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.235],
          [1, 0.557],
          [1, 0.209],
          [2, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.235],
          [2, 0.557],
          [1, 0.209],
          [2, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 5 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [2, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.235],
          [2, 0.557],
          [1, 0.209],
          [2, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 3 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [2, 0.397],
          [2, 0.487],
          [1, 0.28],
          [1, 0.235],
          [2, 0.557],
          [1, 0.209],
          [2, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [2, 0.397],
          [2, 0.487],
          [1, 0.28],
          [1, 0.495],
          [2, 0.557],
          [1, 0.209],
          [2, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [2, 0.397],
          [2, 0.487],
          [1, 0.28],
          [1, 0.506],
          [2, 0.557],
          [1, 0.209],
          [2, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [2, 0.397],
          [2, 0.487],
          [1, 0.28],
          [1, 0.766],
          [2, 0.557],
          [1, 0.209],
          [2, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [2, 0.397],
          [2, 0.487],
          [1, 0.28],
          [1, 0.766],
          [2, 0.557],
          [1, 0.209],
          [2, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [2, 0.487],
          [1, 0.28],
          [1, 0.766],
          [2, 0.557],
          [1, 0.209],
          [2, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 3 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [2, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [2, 0.487],
          [1, 0.28],
          [1, 0.766],
          [2, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, [1, 0.074], [1, 0.944], [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [2, 0.487],
          [1, 0.28],
          [1, 0.766],
          [2, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 6 }, isDrawing: false },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [2, 0.487],
          [1, 0.28],
          [1, 0.766],
          [2, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 1, y: 5 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          [1, 0.397],
          [1, 0.487],
          [1, 0.28],
          [1, 0.766],
          [2, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 4 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [2, 0.626], 0, 0, 0, 0, 0],
        [
          0,
          0,
          0,
          0,
          [1, 0.487],
          0,
          [1, 0.766],
          [2, 0.557],
          [1, 0.209],
          [1, 0.135],
          0,
          0,
          0,
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 5 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [2, 0.626], 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [2, 0.557], [1, 0.209], [1, 0.135], 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 6 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [1, 0.626], 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, [2, 0.557], 0, [1, 0.135], 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 8 }, isDrawing: true },
  },
  {
    canvas: {
      curveMapping: '5a65cfe68d2fe42750471d97',
      data: [
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      ],
    },
    preview: { mouse: { x: 2, y: 9 }, isDrawing: false },
  },
];
