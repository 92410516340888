import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import classNames from 'classnames';

import styles from './FlashMessage.sass';

@inject('NotificationStore')
@observer
class FlashMessage extends Component {
  @observable messageHeight = 0;

  constructor(props) {
    super(props);
  }

  onRef = (element) => {
    this.messageHeight = element ? element.clientHeight : 0;
  };

  render() {
    const {
      message,
      messageVisible,
      messagePositive,
      initialiseHideTimer,
      cancelHideTimer,
      closeMessage,
    } = this.props.NotificationStore;

    const translateY = messageVisible
      ? `translateY(${this.messageHeight}px)`
      : 'translateY(0px)';

    const wrapperClasses = classNames({
      [styles.wrapper]: true,
      [styles.wrapperPositive]: messagePositive,
      [styles.wrapperNegative]: !messagePositive,
    });

    return message ? (
      <div
        className={wrapperClasses}
        ref={this.onRef}
        style={{
          top: `-${this.messageHeight}px`,
          WebkitTransform: translateY,
          MsTransform: translateY,
          transform: translateY,
        }}
        onMouseEnter={cancelHideTimer}
        onMouseLeave={initialiseHideTimer}
      >
        {message}
        <button onClick={closeMessage} className={styles.cancelButton} />
      </div>
    ) : null;
  }
}

FlashMessage.propTypes = {
  NotificationStore: PropTypes.object,
};

export default FlashMessage;
