import * as THREE from 'three';
import OrbitControls from './vendor/OrbitControls';
import { getCurvesData, findMostCentralCurve } from './utils/curveUtils';
import {
  getSpinSpeed,
  initialiseAnimation,
  updateAnimation,
} from './utils/animationUtils';
import { getVec3ScaledPosition, calculateEdgePoints } from './utils/threeUtils';
import { demoModelConfig } from './config';
import { GRID_ROTATION, RENDER_MATERIALS } from 'constants/systemOptions';
import DemoChain from './DemoChain';

class Demo {
  constructor(store) {
    this.store = store;
    this.canvas = undefined;

    // scene
    this.scene = new THREE.Scene();

    // camera
    this.camera = new THREE.PerspectiveCamera(20, 1, 1, 10000);
    this.camera.position.z = store.globalUi.windowHeight * 0.3;
    this.scene.add(this.camera);

    // controls
    this.controls = undefined;

    // renderer
    this.renderer = undefined;

    // lights
    this.lights = [
      new THREE.PointLight(0xffffff, 0.5),
      new THREE.PointLight(0xffffff, 0.3),
      new THREE.PointLight(0xffffff, 0.5),
      new THREE.PointLight(0xffffff, 0.3),
      new THREE.PointLight(0xffffff, 0.3), // the chain light
      new THREE.AmbientLight(0xffffff),
    ];
    this.setLightPositions(
      store.globalUi.windowHeight,
      store.ui.demoZoomDistance
    );
    this.lights.forEach((light) => this.camera.add(light));

    // load env map for reflection
    // images by Paul Debevec from http://www.pauldebevec.com/Probes/
    var envMapURLs = [
      require('assets/images/left.jpg'),
      require('assets/images/right.jpg'),
      require('assets/images/top.jpg'),
      require('assets/images/bottom.jpg'),
      require('assets/images/front.jpg'),
      require('assets/images/back.jpg'),
    ];
    const envMap = new THREE.CubeTextureLoader().load(envMapURLs);

    const texturedNormalMapUrl = require('assets/images/chaintexture.jpg');
    const texturedNormalMap = new THREE.TextureLoader().load(
      texturedNormalMapUrl
    );
    texturedNormalMap.wrapS = texturedNormalMap.wrapT = THREE.RepeatWrapping;
    texturedNormalMap.repeat.x = 500;

    // material
    this.materials = {};
    this.materials[RENDER_MATERIALS.SILVER] = new THREE.MeshStandardMaterial({
      color: 0xf7fafc,
      roughness: 0.6,
      metalness: 0.7,
      envMap: envMap,
      envMapIntensity: 1,
    });
    this.materials[RENDER_MATERIALS.GOLD] = new THREE.MeshStandardMaterial({
      color: 0xf7dca3,
      roughness: 0.5,
      metalness: 0.7,
      envMap: envMap,
      envMapIntensity: 1,
    });

    // textured material for chain
    this.texturedMaterials = {};
    this.texturedMaterials[
      RENDER_MATERIALS.SILVER
    ] = new THREE.MeshStandardMaterial({
      color: 0xb7babc,
      roughness: 0.6,
      metalness: 0.5,
      envMap: envMap,
      envMapIntensity: 1,
      normalMap: texturedNormalMap,
    });
    this.texturedMaterials[
      RENDER_MATERIALS.GOLD
    ] = new THREE.MeshStandardMaterial({
      color: 0xb29e72,
      roughness: 0.5,
      metalness: 0.5,
      envMap: envMap,
      envMapIntensity: 1,
      normalMap: texturedNormalMap,
    });

    this.mesh = undefined;
    this.objectEdgePoints = undefined;

    // add geometry for chain
    this.chain = new DemoChain(store, this);
    this.sprues = undefined;

    // initialise render loop
    this.animationFrameId = undefined;
    this.render();
  }

  destroy() {
    window.cancelAnimationFrame(this.animationFrameId);
  }

  async initialiseSprues() {
    if (!this.sprues) {
      const {
        default: DemoSprues,
      } = await import(/* webpackChunkName: "DemoSprues" */ './DemoSprues');
      this.sprues = new DemoSprues(this.store, this);
    }
    this.sprues.initialise();
  }

  removeSprues() {
    if (this.sprues) this.sprues.remove();
  }

  setup(canvas, wrapperElement) {
    this.canvas = canvas;

    // renderer
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );

    // controls
    this.controls = new OrbitControls(this.camera, this.canvas);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = this.store.config.cameraDampingFactor;
    this.controls.rotateSpeed = this.store.config.cameraDampingFactor;
    this.controls.minDistance = this.store.config.cameraMinDistance;
    this.controls.maxDistance = this.store.config.cameraMaxDistance;

    this.updateDimensions(wrapperElement);
  }

  getMaterial(useTexture) {
    return useTexture
      ? this.texturedMaterials[this.store.settings.renderMaterial]
      : this.materials[this.store.settings.renderMaterial];
  }

  getInitialRotation = () => {
    return this.store.settings.gridRotation === GRID_ROTATION.HORIZONTAL
      ? -Math.PI / 2
      : 0;
  };

  setLightPositions = (wrapperHeight, zoom) => {
    const zFactor = zoom + wrapperHeight * 0.06;
    this.lights[0].position.set(300, 120, -150 - zFactor);
    this.lights[1].position.set(-300, -120, -90 - zFactor);
    this.lights[2].position.set(-90, 200, 200 - zFactor);
    this.lights[3].position.set(90, -200, 200 - zFactor);
    this.lights[4].position.set(0, -120, -60 - zFactor);
  };

  updateDimensions(wrapperElement, box, pixelRatio) {
    const boundingBox = wrapperElement
      ? wrapperElement.getBoundingClientRect()
      : box;
    const depthMultiplier = pixelRatio || 1;

    this.store.ui.updateDemoBoundingBox(boundingBox);

    this.renderer.setSize(boundingBox.width, boundingBox.height);

    // update camera aspect ratio
    this.camera.aspect = boundingBox.width / boundingBox.height;
    this.camera.position.z = boundingBox.height * 0.3 * depthMultiplier;
    this.camera.updateProjectionMatrix();

    // update the lights and control zoom
    this.zoomDistance = this.controls.getZoom();
    this.setLightPositions(
      boundingBox.height * depthMultiplier,
      this.zoomDistance
    );
  }

  updateCurves() {
    this.scene.remove(this.mesh);
    this.mesh = this.generateMeshes(demoModelConfig, false);
    this.scene.add(this.mesh);

    if (this.store.settings.hangingPointAngle !== undefined) {
      // load the angle
      // need to render before calculating so raytrace has a target
      this.renderer.render(this.scene, this.camera);
      this.updateHangingPointAngle(this.store.settings.hangingPointAngle, true);
    }

    this.store.ui.demoHasBeenUpdated();
  }

  updateAndAnimateCurves() {
    // reset position and rotation of orbit controls
    this.controls.reset();
    this.store.settings.updateHangingPointAngle(undefined);

    // hide chain until hanging position is chosen
    this.chain.hide();

    this.scene.remove(this.mesh);
    this.mesh = this.generateMeshes(demoModelConfig, true);
    this.scene.add(this.mesh);

    this.store.ui.demoHasBeenUpdated();

    // delete previous edge points
    // when hanging is initiated they're recalculated
    delete this.objectEdgePoints;
  }

  updateCurvesFromImport() {
    this.scene.remove(this.mesh);
    this.mesh = this.generateMeshes(demoModelConfig, false);

    this.scene.add(this.mesh);

    // spin
    this.controls.resetAtAngle(Math.PI);
    this.store.ui.startDemoSpin();
    this.store.ui.demoHasBeenUpdated();

    // make sure chain is the right material
    this.chain.updateMaterial();

    // delete previous edge points
    // when hanging is initiated they're recalculated
    delete this.objectEdgePoints;

    // load the angle
    // need to render before calculating so raytrace has a target
    this.renderer.render(this.scene, this.camera);

    this.updateHangingPointAngle(this.store.settings.hangingPointAngle, true);
  }

  generateMeshes(modelConfig, animate) {
    const curves = getCurvesData(this.store);

    // if there aren't any curves return an empty mesh
    if (!curves.length) return new THREE.Mesh();

    const tubeRadius = this.store.settings.mmWireDiameter / 2;
    const hexRadius = this.store.settings.mmHexRadius;
    let group = new THREE.Group();

    // simpler
    group.addGeometryWithMaterial = (geometry, material) => {
      const mesh = new THREE.Mesh(geometry, material);
      group.add(mesh);
    };

    // draw each curve as a tube
    curves.forEach((curve) => {
      const bezier = new THREE.CubicBezierCurve3(
        getVec3ScaledPosition(curve.start.layoutPos, hexRadius),
        getVec3ScaledPosition(curve.start.layoutControlPos, hexRadius),
        getVec3ScaledPosition(curve.end.layoutControlPos, hexRadius),
        getVec3ScaledPosition(curve.end.layoutPos, hexRadius)
      );
      const tube = new THREE.TubeBufferGeometry(
        bezier,
        modelConfig.tubeSegments,
        tubeRadius,
        modelConfig.tubeRadiusSegments,
        false
      );

      const tubeMesh = new THREE.Mesh(tube, this.getMaterial(false));
      curve.tubeMesh = tubeMesh;
      group.add(tubeMesh);

      // check whether the caps need capping
      [curve.start, curve.end].forEach((cap) => {
        // draw a sphere for each cap for sprue clicking
        if (this.store.ui.isSpruing && modelConfig.includeSpruePoints) {
          this.sprues.addSprueCapTargetToMesh(cap, group);
        }

        // if it has no extenders it needs a sphere at the end
        if (cap.extenders.length === 0) {
          const point = getVec3ScaledPosition(cap.layoutPos, hexRadius);
          const sphere = new THREE.SphereBufferGeometry(
            tubeRadius,
            modelConfig.tubeRadiusSegments,
            modelConfig.tubeRadiusSegments
          );
          sphere.translate(point.x, point.y, point.z);
          const sphereMesh = new THREE.Mesh(sphere, this.getMaterial(false));
          cap.sphereMesh = sphereMesh;
          group.add(sphereMesh);
        }
      });
    });

    const bBox = new THREE.Box3().setFromObject(group);
    const bBoxCenter = bBox.getCenter();
    this.store.ui.setPieceBoundingBox(bBox, bBoxCenter); // save center for sprue calc and pricing
    group.translateX(-bBoxCenter.x);
    group.translateY(-bBoxCenter.y);
    group.translateZ(-bBoxCenter.z);

    // draw actual sprues caps
    if (this.store.ui.isSpruing && modelConfig.includeSpruePoints) {
      this.sprues.addSprueCapsToMesh(group);
    }

    // calculate the price
    this.store.calculatePrice(curves);

    // add sprues AFTER saving the new center
    if (this.store.ui.isSpruing && modelConfig.includeSprues) {
      this.sprues.addSpruesToMesh(group, modelConfig);
    }

    if (animate) {
      let center = bBoxCenter
        .clone()
        .multiplyScalar(1 / this.store.settings.mmHexRadius);
      center.y *= -1; // flip the y for cartesian
      const centralCurve = findMostCentralCurve(curves, center);
      initialiseAnimation(this, curves, centralCurve);
    }

    // return wrapper group as so we can rotate the center of the mesh easily
    let wrapperGroup = new THREE.Group();
    wrapperGroup.add(group);
    wrapperGroup.rotateZ(this.getInitialRotation());

    return wrapperGroup;
  }

  updateHangingPointAngle = (angle, raycastChain) => {
    // angle is only sent when importing
    if (!angle) {
      // on touch devices we don't want to subtract the initial angle
      // because there's not visual feedback
      angle =
        (this.store.ui.angleToCenterOfDemo -
          this.store.ui.initialHangingPointAngle +
          this.getInitialRotation() +
          Math.PI * 2) %
        (Math.PI * 2);
    }

    // this.controls.enabled = true;

    this.mesh.rotation.set(0, 0, angle);
    this.store.settings.updateHangingPointAngle(angle);
    this.chain.updatePosition(angle, raycastChain);
  };

  startChosingHangingPoint = () => {
    this.store.ui.startChosingHangingPoint();

    // start from facing forward
    this.controls.reset();

    calculateEdgePoints(this);

    // we don't want to show the hanging point on touch device until selected
    if (!this.store.globalUi.onMobile && !this.store.globalUi.onTouchDevice) {
      this.updateHangingPointAngle();
    } else {
      // hide chain on touch devices
      this.chain.hide();
    }
  };

  preRender = () => {
    const config = this.store.config;
    if (this.store.ui.demoIsSpinning) {
      this.controls.autoRotate = true;
      this.controls.autoRotateSpeed = getSpinSpeed(
        this.controls.getAzimuthalAngle(),
        config,
        this.store.ui.endDemoSpin
      );
    } else {
      // autorotate when animating and when the mouse is over the canvas
      this.controls.autoRotate =
        !this.store.globalUi.onMobile &&
        (this.store.ui.demoIsAnimating || !this.store.ui.isMouseOverDemo);
      this.controls.autoRotateSpeed =
        config.cameraRotateSpeed * config.cameraDampingFactor;
    }

    this.controls.update();
    this.controls.enabled = !this.store.ui.isChosingHangingPoint;

    const newZoom = this.controls.getZoom();
    // if the zoom has changed by at least 1, update the light positions
    if (Math.abs(newZoom - this.store.ui.demoZoomDistance) > 1) {
      this.store.ui.setDemoZoomDistance(newZoom);
      this.setLightPositions(this.renderer.getSize().height, newZoom);
    }

    if (this.store.ui.demoIsAnimating) updateAnimation(this, true);
  };

  render = () => {
    this.animationFrameId = requestAnimationFrame(this.render);
    if (!this.renderer) return;

    this.preRender();
    this.renderer.render(this.scene, this.camera);
  };
}

export default Demo;
