import { observable, computed, action } from 'mobx';
import Vector2 from '../utils/Vector2';
import { getSizeFromBBox } from '../utils/threeUtils';
import { getDesignPrice } from '../utils/pricingUtils';
import UIStore from 'stores/ui/UIStore';
import config from '../config';

class SystemUIStore {
  @observable canvasBoundingBox = undefined;
  @observable demoBoundingBox = undefined;
  @observable lastMouseButton = 0;
  @observable isDrawing = false;
  @observable isMouseDownOverCanvas = false;
  @observable isMouseOverDemo = false;
  @observable demoIsAnimating = false;
  @observable demoIsSpinning = false;
  @observable demoIsTransitioning = false;
  @observable demoIsEmpty = true;
  @observable pieceBoundingBox = undefined;
  @observable pieceBoundingBoxCenter = undefined;
  @observable curvesExist = false;
  @observable curvesChangedSinceDemoUpdate = true;
  @observable demoVisibleOnMobile = false;
  @observable designPrice = undefined;
  @observable drawMouseHexagon = true;
  @observable isChosingHangingPoint = false;
  @observable isImporting = false;
  @observable isSpruing = false;
  initialHangingPointAngle = 0;

  @observable lastMouseDownTimeTaken = 0;
  mouseDownStartTime = 0;

  @computed
  get normalisedMousePositionInDemo() {
    // x and y values between -1 and 1
    // used in sprue raycasting and in hanging angles
    // more y flipping idky
    return {
      x:
        (2 * (UIStore.mouseX - this.demoBoundingBox.left)) /
          this.demoBoundingBox.width -
        1,
      y:
        (-2 * (UIStore.mouseY - this.demoBoundingBox.top)) /
          this.demoBoundingBox.height +
        1,
    };
  }

  @computed
  get angleToCenterOfDemo() {
    return (
      -Math.atan2(
        -this.normalisedMousePositionInDemo.y,
        this.normalisedMousePositionInDemo.x
      ) -
      Math.PI / 2
    );
  }

  @action
  updateDimensions = () => {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = undefined;
    }
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  };

  @action
  updateMousePosition = (e) => {
    this.mouseX = e.pageX;
    this.mouseY = e.pageY;
  };

  @action
  updateCanvasBoundingBox = (boundingBox) => {
    this.canvasBoundingBox = boundingBox;
  };

  @action
  updateDemoBoundingBox = (boundingBox) => {
    this.demoBoundingBox = boundingBox;
  };

  @action
  startPoint = (e) => {
    this.lastMouseButton = e.button || 0; // default to left button
    this.isMouseDownOverCanvas = true;
    this.mouseDownStartTime = now();
  };

  @action
  endPoint = () => {
    this.isMouseDownOverCanvas = false;
    this.lastMouseDownTimeTaken = now() - this.mouseDownStartTime;
  };

  @action
  toggleDrawing = (bool) => {
    this.isDrawing = bool;
  };

  @action
  mouseIsOverDemo = () => {
    this.isMouseOverDemo = true;
  };

  @action
  mouseNotOverDemo = () => {
    this.isMouseOverDemo = false;
  };

  @action
  curvesHaveChanged = () => {
    this.curvesExist = true;
    this.curvesChangedSinceDemoUpdate = true;
    this.endSpruing();
  };

  @action
  canvasHasBeenCleared = () => {
    this.curvesExist = false;
  };

  @action
  demoHasBeenEmptied = () => {
    this.demoIsEmpty = true;
  };

  @action
  demoHasBeenUpdated = () => {
    this.demoIsEmpty = false;
    this.curvesChangedSinceDemoUpdate = false;
  };

  @action
  startDemoTransition = () => {
    this.demoIsTransitioning = true;
  };

  @action
  endDemoTransition = () => {
    this.demoIsTransitioning = false;
  };

  @action
  startDemoSpin = () => {
    this.demoIsSpinning = true;
  };

  @action
  endDemoSpin = () => {
    this.demoIsSpinning = false;
  };

  @action
  demoAnimationStarted = () => {
    this.demoIsAnimating = true;
  };

  @action
  demoAnimationEnded = () => {
    this.demoIsAnimating = false;
  };

  @action
  demoIsVisibleOnMobile = (isDemoVisible) => {
    this.demoVisibleOnMobile = isDemoVisible;
  };

  @action
  setPieceBoundingBox = (box, center) => {
    this.pieceBoundingBox = box;
    this.pieceBoundingBoxCenter = center;
  };

  @computed
  get pieceDimensions() {
    return getSizeFromBBox(this.pieceBoundingBox);
  }

  @action
  startDrawingMouseHexagon = () => {
    this.drawMouseHexagon = true;
  };

  @action
  stopDrawingMouseHexagon = () => {
    this.drawMouseHexagon = false;
    this.canvasShouldRender();
  };

  @action
  startChosingHangingPoint = () => {
    this.isChosingHangingPoint = true;
    // update initial hanging point to avoid jerkiness
    this.initialHangingPointAngle = this.angleToCenterOfDemo;

    window.addEventListener('touchstart', this.touchDownHangingPoint);
    window.addEventListener('touchend', this.endChosingHangingPoint);
    window.addEventListener('mousedown', this.endChosingHangingPoint);
  };

  @action
  touchDownHangingPoint = (e) => {
    // touch events happen before mouseMove so manually update position
    UIStore.updateMousePosition(e.touches[0]);
    this.initialHangingPointAngle = this.angleToCenterOfDemo;
    window.removeEventListener('touchstart', this.touchDownHangingPoint);
  };

  @action
  endChosingHangingPoint = () => {
    this.initialHangingPointAngle = 0;
    this.isChosingHangingPoint = false;

    // unfocus the hanging point button
    document.activeElement.blur();
    window.removeEventListener('mousedown', this.endChosingHangingPoint);
    window.removeEventListener('touchend', this.endChosingHangingPoint);
  };

  @action
  calculatePrice = (store, curves) => {
    this.designPrice = getDesignPrice(store, null, curves);
  };

  @action
  startImport = () => {
    this.isImporting = true;
  };

  @action
  endImport = () => {
    this.isImporting = false;
  };

  @action
  startSpruing = () => {
    this.isSpruing = true;
  };

  @action
  endSpruing = () => {
    this.isSpruing = false;
  };

  // zoom distance used to set light positions and for scale
  @observable demoZoomDistance = config.cameraDistance;
  @action
  setDemoZoomDistance = (distance) => {
    this.demoZoomDistance = distance;
  };

  @observable canvasRelativeMousePos = new Vector2(0, 0);
  @action
  setCanvasRelativeMousePos = (x, y) => {
    this.canvasRelativeMousePos.reset(x, y);
  };

  // trigger render from anywhere (progress layout, clear hexagons)
  @observable shouldCanvasRender = false;
  @action
  canvasShouldRender = () => {
    this.shouldCanvasRender = true;
  };
  @action
  canvasHasRendered = () => {
    this.shouldCanvasRender = false;
  };

  // trigger render from import
  @observable shouldDemoRenderFromImport = false;
  @action
  demoShouldRenderFromImport = () => {
    this.shouldDemoRenderFromImport = true;
  };
  @action
  demoHasRenderedFromImport = () => {
    this.shouldDemoRenderFromImport = false;
  };
}

const now = () => {
  // Safari not supporting performance fucks up everything
  return typeof window.performance === 'undefined'
    ? Date.now()
    : window.performance.now;
};

export default SystemUIStore;
