import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import { CookiesProvider } from 'react-cookie';

import UIStore from 'stores/ui/UIStore';
import NotificationStore from 'stores/ui/NotificationStore';
import SystemStore from 'system/stores/SystemStore';
import GTMTrackingStore from 'stores/external/GTMTracking';

import DesignStore from 'stores/domain/DesignStore';
import CollectionStore from 'stores/domain/CollectionStore';
import ShopStore from 'stores/domain/ShopStore';
import CartStore from 'stores/domain/CartStore';
import OrderStore from 'stores/domain/OrderStore';
import UserStore from 'stores/domain/UserStore';

import App from './App';

const Store = new SystemStore();
const GTMTracking = new GTMTrackingStore(Store);
const Cart = new CartStore(GTMTracking);

class AppProvider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Provider
        UIStore={UIStore}
        NotificationStore={NotificationStore}
        SystemStore={Store}
        DesignStore={DesignStore}
        CollectionStore={CollectionStore}
        ShopStore={ShopStore}
        CartStore={Cart}
        OrderStore={OrderStore}
        UserStore={UserStore}
        GTMTracking={GTMTracking}
      >
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </Provider>
    );
  }
}

export default AppProvider;
