import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './ArrowLink.sass';

const arrowPath = (
  <path d='M15.8 7.2l-5.7-5.7c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4 4H1C.4 7 0 7.4 0 8c0 .6.4 1 1 1h11.8l-4 4c-.4.4-.4 1 0 1.4s1 .4 1.4 0l5.7-5.7c.1-.1.2-.4.2-.7 0-.3-.1-.6-.3-.8z' />
);

const ArrowLink = ({ children, className, direction, ...props }) => (
  <Link {...props} className={classNames(className, styles.link)}>
    {direction === 'left' && (
      <svg
        className={styles.arrowLeft}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 16 16'
      >
        {arrowPath}
      </svg>
    )}

    {children}

    {direction === 'right' && (
      <svg
        className={styles.arrowRight}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 16 16'
      >
        {arrowPath}
      </svg>
    )}
  </Link>
);

ArrowLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  direction: PropTypes.string,
};

ArrowLink.defaultProps = {
  direction: 'right',
};

export default ArrowLink;
