import React from 'react';

import styles from '../page.sass';

import GenericHeader from 'components/common/banners/GenericHeader';

import TermsOfService from './TermsOfService';

const TOSSections = [
  'Overview',
  'Online Store Terms',
  'General Conditions',
  'Accuracy, Completeness And Timeliness Of Information',
  'Modifications To The Service And Prices',
  'Products Or Services (If Applicable)',
  'Accuracy Of Billing And Account Information',
  'Optional Tools',
  'Third-Party Links',
  'User Comments, Feedback And Other Submissions',
  'Personal Information',
  'Errors, Inaccuracies And Omissions',
  'Prohibited Uses',
  'Disclaimer Of Warranties; Limitation Of Liability',
  'Indemnification',
  'Severability',
  'Termination',
  'Entire Agreement',
  'Governing Law',
  'Changes To Terms Of Service',
  'Contact Information',
];

const Terms = () => (
  <div>
    <GenericHeader title={'Terms of Service'} />

    <div className={styles.wrapper}>
      <ol className={styles.jumpList}>
        {TOSSections.map((sectionName, i) => (
          <li key={i}>
            <a href={`#tos-${i}`}>{sectionName}</a>
          </li>
        ))}
      </ol>

      <TermsOfService />
    </div>
  </div>
);

export default Terms;
