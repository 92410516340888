const PRODUCT_TYPES = {
  PENDANT: 'pendant',
};

const PLURALS = {
  pendant: 'pendants',
};

module.exports = {
  PRODUCT_TYPES: PRODUCT_TYPES,
  PLURALS: PLURALS,
};
