import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import FilterNav from '../FilterNav';
import ProductGrid from 'components/common/ProductGrid';
import Loader from 'components/common/Loader';

import styles from './List.sass';

@inject('UIStore', 'ShopStore')
@observer
class List extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.UIStore.scrollToTop();
  }

  componentDidMount() {
    this.props.ShopStore.loadShopDesigns();
  }

  render() {
    const { ShopStore } = this.props;

    const wrapperClasses = classNames({
      [styles.wrapper]: true,
      [styles.wrapperWithHeader]: true,
    });

    return (
      <div className={wrapperClasses}>
        <Helmet>
          <title>Shop | Hexatope</title>
          <meta property={'og:type'} content={'product.group'} />
        </Helmet>

        <FilterNav />

        {ShopStore.designsHaveLoaded ? (
          <ProductGrid
            biggerImages={true}
            checkerboardMaterial={!ShopStore.materialFilter}
            priceMaterial={ShopStore.materialFilter}
            designs={ShopStore.sortedDesigns}
            className={styles.products}
          />
        ) : (
          <div className={styles.loaderWrapper}>
            <Loader className={styles.loader} />
          </div>
        )}
      </div>
    );
  }
}

List.propTypes = {
  UIStore: PropTypes.object,
  ShopStore: PropTypes.object,
  location: PropTypes.object,
};

export default List;
