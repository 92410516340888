import { observable, action, computed } from 'mobx';
import {
  statefulApiCall,
  apiPost,
  apiPut,
  apiGet,
  apiDelete,
} from 'utils/httpUtils';
import { exportDesignData, getPieceDimensions } from 'system/utils/exportUtils';
import { getMetalVolume } from 'system/utils/pricingUtils';
import STATES from 'constants/storeStates';
import ShopStore from './ShopStore';

class DesignStore {
  @observable loadedDesign = undefined;
  @observable loadedDesigns = [];
  @observable state = STATES.READY; // ready / pending / error

  @action
  setLoadedDesign(design) {
    this.loadedDesign = design;
  }

  @action
  setLoadedDesigns(designs) {
    this.loadedDesigns = designs;
  }

  @action
  designUnloaded() {
    this.loadedDesign = undefined;
  }

  @computed
  get shopDesigns() {
    return this.loadedDesigns.filter(
      (design) => ShopStore.designIds.indexOf(design._id) >= 0
    );
  }

  @action
  async loadAllDesigns() {
    const designs = await this.getDesigns({
      inCollection: ShopStore.shopCollectionSlug,
    });
    const shopDesignIds = designs
      .filter((design) => design.inCollection)
      .map((design) => design._id);
    ShopStore.setDesignIds(shopDesignIds);
    this.loadedDesigns = designs;
  }

  @action
  async getDesigns(data = {}) {
    let queryString = '';
    for (var key in data) {
      queryString += `${key}=${encodeURIComponent(data[key])}`;
    }
    return await statefulApiCall(this, () =>
      apiGet(`design/all${queryString ? `?${queryString}` : ''}`)
    );
  }

  @action
  async getDesignBySlug(slug) {
    const foundDesign =
      this.loadedDesigns &&
      this.loadedDesigns.find((design) => design.slug === slug);
    return (
      (await foundDesign) ||
      statefulApiCall(this, () => apiGet(`design/${slug}`))
    );
  }

  @action
  async saveDesign(store, images, name) {
    const designData = {
      design: exportDesignData(store, {
        volume: getMetalVolume(store),
        dimensions: getPieceDimensions(store),
        name: name,
      }),
      images: images,
    };

    return await statefulApiCall(this, () =>
      apiPost('design', designData)
    ).then((designDocument) => {
      this.setLoadedDesign(designDocument);
      return designDocument;
    });
  }

  @action
  async updateDesign(store, images, name) {
    const id = this.loadedDesign._id;
    const designData = {
      design: exportDesignData(store, {
        volume: getMetalVolume(store),
        dimensions: getPieceDimensions(store),
        name: name || this.loadedDesign.name,
      }),
      images: images,
      mediaToDelete: images
        ? Object.keys(this.loadedDesign.media).map(
          (key) => this.loadedDesign.media[key].public_id
        )
        : [],
    };

    return await statefulApiCall(this, () =>
      apiPut(`design/${id}`, designData)
    ).then((designDocument) => {
      this.setLoadedDesign(designDocument);
      // update design in store array
      if (this.loadedDesigns) {
        const index = this.loadedDesigns.findIndex(
          (design) => design._id === designDocument._id
        );
        if (index >= 0) this.loadedDesigns[index] = designDocument;
      }
    });
  }

  @action
  async loadDesignBySlug(slug, store) {
    return await statefulApiCall(this, () => apiGet(`design/${slug}`)).then(
      (designDocument) => {
        store.importDesign(designDocument);
        this.setLoadedDesign(designDocument);
      }
    );
  }

  @action
  async deleteDesignById(id) {
    return await statefulApiCall(this, () => apiDelete(`design/${id}`)).then(
      () => {
        // remove design from store array
        if (this.loadedDesigns) {
          const index = this.loadedDesigns.findIndex(
            (design) => design._id === id
          );
          if (index >= 0) this.loadedDesigns.splice(index, 1);
        }
      }
    );
  }
}

export default new DesignStore();
