import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import PanelWrapper from 'components/common/PanelWrapper';
import BelowHeaderWrapper from 'components/common/Header/BelowHeader';
import SystemCanvas from './CanvasWrapper';
import SystemDemo from './DemoWrapper';
import SystemMobileSelector from './MobileSelector';

@inject('SystemStore')
@observer
class System extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    // reset to left panel
    this.props.SystemStore.ui.demoIsVisibleOnMobile(false);
  }

  render() {
    const { SystemStore, match } = this.props;

    return (
      <div>
        <BelowHeaderWrapper>
          <SystemMobileSelector />
        </BelowHeaderWrapper>
        <PanelWrapper overlayLeftOnMobile={!SystemStore.ui.demoVisibleOnMobile}>
          <SystemCanvas {...match.params} />
          <SystemDemo {...match.params} />
        </PanelWrapper>
      </div>
    );
  }
}

System.propTypes = {
  SystemStore: PropTypes.object,
  match: PropTypes.object,
};

export default System;
