import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import SystemCanvas from 'system/Canvas';
import CanvasSettings from './CanvasSettings';

import { GRID_ROTATION } from 'constants/systemOptions';
import { CANVAS_ROTATION_TRANSITION_DURATION } from 'constants/timing';

import styles from './CanvasWrapper.sass';

@inject('UIStore', 'SystemStore')
@observer
class Canvas extends Component {
  constructor(props) {
    super(props);
    this.canvasElement = undefined;
    this.canvasWrapperElement = undefined;
    this.mouseReaction = undefined;
    this.canvasTriggerReaction = undefined;
  }

  componentWillMount() {
    // initialise store before mounting child components
    const store = this.props.SystemStore;
    store.initialiseHexagons();
  }

  componentDidMount() {
    const store = this.props.SystemStore;

    // scroll to top before setup so the bounding box is in the right position
    window.scrollTo(0, 0);
    store.canvas = new SystemCanvas(store);
    store.canvas.setup(this.canvasElement, this.canvasWrapperElement);

    this.renderCanvas();

    // render canvas when mouse position is changed
    this.mouseReaction = reaction(
      () => [
        store.globalUi.mouseY,
        store.globalUi.mouseX,
        store.ui.isMouseDownOverCanvas,
      ],
      () => this.renderCanvas()
    );

    // render from inside hexagons to progress layout, or after import
    this.canvasTriggerReaction = reaction(
      () => [store.ui.shouldCanvasRender],
      () => {
        if (!store.ui.shouldCanvasRender) return;
        this.renderCanvas();
        store.ui.canvasHasRendered();
      },
      {
        fireImmediately: true, // it'll never fire if it starts with needing to render
      }
    );

    // resize canvas when window size is changed
    // and mobile tabs are changed
    // debounced by 50ms
    this.windowSizeReaction = reaction(
      () => [
        store.globalUi.windowWidth,
        store.globalUi.windowHeight,
        store.ui.demoVisibleOnMobile,
      ],
      () => this.resizeCanvas(),
      {
        delay: 50,
      }
    );

    this.windowRotationReaction = reaction(
      () => [store.settings.gridRotation],
      () => this.canvasRotated()
    );

    // disable scrolling and right clicking and page drag
    document.body.style.overflow = 'hidden';
    window.addEventListener('contextmenu', this.preventDefaultEvent);
    window.addEventListener('touchmove', this.preventDefaultEvent, {
      passive: false,
    });
  }

  componentWillUnmount = () => {
    this.props.SystemStore.canvas = undefined;

    // re-enable scrolling and right clicking and page drag
    document.body.style.overflow = '';
    window.removeEventListener('contextmenu', this.preventDefaultEvent);
    window.removeEventListener('touchmove', this.preventDefaultEvent);

    // dispose of reactions
    this.mouseReaction();
    this.canvasTriggerReaction();
    this.windowSizeReaction();
    this.windowRotationReaction();
  };

  preventDefaultEvent = (e) => {
    e.preventDefault();
  };

  startTouchDrawing = (e) => {
    // mouse position is only tested on mousemove or touchmove
    // but needs to be set before drawing on mousestart
    this.props.UIStore.onTouchMoved(e);
    this.startDrawing(e);
  };

  startDrawing = (e) => {
    this.props.SystemStore.ui.startPoint(e);
    e.preventDefault(); // stop mouse events happening after touch events
  };

  endDrawing = (e) => {
    this.props.SystemStore.ui.endPoint();
    this.props.SystemStore.addToHistory();
    e.preventDefault(); // stop mouse events happening after touch events
  };

  renderCanvas = () => {
    const store = this.props.SystemStore;
    const { canvas } = store;

    if (!store.canvas || !store.canvas.c) return;

    const {
      isMouseDownOverCanvas,
      lastMouseButton,
      drawMouseHexagon,
      isDrawing,
      toggleDrawing,
    } = store.ui;

    canvas.updateMousePos();

    // if we're over the canvas at all
    if (store.mouseTargetHex) {
      const wasDrawing = isDrawing;
      const shouldDraw = isMouseDownOverCanvas;

      const isNewTargetHex = store.mouseTargetHex !== store.mouseTargetHexLast;

      if (wasDrawing && !shouldDraw) {
        // reset last target for multiple clicks
        store.resetLastMouseTargetHex();
      }

      if (!wasDrawing && shouldDraw) {
        store.mouseTargetHex.onMouseClicked(lastMouseButton);
      }

      if (isNewTargetHex) {
        if (store.mouseTargetHexLast) store.mouseTargetHexLast.onMouseLeft();
        store.mouseTargetHex.onMouseEntered(wasDrawing, lastMouseButton);
      }

      // update ui var for mouse target colours
      toggleDrawing(shouldDraw);

      store.mouseTargetHex.update();
      store.updateLastMouseTargetHex();
    }

    store.updateHexagons();
    canvas.draw(drawMouseHexagon);
  };

  resizeCanvas = () => {
    const store = this.props.SystemStore;
    if (!store.canvas || !store.canvas.c) return;
    store.canvas.updateDimensions(this.canvasWrapperElement);
  };

  canvasRotated = () => {
    const store = this.props.SystemStore;

    // temporarily disable drawing the mouse hexagon
    store.ui.stopDrawingMouseHexagon();
    setTimeout(
      store.ui.startDrawingMouseHexagon,
      CANVAS_ROTATION_TRANSITION_DURATION
    );

    // make animate button reappear because orientation has changed
    store.ui.curvesHaveChanged();
  };

  render() {
    const store = this.props.SystemStore;

    const canvasClasses = classNames({
      [styles.canvas]: true,
      [styles.canvasVertical]:
        store.settings.gridRotation === GRID_ROTATION.VERTICAL,
      [styles.canvasHorizontal]:
        store.settings.gridRotation === GRID_ROTATION.HORIZONTAL,
    });

    return (
      <div
        className={styles.canvasWrapper}
        ref={(element) => (this.canvasWrapperElement = element)}
      >
        <Helmet>
          <title>App | Hexatope</title>
        </Helmet>
        <CanvasSettings />
        <canvas
          ref={(element) => (this.canvasElement = element)}
          className={canvasClasses}
          onMouseDown={this.startDrawing}
          onTouchStart={this.startTouchDrawing}
          onMouseOut={this.endDrawing}
          onMouseUp={this.endDrawing}
          onTouchEnd={this.endDrawing}
        />
      </div>
    );
  }
}

Canvas.propTypes = {
  UIStore: PropTypes.object,
  SystemStore: PropTypes.object,
};

export default Canvas;
