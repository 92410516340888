import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { formatAsGBP } from 'utils/currencyUtils';

import styles from './PricingWidget.sass';

import Tooltip from 'components/common/Tooltip';
import AddToCartButton from 'components/common/buttons/AddToCartButton';

const PricingWidget = ({ className, design, price, buyButtonDisabled }) => {
  const widgetClasses = classNames({
    [styles.widget]: true,
    [className]: !!className,
  });
  const priceClasses = classNames({
    [styles.price]: true,
    [styles.priceHidden]: !!buyButtonDisabled,
  });

  const buyButton = (
    <AddToCartButton
      disabled={true || !!buyButtonDisabled}
      className={styles.addToCartButton}
      productSlug={design && design.slug}
    />
  );

  return (
    <section className={widgetClasses}>
      <b className={priceClasses}>{formatAsGBP(price, true, false)}</b>

      <Tooltip label={buyButtonDisabled || 'The shop is currently closed for orders'} direction={'up'} tilt={'left'}>
        {buyButton}
      </Tooltip>

      {design && (
        <Link to={`/app/${design.slug}`} className={styles.button}>
          Edit this design
        </Link>
      )}
    </section>
  );
};

PricingWidget.propTypes = {
  className: PropTypes.string,
  design: PropTypes.object,
  price: PropTypes.number,
  buyButtonDisabled: PropTypes.string,
};

export default PricingWidget;
