import { observable, computed, action } from 'mobx';

class SprueStore {
  @observable rootSprueCap = undefined;
  @observable rootSpruePoint = undefined;
  @observable sprueCaps = [];

  @computed
  get hasSprues() {
    return !!this.sprueCaps.length;
  }

  @action
  replaceSprueCaps = (caps) => {
    // used after sorting
    this.sprueCaps = caps;
  };

  @action
  setRootSprue = (cap, point) => {
    this.rootSprueCap = cap;
    this.rootSpruePoint = point;
  };

  @action
  addSprueCap = (cap) => {
    this.sprueCaps.push(cap);
  };

  @action
  clearSprues = () => {
    this.sprueCaps = [];
    this.rootSprueCap = undefined;
  };

  @action
  removeSprueAtIndex = (index) => {
    this.sprueCaps.splice(index, 1);
  };
}

export default SprueStore;
