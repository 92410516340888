// Store functions
//=====================================

export const importDesign = (store, data) => {
  const canvasData = data.canvas.data;
  const settings = data.settings;

  // reset orientation so hanging point is right
  store.settings.resetGridRotation();

  // disable any settings reactions temporarily
  store.ui.startImport();

  const importOffsets = importCanvasData(store, canvasData);

  // if it updates the settings as well as the canvas
  // we want to load them and also trigger a demo render
  if (settings) {
    store.settings.importSettings(settings);
    store.ui.canvasShouldRender();
    store.ui.demoShouldRenderFromImport();
  }

  // used when importing keyframes
  if (data.preview) {
    if (data.preview.mouse)
      store.setMouseTargetHex(
        store.hexagons[data.preview.mouse.x + importOffsets.columnOffset][
          data.preview.mouse.y + importOffsets.rowOffset
        ]
      );
    store.ui.toggleDrawing(data.preview.isDrawing);
    store.ui.canvasShouldRender();
  }

  store.ui.endImport();
};

export const importCanvasData = (store, data) => {
  if (!store.hexagonsInitialised) store.initialiseHexagons();

  const { hexagons, columns, rows } = store;

  // clear grid
  store.clearHexagons();

  // find position inside grid
  const importColumns = data.length;
  const importRows = data[0].length;

  // column offset is easy
  const columnOffset =
    columns > importColumns ? Math.floor((columns - importColumns) / 2) : 0;

  // row offset has to be divisble by 0 because they come in pairs
  const rowOffset =
    rows > importRows ? Math.floor((rows - importRows) / 4) * 2 : 0;

  // set data with offset
  for (let x = 0; x < importColumns; x++) {
    for (let y = 0; y < importRows; y++) {
      const hexagonData = data[x][y];
      // ignore if data is false (empty hexagon)
      // or if off the grid
      if (
        hexagonData &&
        hexagons[x + columnOffset] &&
        hexagons[x + columnOffset][y + rowOffset]
      ) {
        const hexagonTarget = hexagons[x + columnOffset][y + rowOffset];
        // set the active and next active so it doesn't reset the layout seed
        hexagonTarget.active = hexagonData[0];
        hexagonTarget.nextActive = hexagonData[0];
        // set the initial seed as well so it doesn't show it ghosted like in edit mode
        hexagonTarget.layoutSeed = hexagonData[1];
        hexagonTarget.initialLayoutSeed = hexagonData[1];
      }
    }
  }

  // force canvas draw
  store.ui.curvesHaveChanged();
  store.updateHexagons();
  store.ui.canvasShouldRender();

  return {
    columnOffset,
    rowOffset,
  };
};
