import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import styles from './Scale.sass';

const CM_MULTIPLIER = 27;

@inject('UIStore', 'SystemStore')
@observer
class Scale extends Component {
  wrapperElement = undefined;
  wrapperBBox = undefined;
  demoZoomReaction = undefined;
  demoSizeReaction = undefined;
  @observable visibleCms = 0;
  @observable cmHeight = 0;
  @observable valignCenter = true;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { SystemStore, UIStore } = this.props;

    this.demoZoomReaction = reaction(
      () => SystemStore.ui.demoZoomDistance,
      () => this.calculateCmSize(),
      {
        fireImmediately: true,
      }
    );

    this.demoSizeReaction = reaction(
      () => [UIStore.windowHeight, SystemStore.ui.pieceDimensions],
      () => {
        this.calculateWrapperDimensions();
        this.calculateVisibleCms();
      },
      {
        delay: 50,
      }
    );
  }

  componentWillUnmount = () => {
    this.demoSizeReaction();
    this.demoZoomReaction();
  };

  onWrapperRef = (element) => {
    if (!element) return;
    this.wrapperElement = element;
    this.calculateWrapperDimensions();
    this.calculateCmSize();
  };

  calculateWrapperDimensions = () => {
    this.wrapperBBox = this.wrapperElement.getBoundingClientRect();
  };

  calculateCmSize = () => {
    const { ui } = this.props.SystemStore;
    const wrapperHeight = ui.demoBoundingBox ? ui.demoBoundingBox.height : 1000;
    const heightRatio = wrapperHeight / ui.demoZoomDistance;
    this.cmHeight = heightRatio * CM_MULTIPLIER;
    this.calculateVisibleCms();
  };

  calculateVisibleCms = () => {
    const { SystemStore, UIStore } = this.props;

    const biggerMargin = Math.max(
      this.wrapperBBox.top,
      UIStore.windowHeight - this.wrapperBBox.bottom
    );
    const availableCentralHeight =
      (UIStore.windowHeight / 2 - biggerMargin) * 2;
    let wrapperCms = Math.floor(availableCentralHeight / this.cmHeight);

    const pieceDimensions = SystemStore.ui.pieceDimensions;
    const pieceCms = Math.ceil(
      Math.max(pieceDimensions.x, pieceDimensions.y) / 10
    ); // mm to cm

    this.valignCenter = wrapperCms >= pieceCms;
    if (wrapperCms < pieceCms) {
      // if it's not central we can max out the wrapper instead
      wrapperCms = Math.floor(this.wrapperBBox.height / this.cmHeight);
    }
    this.visibleCms = Math.min(pieceCms, wrapperCms);
  };

  render() {
    const units = Array.from({ length: this.visibleCms }, (v, i) => (
      <span
        className={styles.scaleUnit}
        key={i}
        style={{
          height: this.cmHeight,
        }}
      >
        {this.cmHeight > 100 &&
          Array.from({ length: 9 }, (v, i) => (
            <span className={styles.subUnit} key={i} />
          ))}
      </span>
    ));
    const scaleClasses = classNames({
      [styles.scale]: true,
      [styles.scaleCenter]: this.valignCenter,
    });

    return (
      <div className={styles.wrapper} ref={this.onWrapperRef}>
        {this.visibleCms > 0 && (
          <div className={scaleClasses}>
            <span className={styles.scaleUnit} />
            {units}
          </div>
        )}
      </div>
    );
  }
}

Scale.propTypes = {
  UIStore: PropTypes.object,
  SystemStore: PropTypes.object,
};

export default Scale;
