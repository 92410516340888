import * as THREE from 'three';

export const getVec3ScaledPosition = (pos, scale) => {
  return new THREE.Vector3(scale * pos.x, scale * -pos.y, scale * pos.z);
};

export const getSizeFromBBox = (bBox) => {
  if (!bBox) return new THREE.Vector3();
  return bBox.max.clone().sub(bBox.min);
};

export const calculateEdgePoints = (demo) => {
  // prepare mesh for raycasting
  demo.mesh.rotation.set(0, 0, 0);
  demo.chain.visible = false;
  demo.renderer.render(demo.scene, demo.camera);

  const RAY_DISTANCE_FROM_ORIGIN = 100;
  let startingPoint = new THREE.Vector3();
  let direction = new THREE.Vector3();
  let raycaster = new THREE.Raycaster(startingPoint, direction);
  let edgePointsFirstHalf = [];
  let edgePointsSecondHalf = [];

  // calculate depths to raycast
  const meshGroupZ = demo.mesh.children[0].position.z;
  let lowestZ = Infinity;
  let highestZ = -Infinity;
  // loop through all geometries and get extremes of depth
  demo.mesh.children[0].children.forEach((mesh) => {
    const meshZ = mesh.geometry.boundingSphere.center.z;
    if (meshZ < lowestZ) lowestZ = meshZ;
    if (meshZ > highestZ) highestZ = meshZ;
  });
  lowestZ += meshGroupZ;
  highestZ += meshGroupZ;
  const intervalZ = 0.1;

  // rotate around half the z-axis
  for (let angle = 0; angle < Math.PI; angle += Math.PI / 50) {
    // set the starting point of the ray 100 away from the origin
    // and the direction pointing towards the origin
    startingPoint.set(
      Math.sin(angle) * RAY_DISTANCE_FROM_ORIGIN,
      Math.cos(angle) * RAY_DISTANCE_FROM_ORIGIN,
      0
    );
    direction.set(-Math.sin(angle), -Math.cos(angle), 0);

    let closestIntersection = undefined;
    let furthestIntersection = undefined;

    // scan at different depths to find the closest
    for (let posZ = lowestZ; posZ <= highestZ; posZ += intervalZ) {
      // set the starting point parallel to direction
      startingPoint.setZ(posZ);
      raycaster.set(startingPoint, direction);

      // do some recursive raycasting
      const intersection = raycaster.intersectObject(demo.scene, true);
      // if there's an intersection
      // whose distance is less than the current closest one
      // but is still on the right side of the origin
      // update the closest one
      if (
        intersection.length &&
        intersection[0].distance < RAY_DISTANCE_FROM_ORIGIN &&
        (!closestIntersection ||
          intersection[0].distance < closestIntersection.distance)
      ) {
        closestIntersection = intersection[0];
      }

      // if there's an intersection
      // whose last object's distance is more than the current farthest one
      // but is still on the far side of the origin
      // update the farthest one
      if (
        intersection.length &&
        intersection[intersection.length - 1].distance >
          RAY_DISTANCE_FROM_ORIGIN &&
        (!furthestIntersection ||
          intersection[intersection.length - 1].distance >
            furthestIntersection.distance)
      ) {
        furthestIntersection = intersection[intersection.length - 1];
      }
    }

    if (closestIntersection) {
      edgePointsFirstHalf.push({
        distanceFromCenter:
          RAY_DISTANCE_FROM_ORIGIN - closestIntersection.distance,
        z: closestIntersection.point.z,
      });
    } else {
      // if there are no intersections then just add a false to the array
      edgePointsFirstHalf.push(false);
    }

    if (furthestIntersection) {
      edgePointsSecondHalf.push({
        distanceFromCenter:
          furthestIntersection.distance - RAY_DISTANCE_FROM_ORIGIN + 0.14, // add tube radius to get to other side of tube
        z: furthestIntersection.point.z,
      });
    } else {
      // if there are no intersections then just add a false to the array
      edgePointsSecondHalf.push(false);
    }
  }

  // join the arrays together
  demo.objectEdgePoints = edgePointsFirstHalf.concat(edgePointsSecondHalf);
};
