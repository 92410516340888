import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { getRenderMaterial } from 'utils/settingsUtils';
import { formatAsGBP } from 'utils/currencyUtils';

import styles from './OrderSummary.sass';

import CloudinaryImage from 'components/common/CloudinaryImage';

@inject('UIStore')
@observer
class OrderSummary extends Component {
  @observable isVisibleOnMobile = false;
  contentElement = undefined;
  contentHeight = 0;

  constructor(props) {
    super(props);
  }

  toggleMobileVisibility = () => {
    this.contentHeight = this.contentElement.clientHeight;
    this.isVisibleOnMobile = !this.isVisibleOnMobile;
  };

  render() {
    const { cart, UIStore } = this.props;
    const { discount } = cart;

    return (
      <aside
        className={styles.wrapper}
        style={
          UIStore.onMobile
            ? {
              maxHeight: this.isVisibleOnMobile
                ? 56 + this.contentHeight
                : 56,
            }
            : null
        }
      >
        <h2
          className={styles.sectionTitle}
          onClick={UIStore.onMobile ? this.toggleMobileVisibility : null}
          tabIndex={UIStore.onMobile ? '0' : null}
        >
          Order Summary
          {this.isVisibleOnMobile ? (
            <div className={styles.mobileToggleOpen} />
          ) : (
            <div className={styles.mobileToggleClosed}>
              {formatAsGBP(cart.totalCost)}
            </div>
          )}
        </h2>

        <div
          className={styles.content}
          ref={(element) => (this.contentElement = element)}
        >
          <div className={styles.productList}>
            {cart.productsWithQuantities.map((product, i) => (
              <article className={styles.product} key={i}>
                <figure className={styles.productImage}>
                  <CloudinaryImage
                    data={
                      product.design.media[getRenderMaterial(product.material)]
                    }
                  />
                </figure>
                <div className={styles.productSummary}>
                  <p className={styles.productTitle}>{product.design.title}</p>
                  <p className={styles.productPrice}>
                    {formatAsGBP(product.price)}
                  </p>
                  <p className={styles.productQuantity}>
                    &times; {product.quantity}
                  </p>
                </div>
              </article>
            ))}
          </div>

          <dl className={styles.orderTotals}>
            {!!discount.id && (
              <dt className={styles.discount}>
                &lsquo;{discount.code}&rsquo; -{discount.percentageOff}%
              </dt>
            )}
            {!!discount.id && (
              <dd className={styles.totalPriceDiscountedFrom}>
                {formatAsGBP(cart.subtotalWithoutDiscount)}
              </dd>
            )}

            <dt className={styles.totalDetails}>Subtotal</dt>
            <dd>{formatAsGBP(cart.subtotal)}</dd>

            <dt className={styles.totalDetails}>Shipping</dt>
            <dd>
              {cart.shippingCost === 0
                ? 'FREE'
                : formatAsGBP(cart.shippingCost)}
            </dd>

            <dt className={styles.totalDetails}>Total</dt>
            <dd>{formatAsGBP(cart.totalCost)}</dd>
          </dl>
        </div>
      </aside>
    );
  }
}

OrderSummary.propTypes = {
  cart: PropTypes.object.isRequired,
  UIStore: PropTypes.object,
};

export default OrderSummary;
