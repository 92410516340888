import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import styles from './MobileSelector.sass';

@inject('SystemStore')
@observer
class MobileSelector extends Component {
  constructor(props) {
    super(props);
  }

  showCanvas = () => {
    this.props.SystemStore.ui.demoIsVisibleOnMobile(false);
  };

  showDemo = () => {
    // turn off and then on to trigger reanimation
    const { ui } = this.props.SystemStore;
    ui.demoIsVisibleOnMobile(false);
    ui.demoIsVisibleOnMobile(true);
  };

  render() {
    const buttonClasses = (isActive) =>
      classNames({
        [styles.mobileSelectorItem]: true,
        [styles.mobileSelectorItemActive]: isActive,
      });
    const { demoVisibleOnMobile } = this.props.SystemStore.ui;

    return (
      <aside className={styles.mobileSelectorWrapper}>
        <nav className={styles.mobileSelector}>
          <button
            onClick={this.showCanvas}
            className={buttonClasses(!demoVisibleOnMobile)}
          >
            Canvas
          </button>
          <button
            onClick={this.showDemo}
            className={buttonClasses(demoVisibleOnMobile)}
          >
            Demo
          </button>
        </nav>
      </aside>
    );
  }
}

MobileSelector.propTypes = {
  SystemStore: PropTypes.object,
};

export default MobileSelector;
