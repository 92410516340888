import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { formatDimensions } from 'utils/settingsUtils';
import { formatAsGBP } from 'utils/currencyUtils';

import styles from './ProductInfo.sass';

import MaterialPicker from 'components/common/MaterialPicker';
import RelatedProducts from './RelatedProducts';
import AddToCartButton from 'components/common/buttons/AddToCartButton';
import ArrowLink from 'components/common/ArrowLink';

@inject('DesignStore', 'SystemStore', 'CartStore')
@observer
class ProductInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { DesignStore, SystemStore } = this.props;
    const design = DesignStore.loadedDesign;
    const store = SystemStore;

    return design ? (
      <div className={styles.wrapper}>
        <div className={styles.infoBox}>
          <ArrowLink to={'/shop'} direction={'left'}>
            Back to Shop
          </ArrowLink>

          <section className={styles.productMeta}>
            <h2 className={styles.productNameBig}>{design.name}</h2>

            <div className={styles.materialPicker}>
              <MaterialPicker direction={'down'} />

              <b className={styles.price}>
                {formatAsGBP(
                  design.prices[store.settings.material],
                  true,
                  false
                )}
              </b>
            </div>

            <p className={styles.techDescription}>
              {design.name} measures {formatDimensions(design.dimensions)} and
              comes with a matching 18&Prime; / 45cm snake chain.
            </p>
          </section>

          <aside className={styles.buttonWrapper}>
            <AddToCartButton
              className={styles.buyButton}
              productSlug={design.slug}
            />
            <Link to={`/app/${design.slug}`} className={styles.button}>
              Edit Design
            </Link>
          </aside>
        </div>

        <RelatedProducts currentProduct={design} />
      </div>
    ) : null;
  }
}

ProductInfo.propTypes = {
  DesignStore: PropTypes.object,
  SystemStore: PropTypes.object,
};

export default ProductInfo;
