import React from 'react';
import { PropTypes as MobXPropTypes } from 'mobx-react';

import styles from './ProductList.sass';

import ProductListItem from './ProductListItem';

const ProductList = ({ products, ...props }) => (
  <div className={styles.wrapper}>
    <div className={styles.listHeader}>
      <div className={styles.listHeaderQuantity}>Quantity</div>
      <div className={styles.listHeaderPrice}>Price</div>
      <div className={styles.listHeaderSubtotal}>Subtotal</div>
    </div>
    {products.map((product) => (
      <ProductListItem
        key={`${product.design.slug}${product.material}`}
        product={product}
        {...props}
      />
    ))}
  </div>
);

ProductList.propTypes = {
  products: MobXPropTypes.arrayOrObservableArray,
};

export default ProductList;
