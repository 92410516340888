import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { reaction, observable } from 'mobx';
import classNames from 'classnames';

import styles from './HamburgerMenu.sass';

import LockIcon from 'components/common/icons/LockIcon';

@inject('UIStore', 'UserStore')
@observer
class HamburgerMenu extends Component {
  @observable open = false;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.scrollReaction = reaction(
      () => this.props.UIStore.scrollY,
      () => {
        this.open = false;
      }
    );
  }

  componentWillUnmount() {
    // dispose of reaction
    this.scrollReaction();
  }

  onToggleClicked = () => {
    this.open = !this.open;
  };

  onLinkClick = () => {
    this.open = false;
  };

  render() {
    const { UIStore, UserStore } = this.props;

    const toggleClasses = classNames({
      [styles.toggle]: true,
      [styles.toggleOpen]: this.open,
    });
    const menuClasses = classNames({
      [styles.menu]: true,
      [styles.menuInverted]: UIStore.headerInverted,
      [styles.menuVisible]: this.open,
    });

    return (
      <div>
        <div className={toggleClasses}>
          <button
            className={styles.toggleButton}
            onClick={this.onToggleClicked}
          >
            Menu
          </button>
        </div>

        <nav className={menuClasses}>
          <NavLink
            to={'/app'}
            onClick={this.onLinkClick}
            className={styles.link}
            activeClassName={styles.linkActive}
          >
            App
          </NavLink>
          <NavLink
            to={'/shop'}
            onClick={this.onLinkClick}
            className={styles.link}
            activeClassName={styles.linkActive}
          >
            Shop
          </NavLink>
          <NavLink
            to={'/about'}
            onClick={this.onLinkClick}
            className={styles.link}
            activeClassName={styles.linkActive}
          >
            About
          </NavLink>
          <NavLink
            to={'/contact'}
            onClick={this.onLinkClick}
            className={styles.link}
            activeClassName={styles.linkActive}
          >
            Contact
          </NavLink>

          {UserStore.userHasRoles && (
            <Link
              to={'/admin'}
              onClick={this.onLinkClick}
              className={styles.adminLink}
            >
              <LockIcon className={styles.adminIcon} />
            </Link>
          )}
        </nav>
      </div>
    );
  }
}

HamburgerMenu.propTypes = {
  UIStore: PropTypes.object,
  UserStore: PropTypes.object,
};

export default HamburgerMenu;
