import React from 'react';
import PropTypes from 'prop-types';

import styles from './OrderCompleted.sass';

const OrderCompleted = ({ orderNumber, customer }) => (
  <div className={styles.success}>
    <h1 className={styles.successTitle}>Order completed</h1>
    <h2>Thank you for your purchase {customer.firstName}!</h2>
    <p>
      Your order number is #{orderNumber}
    </p>
    <p>You will shortly receive a confirmation email at {customer.email}</p>
  </div>
);

OrderCompleted.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  customer: PropTypes.object.isRequired,
};

export default OrderCompleted;
