import STATES from 'constants/storeStates';
import { runInAction } from 'mobx';

export const statefulApiCall = (store, apiCall) =>
  new Promise(async (resolve, reject) => {
    runInAction(() => {
      store.state = STATES.PENDING;
    });
    const result = await apiCall();
    runInAction(() => {
      store.state = STATES.READY;
    });
    processResult(result, resolve, reject);
  }).catch((error) => {
    console.error(error); // eslint-disable-line

    runInAction(() => {
      store.state = STATES.ERROR;
      store.errorMessage = error;
    });
  });

export const apiCall = (call) =>
  new Promise(async (resolve, reject) => {
    const result = await call();
    processResult(result, resolve, reject);
  });

const processResult = (result, resolve, reject) => {
  if (result.ok) return resolve(result.json());

  return result.text().then((data) => {
    // for non-json errors
    if (data[0] !== '{') return reject(result.statusText);

    reject(JSON.parse(data).message);
  });
};

export const apiPost = (url, data) =>
  fetch(`/api/${url}`, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

export const apiPut = (url, data) =>
  fetch(`/api/${url}`, {
    method: 'PUT',
    credentials: 'same-origin',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

export const apiGet = (url) =>
  fetch(`/api/${url}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

export const apiDelete = (url) =>
  fetch(`/api/${url}`, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
