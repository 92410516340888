import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import classNames from 'classnames';
import { exportImages } from 'system/utils/assetUtils';

import styles from './AddToCartButton.sass';

import LoadingButton from 'components/common/buttons/LoadingButton';

@inject('DesignStore', 'SystemStore', 'CartStore', 'GTMTracking')
@observer
class AddToCartButton extends Component {
  @observable isPending = false;
  @observable shouldRedirect = false;

  constructor(props) {
    super(props);
  }

  onButtonClicked = async () => {
    this.props.GTMTracking.trackEvent('clickBuyNow');
    this.isPending = true;

    await this.addToCart();

    this.isPending = false;
    this.shouldRedirect = true;
  };

  async addToCart() {
    const { CartStore, SystemStore, productSlug } = this.props;

    const design = productSlug
      ? await this.getSavedDesign()
      : await this.getAppDesign();

    if (!design) return;

    const price = productSlug
      ? design.prices[SystemStore.settings.material]
      : SystemStore.ui.designPrice;
    const { material } = SystemStore.settings;

    CartStore.addToCart({
      design: design,
      material: material,
      price: price,
    });
  }

  async getSavedDesign() {
    const { DesignStore, productSlug } = this.props;

    return DesignStore.loadedDesign &&
      DesignStore.loadedDesign.slug === productSlug
      ? DesignStore.loadedDesign
      : await DesignStore.getDesignBySlug(productSlug);
  }

  async getAppDesign() {
    const { DesignStore, SystemStore } = this.props;

    if (!SystemStore.demo) return null;

    // save design without images
    return await DesignStore.saveDesign(
      SystemStore,
      exportImages(SystemStore.demo, true)
    );
  }

  render() {
    const { disabled, className } = this.props;

    const addToCartButtonClasses = classNames({
      [className]: !!className,
      [styles.addToCartButton]: !className,
      [styles.addToCartButtonDisabled]: !!disabled,
    });

    return this.shouldRedirect ? (
      <Redirect to={'/cart'} />
    ) : (
      <LoadingButton
        onClick={this.onButtonClicked}
        disabled={!!disabled}
        className={addToCartButtonClasses}
        isLoading={this.isPending}
      >
        Add to Cart
      </LoadingButton>
    );
  }
}

AddToCartButton.propTypes = {
  CartStore: PropTypes.object,
  DesignStore: PropTypes.object,
  SystemStore: PropTypes.object,
  GTMTracking: PropTypes.object,
  productSlug: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

AddToCartButton.defaultProps = {
  disabled: false,
};

export default AddToCartButton;
