import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import styles from './GenericHeader.sass';

@inject('UIStore')
@observer
class GenericHeader extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.UIStore.scrollToTop();
  }

  render() {
    const { title } = this.props;

    return (
      <div className={styles.wrapper}>
        <Helmet>
          <title>{title} | Hexatope</title>
        </Helmet>
        <h1 className={styles.pageTitle}>{title}</h1>
      </div>
    );
  }
}

GenericHeader.propTypes = {
  title: PropTypes.string,
  UIStore: PropTypes.object,
};

export default GenericHeader;
