import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { RENDER_MATERIALS } from 'constants/systemOptions';
import { formatAsGBP } from 'utils/currencyUtils';
import { pluraliseProductType } from 'utils/urlUtils';

import styles from './ProductCard.sass';

import CloudinaryImage from 'components/common/CloudinaryImage';
import ProductCardAdminTools from './ProductCardAdminTools';

const ProductCard = ({
  product,
  className,
  renderMaterial,
  priceMaterial,
  onCardClicked,
  showDetails,
  isActive,
  isTransitioning,
  showAdminTools,
  ...props
}) => {
  const cardClasses = classNames({
    [styles.card]: true,
    [styles.cardWithDetails]: showDetails,
    [styles.cardActive]: isActive,
    [className]: !!className,
  });
  const imageClasses = classNames({
    [styles.image]: true,
    [styles.imageHidden]: isTransitioning,
  });

  return (
    <article className={cardClasses} {...props}>
      {showAdminTools && <ProductCardAdminTools product={product} />}
      <Link
        to={`/shop/${pluraliseProductType(product.productType)}/${
          product.slug
        }`}
        className={styles.imageWrapper}
        onClick={onCardClicked}
      >
        {product.media &&
          product.media[renderMaterial] && (
            <CloudinaryImage
              key={renderMaterial + product._id}
              data={product.media[renderMaterial]}
              className={imageClasses}
              scalar={1.1}
            />
          )}
        <aside className={styles.meta}>
          <h3>{product.name}</h3>
          {priceMaterial ? (
            <div>{formatAsGBP(product.prices[priceMaterial], true, false)}</div>
          ) : (
            <div>
              from{' '}
              {formatAsGBP(
                Math.min(
                  ...Object.keys(product.prices).map(
                    (material) => product.prices[material]
                  )
                ),
                true,
                false
              )}
            </div>
          )}
        </aside>
      </Link>
    </article>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  renderMaterial: PropTypes.string,
  showDetails: PropTypes.bool,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  onCardClicked: PropTypes.func,
  showAdminTools: PropTypes.bool,
  priceMaterial: PropTypes.string,
  isTransitioning: PropTypes.bool,
};

ProductCard.defaultProps = {
  renderMaterial: RENDER_MATERIALS[0],
};

export default ProductCard;
