import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './InputElement.sass';

const InputElement = ({
  name,
  label,
  value,
  inline,
  className,
  type,
  darkBackground,
  darkBorder,
  children,
  ...props
}) => {
  const wrapperClasses = classNames({
    [styles.wrapper]: true,
    [styles.inlineWrapper]: inline,
    [styles.wrapperWithSelect]: type === 'select',
    [className]: !!className,
  });
  const labelClasses = classNames({
    [styles.label]: !inline,
    [styles.labelWithValue]: !inline && !!value,
    [styles.inlineLabel]: inline,
  });
  const inputClasses = classNames({
    [styles.input]: !inline,
    [styles.selectInput]: type === 'select',
    [styles.inlineInput]: inline,
    [styles.inputDarkBackground]: darkBackground,
    [styles.inputDarkBorder]: darkBorder,
  });

  return (
    <div className={wrapperClasses}>
      {type === 'select' ? (
        <select value={value} name={name} {...props} className={inputClasses}>
          {children}
        </select>
      ) : (
        <input
          type={type}
          value={value}
          name={name}
          {...props}
          className={inputClasses}
        />
      )}
      {label && (
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
      )}
    </div>
  );
};

InputElement.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  darkBackground: PropTypes.bool,
  darkBorder: PropTypes.bool,
  inline: PropTypes.bool,
};

InputElement.defaultProps = {
  type: 'text',
  inline: false,
  darkBackground: false,
  darkBorder: true,
};

export default InputElement;
