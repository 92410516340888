import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { observable, reaction } from 'mobx';
import classNames from 'classnames';
import { checkerboard } from 'utils/numberUtils';
import { RENDER_MATERIALS } from 'constants/systemOptions';

import styles from './ProductGrid.sass';

import ProductCard from './ProductCard';

@inject('UIStore', 'SystemStore', 'DesignStore')
@observer
class ProductGrid extends Component {
  wrapperElement = undefined;
  @observable columns = undefined;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.resizeReaction = reaction(
      () => this.props.UIStore.windowWidth,
      this.calculateColumns
    );
  }

  componentWillUnmount() {
    // dispose of reaction
    this.resizeReaction();
  }

  onWrapperMount = (element) => {
    if (!element) return;

    this.wrapperElement = element;

    this.calculateColumns();
  };

  calculateColumns = () => {
    if (!this.wrapperElement) return;
    const { biggerImages } = this.props;
    const width = this.wrapperElement.offsetWidth;

    this.columns = Math.max(
      biggerImages ? Math.floor(width / 250) : Math.floor(width / 160),
      2
    );
  };

  render() {
    const {
      SystemStore,
      DesignStore,
      designs,
      biggerImages, // eslint-disable-line
      UIStore, // eslint-disable-line
      columns,
      marginless,
      checkerboardMaterial,
      className,
      ...props
    } = this.props;

    const cards = (designs || []).map((design, i) => {
      const materialIndex = checkerboard(i, columns || this.columns);
      const renderMaterial = checkerboardMaterial
        ? RENDER_MATERIALS[Object.keys(RENDER_MATERIALS)[materialIndex]]
        : SystemStore.settings.renderMaterial;
      return (
        <ProductCard
          key={i}
          product={design}
          renderMaterial={renderMaterial}
          isActive={
            DesignStore.loadedDesign &&
            design._id === DesignStore.loadedDesign._id
          }
          showDetails={biggerImages}
          {...props}
        />
      );
    });

    const columnCount = columns || this.columns;
    const productListClasses = classNames({
      [styles.productList]: true,
      [styles.productListMarginless]: marginless,
      [styles[`productListColumns${columnCount}`]]: true,
    });

    return (
      <div ref={this.onWrapperMount} className={className}>
        {!!cards.length &&
          columnCount && (
            <section className={productListClasses}>{cards}</section>
          )}
      </div>
    );
  }
}

ProductGrid.propTypes = {
  UIStore: PropTypes.object,
  DesignStore: PropTypes.object,
  SystemStore: PropTypes.object,
  biggerImages: PropTypes.bool,
  designs: MobXPropTypes.arrayOrObservableArray,
  showAdminTools: PropTypes.bool,
  checkerboardMaterial: PropTypes.bool,
  columns: PropTypes.number,
  marginless: PropTypes.bool,
  className: PropTypes.string,
};

ProductGrid.defaultProps = {
  showAdminTools: false,
  checkerboardMaterial: true,
};

export default ProductGrid;
