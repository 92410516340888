import React from 'react';

import styles from '../page.sass';

import GenericHeader from 'components/common/banners/GenericHeader';

const Delivery = () => (
  <div>
    <GenericHeader title={'Delivery'} />

    <div className={styles.wrapper}>
      <p>
        As all Hexatope products are made to order please allow 3 weeks for
        order dispatch.
      </p>

      <h2>Domestic Orders</h2>
      <p>Shipping within the UK is free of charge.</p>
      <p>
        Orders under the value of £100 are send by First Class mail which does
        not require a signature upon delivery.
      </p>
      <p>
        Orders above £100 are send via Royal Mail’s Special Delivery next day
        service which does require a signature upon delivery.
      </p>

      <h2>International Orders</h2>
      <p>Shipping outside of the UK is for a flat fee of £9.</p>
      <p>
        All international orders are sent via Royal Mail’s International Tracked
        service with appropriate insurance.
      </p>
      <p>
        They aim to delivery to Europe within 3-5 days and Worldwide within 5-7
        days.
      </p>

      <hr />
      <p>
        In a hurry? Send us an email at{' '}
        <a href={'mailto:orders@hexatope.io'}>orders@hexatope.io</a> and we’ll
        try and work something out.
      </p>
    </div>
  </div>
);

export default Delivery;
