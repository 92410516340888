import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable, reaction } from 'mobx';

import styles from './RelatedProducts.sass';

import ProductGrid from 'components/common/ProductGrid';

@inject('UIStore', 'SystemStore', 'ShopStore')
@observer
class RelatedProducts extends Component {
  @observable relatedProducts = [];
  @observable columns = 0;
  productViews = {};

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { ShopStore, UIStore, currentProduct } = this.props;

    this.calculateColumns();
    ShopStore.loadShopDesigns().then(() => {
      this.chooseRelatedProducts(currentProduct);
    });

    this.resizeReaction = reaction(
      () => UIStore.windowWidth,
      this.calculateColumns,
      {
        delay: 100,
      }
    );
  }

  componentWillUnmount() {
    this.resizeReaction();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.currentProduct._id !== newProps.currentProduct._id) {
      this.chooseRelatedProducts(newProps.currentProduct);
    }
  }

  calculateColumns = () => {
    this.columns = Math.max(
      Math.floor((this.props.UIStore.windowWidth-400) / 360),
      2
    );
  };

  chooseRelatedProducts = (currentProduct) => {
    // add a view for each related product
    this.relatedProducts.slice(0, this.columns).forEach((product) => {
      let currentValue = this.productViews[product._id];
      this.productViews[product._id] = currentValue ? currentValue + 1 : 1;
    });

    const { ShopStore } = this.props;

    const indexOfCurrent = ShopStore.sortedDesigns.findIndex(
      (design) => design._id === currentProduct._id
    );

    // cut from where the current product is in the list
    this.relatedProducts = ShopStore.sortedDesigns
      .slice(indexOfCurrent + 1)
      .concat(ShopStore.sortedDesigns.slice(0, indexOfCurrent))
      .sort((a, b) => {
        // prioritise unseen products
        return (
          (this.productViews[a._id] || 0) - (this.productViews[b._id] || 0)
        );
      });
  };

  render() {
    const { ShopStore, SystemStore } = this.props;

    const designs = this.relatedProducts.slice(0, this.columns);

    return (
      <div className={styles.wrapper}>
        <h3 className={styles.title}>
          Related Products
        </h3>
        <ProductGrid
          checkerboardMaterial={!ShopStore.materialFilter}
          priceMaterial={ShopStore.materialFilter}
          designs={designs}
          columns={this.columns}
          isTransitioning={SystemStore.ui.demoIsTransitioning}
          marginless
        />
      </div>
    );
  }
}

RelatedProducts.propTypes = {
  UIStore: PropTypes.object,
  SystemStore: PropTypes.object,
  ShopStore: PropTypes.object,
  currentProduct: PropTypes.object,
};

export default RelatedProducts;
