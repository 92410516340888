import React from 'react';

import styles from './About.sass';

import CloudinaryImage from 'components/common/CloudinaryImage';
import GenericHeader from 'components/common/banners/GenericHeader';

const About = () => (
  <div>
    <GenericHeader title={'About'} />

    <div className={styles.wrapperWithSidebar}>
      <div className={styles.contentWrapper}>
        <div className={styles.words}>
          <div className={styles.about}>
            <p className={styles.em}>
              Hexatope is a new kind of jewellery studio by British designer and
              developer{' '}
              <a href={'http://charlottedann.com'}>Charlotte&nbsp;Dann</a>.
            </p>
            <p className={styles.em}>
              Hexatope is a tool that enables anyone to become a designer by
              intuitively interacting with a hexagonal grid, and leverages
              digital fabrication techniques to bring your designs to life.
            </p>

            <p className={styles.description}>
              While completing a Masters in Computational Arts, Charlotte began
              experimenting with using the framework of a hexagonal grid to{' '}
              <a href={'https://codepen.io/pouretrebelle/post/hexagons'}>
                generate art with code
              </a>. Combining this technology with her other vocation, jewellery
              design, she began 3D printing the derived pieces and{' '}
              <a href={'http://overlap.show/artist/charlotte-dann/'}>
                casting them into precious metal
              </a>. In October 2017 she launched{' '}
              <a
                href={
                  'https://www.kickstarter.com/projects/charlottedann/hexatope-design-your-own-unique-jewellery'
                }
              >
                the Hexatope Kickstarter
              </a>, raising over £15,000 in funding to pursue Hexatope fulltime.
            </p>
          </div>

          <div className={styles.press}>
            <h2 className={styles.pressTitle}>Selected Press:</h2>
            <ol className={styles.pressList}>
              <li className={styles.pressItem}>
                <a
                  className={styles.pressLink}
                  target={'_blank'}
                  href={
                    'https://www.itsnicethat.com/articles/charlotte-dann-hexatope-product-design-digital-231017'
                  }
                >
                  Hexatope: the web-app utilising computational arts to make
                  personalised&nbsp;jewellery
                </a>
                <cite className={styles.pressCite}>It’s Nice That</cite>
              </li>
              <li className={styles.pressItem}>
                <a
                  className={styles.pressLink}
                  target={'_blank'}
                  href={
                    'https://blog.producthunt.com/when-tech-and-jewelry-collide-the-maker-of-hexatope-shares-her-story-a9f2819f49a5'
                  }
                >
                  When tech and jewelry collide: the maker of Hexatope
                  shares&nbsp;her&nbsp;story
                </a>
                <cite className={styles.pressCite}>Product Hunt</cite>
              </li>
              <li className={styles.pressItem}>
                <a
                  className={styles.pressLink}
                  target={'_blank'}
                  href={
                    'https://blog.codepen.io/2017/10/17/147-charlotte-dann/'
                  }
                >
                  Charlotte Dann on Codepen Radio
                </a>
              </li>
            </ol>
          </div>
        </div>

        <div className={styles.imagery}>
          <CloudinaryImage
            className={styles.image1}
            data={{
              version: 1520597739,
              public_id: 'marketing/about-gold',
              ext: 'jpg',
            }}
          />
          <CloudinaryImage
            className={styles.image2}
            data={{
              version: 1520597739,
              public_id: 'marketing/about-charlotte',
              ext: 'jpg',
            }}
          />
          <CloudinaryImage
            className={styles.image3}
            data={{
              version: 1520597737,
              public_id: 'marketing/about-silver',
              ext: 'jpg',
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default About;
