import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { observer, inject } from 'mobx-react';

@inject('CartStore', 'NotificationStore')
@observer
class DiscountWrapper extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const { CartStore, NotificationStore, code } = this.props;
    CartStore.testDiscountCode(code).then((discount) => {
      NotificationStore.flash(
        `${discount.percentageOff}% off with '${
          discount.code
        }' – discount applied at checkout`
      );
    });
  }

  render() {
    const { Component } = this.props;

    return <Component />;
  }
}

DiscountWrapper.propTypes = {
  CartStore: PropTypes.object,
  NotificationStore: PropTypes.object,
  Component: PropTypes.func,
  code: PropTypes.string,
};

export default DiscountWrapper;
