import { saveAs } from 'file-saver';
import {
  findMouseHexagon,
  drawFilledHexagon,
  drawOutlinedHexagon,
} from './utils/hexagonUtils';
import { TOOL_MODES, GRID_ROTATION } from 'constants/systemOptions';

class Canvas {
  constructor(store) {
    this.store = store;
    this.canvas = undefined;
    this.c = undefined;

    this.internalWidth = undefined;
    this.internalHeight = undefined;
    this.verticalPadding = undefined;
    this.horizontalPadding = undefined;

    this.isMouseInside = false;
    this.pixelRatio = window.devicePixelRatio || 1;
  }

  setup(canvas, wrapperElement) {
    this.canvas = canvas;
    this.c = canvas.getContext('2d');
    this.c.scale(this.pixelRatio, this.pixelRatio);

    const { hexRadius, hexMargin } = this.store.config;
    const hexHeight = Math.sqrt(3) * hexRadius;
    this.internalWidth = (this.store.columns + 2 / 3) * (hexRadius * 3);
    this.internalHeight = (this.store.rows + 1) * (hexHeight / 2);
    this.verticalPadding = hexMargin;
    this.horizontalPadding = hexMargin;

    this.updateDimensions(wrapperElement);
  }

  updateMousePos() {
    const { mouseX, mouseY } = this.store.globalUi;
    const { canvasBoundingBox } = this.store.ui;

    // pixel position of mouse relative to canvas wrapper
    let absoluteMouseX = mouseX - canvasBoundingBox.left;
    let absoluteMouseY = mouseY - canvasBoundingBox.top;

    // this is confusing
    const isHorizontal =
      this.store.settings.gridRotation === GRID_ROTATION.HORIZONTAL;
    let rotatedMouseX = isHorizontal
      ? absoluteMouseY - (canvasBoundingBox.height - this.internalWidth) / 2
      : absoluteMouseX - (canvasBoundingBox.width - this.internalWidth) / 2;
    let rotatedMouseY = isHorizontal
      ? canvasBoundingBox.width -
        absoluteMouseX -
        (canvasBoundingBox.width - this.internalHeight) / 2
      : absoluteMouseY - (canvasBoundingBox.height - this.internalHeight) / 2;

    // inset by hexMargin
    const relativeMouseX = rotatedMouseX - this.verticalPadding;
    const relativeMouseY = rotatedMouseY - this.horizontalPadding;

    // dealing with pixel ratio and the difference between the actual hexagons and the canvas
    this.store.ui.setCanvasRelativeMousePos(relativeMouseX, relativeMouseY);

    let mouseInside = true;
    if (absoluteMouseX > canvasBoundingBox.width || absoluteMouseX < 0)
      mouseInside = false;
    if (absoluteMouseY > canvasBoundingBox.height || absoluteMouseY < 0)
      mouseInside = false;
    if (
      relativeMouseX < 0 ||
      relativeMouseX > this.internalWidth + this.horizontalPadding * 2
    )
      mouseInside = false;
    if (
      relativeMouseY < 0 ||
      relativeMouseY > this.internalHeight + this.verticalPadding * 2
    )
      mouseInside = false;
    this.isMouseInside = mouseInside;

    this.store.setMouseTargetHex(findMouseHexagon(this.store));
  }

  updateDimensions(wrapperElement) {
    // sometimes buggy on page load
    if (!wrapperElement) return;

    const boundingBox = wrapperElement.getBoundingClientRect();

    this.store.ui.updateCanvasBoundingBox(boundingBox);

    const externalWidth = this.internalWidth + this.horizontalPadding * 2;
    const externalHeight = this.internalHeight + this.verticalPadding * 2;

    this.canvas.width = externalWidth * this.pixelRatio;
    this.canvas.style.width = `${externalWidth}px`;
    this.canvas.height = externalHeight * this.pixelRatio;
    this.canvas.style.height = `${externalHeight}px`;

    // redraw canvas
    this.draw();
  }

  draw(drawMouseHexagon) {
    if (!this.c) return;

    this.c.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.c.strokeStyle = this.store.config.gridColor;
    this.c.lineWidth = this.store.config.hexMargin * this.pixelRatio;

    this.c.save();
    // inset by hexagon margin
    this.c.translate(
      this.verticalPadding * this.pixelRatio,
      this.horizontalPadding * this.pixelRatio
    );

    // draw all hexagons before lines to avoid overlap
    this.store.eachHexagon((hex) => {
      this.drawHex(hex);
    });

    // draw hexagon at mouse position
    if (drawMouseHexagon && this.isMouseInside) this.drawMouseHexagon();

    // draw grid lines
    this.store.eachHexagon((hex) => {
      drawOutlinedHexagon(
        this.c,
        hex.layoutPos.multiplyNew(this.store.config.hexRadius),
        this.pixelRatio
      );
    });

    // draw curves
    this.drawAllHexCurves(this.c);

    this.c.restore();
  }

  drawMouseHexagon() {
    switch (this.store.settings.toolMode) {
      case TOOL_MODES.DRAW:
        // on right click use erase active color
        this.c.fillStyle = this.store.ui.isDrawing
          ? this.store.ui.lastMouseButton === 2
            ? this.store.config.mouseEraseActiveColor
            : this.store.config.mouseActiveColor
          : this.store.config.mouseColor;
        break;
      case TOOL_MODES.EDIT:
        this.c.fillStyle = this.store.ui.isDrawing
          ? this.store.config.mouseEditActiveColor
          : this.store.config.mouseEditColor;
        break;
      case TOOL_MODES.ERASE:
        this.c.fillStyle = this.store.ui.isDrawing
          ? this.store.config.mouseEraseActiveColor
          : this.store.config.mouseEraseColor;
        break;
    }

    const target = this.store.mouseTargetHex;
    if (target) {
      drawFilledHexagon(
        this.c,
        target.layoutPos.multiplyNew(this.store.config.hexRadius),
        this.pixelRatio
      );
    }
  }

  drawHex(hex) {
    switch (hex.active) {
      case 0:
        this.c.fillStyle = this.store.config.inactiveColor;
        break;
      case 1:
        this.c.fillStyle = this.store.config.activeColor;
        break;
      case 2:
        this.c.fillStyle = this.store.config.doubleActiveColor;
        break;
    }
    drawFilledHexagon(
      this.c,
      hex.layoutPos.multiplyNew(this.store.config.hexRadius),
      this.pixelRatio
    );
  }

  drawAllHexCurves(c) {
    this.store.eachHexagon((hex) => {
      this.drawHexCurves(hex, c);
    });
  }

  drawHexCurves(hex, c) {
    // don't do anything if it's not in an active state
    if (!hex.active) return;

    c.save();
    const startX =
      hex.layoutPos.x * this.pixelRatio * this.store.config.hexRadius;
    const startY =
      hex.layoutPos.y * this.pixelRatio * this.store.config.hexRadius;
    c.translate(startX, startY);
    const scalar = this.pixelRatio * this.store.config.hexRadius;
    c.lineWidth = this.store.config.hexLineWeight * this.pixelRatio;

    hex.curves.forEach((curve) => {
      const { start, end } = curve;
      c.strokeStyle = this.store.config.lineColor;
      // fade the curves if in erase mode
      // or it's the old curve when in layout cycle mode
      if (
        curve.drawFaded ||
        (hex === this.store.mouseTargetHex &&
          this.store.settings.toolMode === TOOL_MODES.ERASE)
      ) {
        c.strokeStyle = this.store.config.lineColorFaded;
      }
      c.beginPath();
      c.moveTo(start.pos.x * scalar, start.pos.y * scalar);
      c.bezierCurveTo(
        start.controlPos.x * scalar,
        start.controlPos.y * scalar,
        end.controlPos.x * scalar,
        end.controlPos.y * scalar,
        end.pos.x * scalar,
        end.pos.y * scalar
      );
      c.stroke();
      c.closePath();
    });

    c.restore();
  }

  async downloadSVG() {
    // bundle dependency seperately
    const C2S = await import(/* webpackChunkName: "canvas2svg" */ 'canvas2svg');

    // create new svg canvas context
    let svgC = new C2S(
      this.internalWidth * this.pixelRatio,
      this.internalHeight * this.pixelRatio
    );

    // draw all the hex curves to this context
    this.drawAllHexCurves(svgC);

    // create blob of svg content
    const blob = new Blob([svgC.getSerializedSvg()], {
      type: 'text/plain',
    });
    saveAs(blob, 'hexatope.svg');
  }
}

export default Canvas;
