import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { LIVE_MATERIAL_OPTIONS } from 'constants/systemOptions';

import Dropdown from 'components/common/Dropdown';

@inject('SystemStore')
@observer
class Picker extends Component {
  constructor(props) {
    super(props);
  }

  onMaterialChanged = (i) => {
    // use the index of the dropdown val to set material
    this.props.SystemStore.settings.setMaterial(LIVE_MATERIAL_OPTIONS[i].value);
  };

  render() {
    const { SystemStore, direction } = this.props;
    const materialOptionIndex = LIVE_MATERIAL_OPTIONS.findIndex((option) => {
      return option.value === SystemStore.settings.material;
    });

    return (
      <Dropdown
        options={LIVE_MATERIAL_OPTIONS}
        direction={direction}
        onChange={this.onMaterialChanged}
        currentOptionIndex={materialOptionIndex}
      />
    );
  }
}

Picker.propTypes = {
  SystemStore: PropTypes.object,
  direction: PropTypes.string,
};

export default Picker;
