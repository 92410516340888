const TOOL_MODES = {
  DRAW: 'draw',
  EDIT: 'edit',
  ERASE: 'erase',
};

const GRID_ROTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

const RENDER_MATERIALS = {
  GOLD: 'gold',
  SILVER: 'silver',
};

const MATERIALS = {
  GOLD18K: 'gold18k',
  GOLD18KP: 'gold18kp',
  STERLING: 'sterling',
};

const MATERIAL_OPTIONS = [
  {
    label: 'Sterling Silver',
    renderMaterial: RENDER_MATERIALS.SILVER,
    value: MATERIALS.STERLING,
    abbr: 'SS',
  },
  {
    label: '18ct Yellow Gold Plated',
    renderMaterial: RENDER_MATERIALS.GOLD,
    value: MATERIALS.GOLD18KP,
    abbr: 'p-18k',
  },
  {
    label: '18ct Yellow Gold',
    renderMaterial: RENDER_MATERIALS.GOLD,
    value: MATERIALS.GOLD18K,
    abbr: '18k',
  },
];

const LIVE_MATERIAL_OPTIONS = [MATERIAL_OPTIONS[0], MATERIAL_OPTIONS[1]];

module.exports = {
  TOOL_MODES: TOOL_MODES,
  GRID_ROTATION: GRID_ROTATION,
  RENDER_MATERIALS: RENDER_MATERIALS,
  MATERIALS: MATERIALS,
  MATERIAL_OPTIONS: MATERIAL_OPTIONS,
  LIVE_MATERIAL_OPTIONS: LIVE_MATERIAL_OPTIONS,
};
