import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import styles from './ProductCardAdminTools.sass';

@inject('DesignStore', 'ShopStore')
@observer
class AdminTools extends Component {
  constructor(props) {
    super(props);
  }

  deleteDesign = (id) => {
    this.props.DesignStore.deleteDesignById(id);
  };

  addDesignToShop = (id) => {
    this.props.ShopStore.addDesignToShop(id).then(() => this.forceUpdate());
  };

  removeDesignFromShop = (id) => {
    this.props.ShopStore.removeDesignFromShop(id).then(() =>
      this.forceUpdate()
    );
  };

  render() {
    const { product, ShopStore } = this.props;
    const inCollection = ShopStore.designIsInShop(product._id);

    return (
      <div className={styles.wrapper}>
        <p>
          <button
            className={styles.button}
            onClick={() => this.deleteDesign(product._id)}
          >
            Delete
          </button>

          {inCollection ? (
            <button
              className={styles.buttonRemove}
              onClick={() => this.removeDesignFromShop(product._id)}
            >
              Remove
            </button>
          ) : (
            <button
              className={styles.buttonAdd}
              onClick={() => this.addDesignToShop(product._id)}
            >
              Add
            </button>
          )}

          <Link className={styles.button} to={`/app/${product.slug}`}>
            Edit
          </Link>
        </p>
      </div>
    );
  }
}

AdminTools.propTypes = {
  product: PropTypes.object.isRequired,
  DesignStore: PropTypes.object,
  ShopStore: PropTypes.object,
};

export default AdminTools;
