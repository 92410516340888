import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import styles from './Cart.sass';

import GenericHeader from 'components/common/banners/GenericHeader';
import ProductList from 'components/common/ProductList';
import ArrowLink from 'components/common/ArrowLink';
import OrderOptions from './OrderOptions';

@inject('CartStore')
@observer
class Cart extends Component {
  constructor(props) {
    super(props);
  }

  onProductQuantityChange = (product, difference) => {
    const { CartStore } = this.props;
    if (difference === 1) {
      CartStore.addToCart(product);
    }
    if (difference === -1) {
      CartStore.removeFromCart(product);
    }
  };

  render() {
    const { CartStore } = this.props;

    return (
      <div>
        <GenericHeader title={'Cart'} />

        <div className={styles.wrapperWithSidebar}>
          {CartStore.isEmpty ? (
            <div className={styles.emptyCart}>
              <h2 className={styles.emptyCartTitle}>Your cart is empty</h2>
              <ArrowLink to={'/shop'}>Go to the Shop</ArrowLink>
            </div>
          ) : (
            <div className={styles.cartWrapper}>
              <div className={styles.productList}>
                <ProductList
                  onProductQuantityChange={this.onProductQuantityChange}
                  products={CartStore.productsWithQuantities}
                />
              </div>
              <OrderOptions />
              <div className={styles.checkoutButtonWrapper}>
                <Link to={'/checkout'} disabled className={styles.checkoutButton}>
                  Proceed to Checkout
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Cart.propTypes = {
  CartStore: PropTypes.object,
};

export default Cart;
