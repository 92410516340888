export const formatAsGBP = (
  price,
  useSymbol = false,
  forceDecimal = true,
  roundDown = false
) => {
  price = roundDown ? Math.floor(price) : price;
  price =
    !forceDecimal && price % 1 === 0 ? price : parseFloat(price).toFixed(2);
  return useSymbol ? `£${price}` : `GBP ${price}`;
};
