import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { TYPEKIT_KIT_ID } from 'constants/external';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeProvider = ({ children }) => (
  <Elements
    stripe={stripePromise}
    options={{
      fonts: [
        {
          cssSrc: `https://use.typekit.net/${TYPEKIT_KIT_ID}.css`,
        },
      ],
    }}
  >
    {children}
  </Elements>
);

StripeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StripeProvider;
