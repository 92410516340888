import React from 'react';

import styles from '../page.sass';

import GenericHeader from 'components/common/banners/GenericHeader';

const Returns = () => (
  <div>
    <GenericHeader title={'Returns'} />

    <div className={styles.wrapper}>
      <p>
        We accept returns and exchanges on eligible items within 2 weeks of an
        order’s receipt. They must be returned in the original packaging and be
        unworn.
      </p>

      <p>
        The customer is responsible for all incurred shipping costs of returning
        an order unless the item(s) arrive damaged or are sent in error.
      </p>

      <p>
        Please email{' '}
        <a href={'mailto:orders@hexatope.io'}>orders@hexatope.io</a> to initiate
        a return or exchange.
      </p>

      <h2>Eligible Items</h2>

      <p>
        All items from the pre-designed collection are eligible for return, but
        unfortunately we are unable to accept returns on custom orders.
      </p>
    </div>
  </div>
);

export default Returns;
