const MATERIAL_COST = {
  gold18k: {
    perCm3: 35 * 15.6, // price per gram and grams per cm3 with less markup
    castingOverhead: 0,
    castingPerCm2: 0,
    platingPerCm3: 0,
    chain: 60,
  },
  gold18kp: {
    perCm3: 0.85 * 10.36 * 2, // double for sprue volume
    castingOverhead: 3,
    castingPerCm2: 0.4,
    platingPerCm3: 20,
    chain: 4.5,
  },
  sterling: {
    perCm3: 0.85 * 10.36 * 2, // double for sprue volume
    castingOverhead: 3,
    castingPerCm2: 0.4,
    platingPerCm3: 0,
    chain: 6,
  },
};

const getProductPrices = (data) => {
  let prices = {};

  Object.keys(MATERIAL_COST).forEach((material) => {
    prices[material] = getProductPrice(material, data);
  });

  return prices;
};

const getProductPrice = (material, data) =>
  Math.round(estimatePrice(material, 3, data) / 5) * 5;

const estimatePrice = (material, markup, { metalVolume, pieceArea }) => {
  let price = 0;

  const materialCost = MATERIAL_COST[material];

  // metal price
  const metalPrice = metalVolume * materialCost.perCm3;
  price += metalPrice;

  // casting
  price +=
    materialCost.castingOverhead + pieceArea * materialCost.castingPerCm2;

  // plating
  price += metalVolume * materialCost.platingPerCm3;

  // resin price
  price += metalVolume * 10 * 0.3; // £300/canister of 1000cm3

  // finishing price
  const finishingPrice = 5 + metalVolume * 8;
  price += finishingPrice;

  // packaging
  price += 2;

  // chain
  price += materialCost.chain;

  // markup
  price *= markup;

  return price;
};

module.exports = {
  getProductPrices,
  estimatePrice,
};
