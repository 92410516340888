import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import styles from './InstagramBanner.sass';

import InstagramIcon from 'components/common/icons/social/InstagramIcon';


const allPosts = [
  {
    link: 'https://www.instagram.com/p/BiXF5xyHZ4J/',
    images: {
      low_resolution: {
        url: require('assets/images/instagram/1.jpg'),
      },
    },
    caption: {
      text: 'Shop redesign ✔️',
    },
  },
  {
    link: 'https://www.instagram.com/p/BiM-y1AHgOm/',
    images: {
      low_resolution: {
        url: require('assets/images/instagram/2.jpg'),
      },
    },
    caption: {
      text: 'Twin Sybils | Ficus elastica',
    },
  },
  {
    link: 'https://www.instagram.com/p/Bh_X7xgnm8r/',
    images: {
      low_resolution: {
        url: require('assets/images/instagram/3.jpg'),
      },
    },
    caption: {
      text: 'Sylvie glistening',
    },
  },
  {
    link: 'https://www.instagram.com/p/BhzCCAyH54D/',
    images: {
      low_resolution: {
        url: require('assets/images/instagram/4.jpg'),
      },
    },
    caption: {
      text: 'A bunch of Collection pieces hardening in our UV curing chamber. After each #hexatope piece is 3D printed it needs to be cured by UV rays for 24 hours so the material is at its optimum for the casting process',
    },
  },
  {
    link: 'https://www.instagram.com/p/BhIBihfgUyA/',
    images: {
      low_resolution: {
        url: require('assets/images/instagram/5.jpg'),
      },
    },
    caption: {
      text: 'Cordelia in 18K making her way out of the studio',
    },
  },
  {
    link: 'https://www.instagram.com/p/BglMTdeBjlg/',
    images: {
      low_resolution: {
        url: require('assets/images/instagram/6.jpg'),
      },
    },
    caption: {
      text: 'Really drawn to this custom #hexatope piece',
    },
  },
];

@inject('UIStore')
@observer
class InstagramBanner extends Component {
  @observable posts = [];

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.fetchPosts();
  }

  fetchPosts() {
    fetch(
      `https://api.instagram.com/v1/users/self/media/recent/?access_token=${
        process.env.REACT_APP_INSTAGRAM_TOKEN
      }`
    )
      .then((result) => result.json())
      .then((result) => {
        if (!result.data) return;
        this.posts = result.data;
        this.posts.splice(6);
      });
  }

  render() {
    const posts = allPosts;//this.posts.slice();
    if (!this.props.UIStore.onMobile) posts.splice(5);

    return (
      <div className={styles.wrapper}>
        {posts.map((post, i) => (
          <a
            href={post.link}
            key={i}
            alt={post.caption ? post.caption.text : ''}
            target={'_blank'}
            className={styles.link}
          >
            <div
              className={styles.thumbnail}
              style={{
                backgroundImage: `url('${post.images.low_resolution.url}')`,
              }}
            />
            <InstagramIcon className={styles.instagramIcon} />
          </a>
        ))}
      </div>
    );
  }
}

InstagramBanner.propTypes = {
  UIStore: PropTypes.object,
};

export default InstagramBanner;
