import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import classNames from 'classnames';

import styles from './PanelWrapper.sass';

@inject('UIStore')
@observer
class PanelWrapper extends Component {
  constructor(props) {
    super(props);
    this.panelWrapperElement = undefined;
  }

  componentDidMount() {
    const { UIStore, hideFooter } = this.props;

    this.windowHeightReaction = reaction(
      () => UIStore.windowHeight,
      (windowHeight) => this.reactToWindowHeightChange(windowHeight),
      {
        fireImmediately: true,
      }
    );

    if (hideFooter) UIStore.hideFooter();
  }

  componentWillUnmount() {
    // dispose of reaction
    this.windowHeightReaction();

    this.props.UIStore.showFooter();
  }

  componentDidUpdate(prevProps) {
    const { UIStore, hideFooter } = this.props;

    if (hideFooter === prevProps.hideFooter) return;

    if (hideFooter) {
      UIStore.hideFooter();
    } else {
      UIStore.showFooter();
    }
  }

  reactToWindowHeightChange = (windowHeight) => {
    // hard set page height to stop mobile 100vh bug
    if (this.panelWrapperElement)
      this.panelWrapperElement.style.height = `${windowHeight}px`;
  };

  render() {
    const { overlayLeftOnMobile, children, className } = this.props;

    const wrapperClasses = classNames({
      [styles.wrapper]: true,
      [className]: !!className,
    });
    const leftPanelClasses = classNames({
      [styles.leftPanel]: true,
      [styles.leftPanelShiftMobile]: overlayLeftOnMobile,
    });

    return (
      <div
        className={wrapperClasses}
        ref={(element) => (this.panelWrapperElement = element)}
      >
        <div className={leftPanelClasses}>{children[0]}</div>
        <div className={styles.rightPanel}>{children[1]}</div>
      </div>
    );
  }
}

PanelWrapper.propTypes = {
  UIStore: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  overlayLeftOnMobile: PropTypes.bool,
  hideFooter: PropTypes.bool,
  className: PropTypes.string,
};

PanelWrapper.defaultProps = {
  hideFooter: true,
};

export default PanelWrapper;
