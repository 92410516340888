import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import ClickOutsideWrapper from 'components/common/ClickOutsideWrapper';

import styles from './Dropdown.sass';

const ENTER_KEY_CODE = 13;

@observer
class Dropdown extends Component {
  @observable isOpen = false;

  constructor(props) {
    super(props);
  }

  onChange = (i) => {
    this.props.onChange(i);
  };

  openDropdown = () => {
    this.isOpen = true;
  };

  closeDropdown = () => {
    this.isOpen = false;
  };

  render() {
    const {
      labelClassName,
      direction,
      options,
      currentOptionIndex,
      unselectedLabel,
    } = this.props;

    const labelClasses = classNames({
      [styles.label]: true,
      [styles.labelActive]: this.isOpen,
      [styles.labelOpenDown]: direction === 'down',
      [styles.labelOpenUp]: direction === 'up',
      [labelClassName]: !!labelClassName,
    });

    const optionsWrapperClasses = classNames({
      [styles.optionsWrapper]: true,
      [styles.optionsWrapperVisible]: this.isOpen,
      [styles.optionsWrapperOpenDown]: direction === 'down',
      [styles.optionsWrapperOpenUp]: direction === 'up',
    });

    const optionsClasses = classNames({
      [styles.options]: true,
      [styles.optionsOpenDown]: direction === 'down',
      [styles.optionsOpenUp]: direction === 'up',
    });

    const selectedOption =
      currentOptionIndex >= 0
        ? options[currentOptionIndex]
        : { label: unselectedLabel };
    const otherOptions = options
      .filter((option) => option !== selectedOption)
      .map((option, i) => (
        <button
          className={styles.option}
          key={i}
          tabIndex='1'
          onClick={() => {
            this.onChange(
              currentOptionIndex >= 0 && i >= currentOptionIndex ? i + 1 : i
            );
            this.closeDropdown();
          }}
          onKeyPress={(event) => {
            if (event.charCode === ENTER_KEY_CODE) {
              this.onChange(
                currentOptionIndex >= 0 && i >= currentOptionIndex ? i + 1 : i
              );
              this.closeDropdown();
            }
          }}
        >
          {option.label}
        </button>
      ));

    return (
      <div className={styles.wrapper} tabIndex='1' onFocus={this.openDropdown}>
        <div className={labelClasses} onMouseDown={this.openDropdown}>
          {selectedOption.label}
        </div>
        <div className={optionsWrapperClasses}>
          {this.isOpen ? (
            <ClickOutsideWrapper
              onDocumentClickedOutside={this.closeDropdown}
              className={optionsClasses}
            >
              {otherOptions}
            </ClickOutsideWrapper>
          ) : (
            <div className={optionsClasses}>{otherOptions}</div>
          )}
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
  direction: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  unselectedLabel: PropTypes.string,
  currentOptionIndex: PropTypes.number.isRequired,
};

Dropdown.defaultProps = {
  direction: 'down',
};

export default Dropdown;
