import React from 'react';

import styles from './PaymentMethods.sass';

import stripe from 'assets/images/payment/stripe.svg';
import visa from 'assets/images/payment/visa.svg';
import mastercard from 'assets/images/payment/mastercard.svg';
import maestro from 'assets/images/payment/maestro.svg';
import amex from 'assets/images/payment/amex.svg';

const PaymentMethods = () => (
  <div className={styles.wrapper}>
    <a href={'https://stripe.com'} target={'_blank'}>
      <img src={stripe} className={styles.icon} />
    </a>
    <img src={visa} className={styles.icon} />
    <img src={mastercard} className={styles.icon} />
    <img src={maestro} className={styles.icon} />
    <img src={amex} className={styles.icon} />
  </div>
);

export default PaymentMethods;
