import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { MAILCHIMP_USER_ID, MAILCHIMP_LIST_ID } from 'constants/external';

import styles from './NewsletterForm.sass';

@observer
class NewsletterForm extends Component {
  @observable email = '';

  constructor(props) {
    super(props);
  }

  onEmailChanged = (e) => {
    this.email = e.target.value;
  };

  render() {
    return (
      <form
        action={`https://hexatope.us17.list-manage.com/subscribe/post?u=${MAILCHIMP_USER_ID}&id=${MAILCHIMP_LIST_ID}`}
        method={'post'}
        target={'_blank'}
        className={styles.form}
        noValidate
      >
        <div className={styles.fields}>
          <label htmlFor={'mce-EMAIL'} style={{ display: 'none' }}>
            Email Address
          </label>
          <input
            type={'email'}
            name={'EMAIL'}
            id={'mce-EMAIL'}
            onChange={this.onEmailChanged}
            value={this.email}
            className={styles.emailField}
            placeholder={'Email Address'}
          />
          <div style={{ position: 'absolute', left: -5000 }} aria-hidden={true}>
            <input
              type={'text'}
              name={'b_4c51e43f1162adc62d41e6ea5_7a87ac0c36'}
              tabIndex={-1}
              value={''}
            />
          </div>
          <div className={styles.submitButtonWrapper}>
            <button
              type={'submit'}
              name={'subscribe'}
              className={styles.submitButton}
            >
              Subscribe
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default NewsletterForm;
