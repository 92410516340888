import { observable, computed } from 'mobx';
import { getEdgePoint } from './utils/hexagonUtils';

class CurveCap {
  constructor(curve, point, controlMagnitude, isExternal) {
    this.curve = curve;
    this.store = curve.hexagon.store;

    this.pos = point;

    this.isExternal = isExternal;

    this.controlDirection = getEdgePoint(point.edge, 0).normalise();
    this.controlMagnitude = controlMagnitude;

    this.pair = undefined;
    this.extenders = [];
    this.aligners = [];
  }

  @observable depthOverlap = 0;
  @observable depthCurvature = 0;
  @observable angleOverlap = 0;
  @observable angleCurvature = 0;

  @computed
  get layoutPos() {
    return {
      x: this.curve.hexagon.layoutPos.x + this.pos.x,
      y: this.curve.hexagon.layoutPos.y + this.pos.y,
      z: this.posZ,
    };
  }

  @computed
  get layoutControlPos() {
    return {
      x: this.curve.hexagon.layoutPos.x + this.controlPos.x,
      y: this.curve.hexagon.layoutPos.y + this.controlPos.y,
      z: this.controlPos.z,
    };
  }

  @computed
  get posZ() {
    return (
      this.store.settings.depthOverlapScalar * this.depthOverlap +
      this.store.settings.depthCurvatureScalar * this.depthCurvature
    );
  }

  @computed
  get controlPos() {
    if (this.angleOverlap === undefined && this.angleCurvature === undefined)
      return this.x;

    const averageAngle =
      this.store.settings.depthOverlapScalar * this.angleOverlap +
      this.store.settings.depthCurvatureScalar * this.angleCurvature;
    const depth = Math.sin(averageAngle) * this.controlMagnitude;
    const length = Math.cos(averageAngle) * this.controlMagnitude;

    let controlPos = this.pos.minusNew(
      this.controlDirection.multiplyNew(length)
    );
    controlPos.z = this.posZ + depth;

    return controlPos;
  }

  setDepthOverlap(depth) {
    this.depthOverlap = depth;
  }

  setDepthCurvature(depth) {
    this.depthCurvature = depth;
  }

  getOppositeCap() {
    if (this.curve.start == this) return this.curve.end;
    return this.curve.start;
  }

  getOverlapAngleToOppositeCap() {
    return Math.atan(
      (this.getOppositeCap().depthOverlap - this.depthOverlap) /
        this.curve.estLength
    );
  }

  getCurvatureAngleToOppositeCap() {
    return Math.atan(
      (this.getOppositeCap().depthCurvature - this.depthCurvature) /
        this.curve.estLength
    );
  }

  angleControlPos(angleOverlap, angleCurvature) {
    this.angleOverlap = angleOverlap;
    this.angleCurvature = angleCurvature;
  }
}

export default CurveCap;
