import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { mediaUrl } from 'utils/mediaUtils';

import styles from './VideoBanner.sass';

import HeaderInvertBanner from 'components/common/banners/HeaderInvertBanner';

const videoData = [
  {
    width: 3840,
    height: 1280,
    aspectRatio: 1 / 3,
    version: '1519124628',
    public_id: 'Cut_Skinny_csmwpg',
  },
  {
    width: 2160,
    height: 2160,
    aspectRatio: 1,
    version: '1519124629',
    public_id: 'Cut_Square_polo1m',
  },
];

@inject('UIStore')
@observer
class VideoBanner extends Component {
  wrapperElement = undefined;
  pixelRatio = window.devicePixelRatio || 1;
  @observable videoChoice = 0;
  @observable videoWidth = 0;
  @observable videoHeight = 0;
  @observable videoSourceWidth = 0;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { UIStore } = this.props;

    // chose new video + size when window is resized
    // but debounce by a second so it only happens with final size
    this.rechooseReaction = reaction(
      () => UIStore.windowWidth,
      () => this.chooseVideo,
      {
        delay: 1000,
      }
    );

    // instantly recalc the client video size though
    this.resizeReaction = reaction(() => UIStore.windowWidth, this.sizeVideo);
  }

  componentWillUnmount() {
    // dispose of reactions
    this.rechooseReaction();
    this.resizeReaction();
  }

  onVideoMount = (element) => {
    if (!element) return;

    this.wrapperElement = element;

    this.chooseVideo();
  };

  chooseVideo = () => {
    const wrapperWidth = this.wrapperElement.clientWidth;
    const wrapperHeight = this.wrapperElement.clientHeight;

    // round the source to the nearest 100 so we transform less often
    this.videoSourceWidth =
      Math.ceil((wrapperHeight * this.pixelRatio) / 100) * 100;

    // find the right video for the job
    const wrapperAR = wrapperHeight / wrapperWidth;
    let choice = 0;
    let difference = undefined;
    videoData.forEach((data, i) => {
      const thisDiff = Math.abs(wrapperAR - data.aspectRatio);
      if (!difference || thisDiff < difference) {
        difference = thisDiff;
        choice = i;
      }
    });
    this.videoChoice = choice;

    this.sizeVideo({ wrapperWidth, wrapperHeight });
  };

  sizeVideo = ({ wrapperWidth, wrapperHeight }) => {
    if (!this.wrapperElement) return;

    wrapperWidth = wrapperWidth || this.wrapperElement.clientWidth;
    wrapperHeight = wrapperHeight || this.wrapperElement.clientHeight;
    const wrapperRatio = wrapperHeight / wrapperWidth;
    const videoRatio = videoData[this.videoChoice].aspectRatio;

    const moreHorizontal = wrapperRatio > videoRatio;

    if (moreHorizontal) {
      this.videoHeight = wrapperHeight;
      this.videoWidth = wrapperHeight / videoRatio;
    } else {
      this.videoWidth = wrapperWidth;
      this.videoHeight = wrapperWidth * videoRatio;
    }
  };

  render() {
    const { children } = this.props;

    return (
      <HeaderInvertBanner>
        <section className={styles.wrapper} ref={this.onVideoMount}>
          <video
            className={styles.video}
            src={mediaUrl(
              videoData[this.videoChoice],
              `c_scale,w_${this.videoSourceWidth}`,
              'mp4'
            )}
            loop
            autoPlay
            muted
            playsInline
            width={this.videoWidth}
            height={this.videoHeight}
          />

          {children}
        </section>
      </HeaderInvertBanner>
    );
  }
}

VideoBanner.propTypes = {
  UIStore: PropTypes.object,
  children: PropTypes.node,
};

export default VideoBanner;
