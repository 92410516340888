import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { formatDimensions } from 'utils/settingsUtils';

import styles from './Product.sass';

import PanelWrapper from 'components/common/PanelWrapper';
import ProductInfo from './ProductInfo';
import FilterNav from '../FilterNav';
import MaterialPicker from 'components/common/MaterialPicker';
import Loader from 'components/common/Loader';
import Demo from 'components/common/Demo';
import PricingWidget from 'components/common/Demo/PricingWidget';
import Scale from 'components/common/Demo/Scale';

@inject('UIStore', 'SystemStore', 'DesignStore', 'GTMTracking')
@observer
class Product extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { match, UIStore } = this.props;
    UIStore.scrollToTop();
    this.loadDesignBySlug(match.params.productSlug);
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.match.params.productSlug != nextProps.match.params.productSlug
    ) {
      this.props.SystemStore.ui.startDemoTransition();
      setTimeout(() => {
        this.props.SystemStore.ui.endDemoTransition();
        this.loadDesignBySlug(nextProps.match.params.productSlug);
      }, 300);
    }
    this.props = nextProps;

    // force rerender
    this.forceUpdate();
  };

  componentWillUnmount() {
    this.props.DesignStore.designUnloaded();
  }

  loadDesignBySlug = (slug) => {
    const store = this.props.SystemStore;
    const { DesignStore, GTMTracking } = this.props;

    DesignStore.getDesignBySlug(slug).then((design) => {
      DesignStore.setLoadedDesign(design);

      const importColumns = design.canvas.data.length;
      const importRows = design.canvas.data[0].length;

      if (!store.hexagonsInitialised) {
        store.initialiseHexagons();
      } else if (
        store.columns < importColumns ||
        store.rows < importRows ||
        store.hexagons.length === 0
      ) {
        store.initialiseHexagons(importRows, importColumns);
      }

      // don't import material
      delete design.settings.material;

      store.importDesign(design);

      // risky but effective
      this.forceUpdate();

      // add product view to gtm
      GTMTracking.trackEvent('ecommerce', {
        ecommerce: {
          detail: {
            products: [
              {
                name: design.name,
                id: design._id,
                price: design.prices[store.settings.material].toString(),
                category: design.productType,
                variant: store.settings.material,
              },
            ],
          },
        },
      });

      // window.fbq('track', 'ViewContent', {
      //   content_name: '{{Detail Product 1 Name}}',
      //   content_category: '{{Detail Product 1 Category}}',
      //   content_type: 'product',
      //   value: parseFloat('{{ Detail Product 1 Price }}'),
      //   currency: 'GBP',
      //   content_ids: ['{{Detail Product 1 ID}}-{{Detail Product 1 Variant}}'],
      // });
    });
  };

  render() {
    const { UIStore, SystemStore, DesignStore } = this.props;
    const design = DesignStore.loadedDesign;
    const store = SystemStore;

    const pageTitle =
      design && design.name ? `${design.name} | Hexatope Shop` : 'Hexatope';

    const demoWrapperClasses = classNames({
      [styles.demoWrapper]: true,
      [styles.demoWrapperHidden]: store.ui.demoIsTransitioning,
    });
    const scaleClasses = classNames({
      [styles.scale]: true,
      [styles.scaleHidden]: store.ui.demoIsTransitioning,
    });

    return (
      <div>
        {!UIStore.onMobile &&
          !UIStore.onTablet && (
            <div className={styles.filterNav}>
              <FilterNav />
            </div>
          )}

        <PanelWrapper
          hideFooter={UIStore.onMobile}
          className={styles.panelWrapper}
        >
          <ProductInfo />

          <div className={styles.wrapper}>
            <figure className={demoWrapperClasses}>
              <Demo />
            </figure>
            {store.ui.demoIsTransitioning && <Loader />}

            {design && (
              <Helmet>
                <title>{pageTitle}</title>
                <meta property={'og:type'} content={'product'} />
              </Helmet>
            )}

            {design && (
              <aside className={styles.productInfo}>
                <div className={scaleClasses}>
                  <Scale />
                </div>

                {UIStore.onMobile && (
                  <section className={styles.productMeta}>
                    <h2 className={styles.productName}>{design.name}</h2>

                    <div className={styles.materialPicker}>
                      <MaterialPicker direction={'down'} />
                    </div>

                    <dl className={styles.metaList}>
                      <dt className={styles.metaListLabel}>Chain</dt>
                      <dd className={styles.metaListValue}>
                        18&Prime; / 45cm snake
                      </dd>
                      <dt className={styles.metaListLabel}>Size</dt>
                      <dd className={styles.metaListValue}>
                        {design.dimensions && (
                          <span>{formatDimensions(design.dimensions)}</span>
                        )}
                      </dd>
                    </dl>
                  </section>
                )}

                {UIStore.onMobile && (
                  <div className={styles.pricing}>
                    <PricingWidget
                      design={design}
                      price={design.prices[store.settings.material]}
                    />
                  </div>
                )}
              </aside>
            )}
          </div>
        </PanelWrapper>
      </div>
    );
  }
}

Product.propTypes = {
  match: PropTypes.object,
  UIStore: PropTypes.object,
  DesignStore: PropTypes.object,
  SystemStore: PropTypes.object,
  GTMTracking: PropTypes.object,
};

export default Product;
