import { observable, action } from 'mobx';
import { statefulApiCall, apiGet, apiPost, apiDelete } from 'utils/httpUtils';
import STATES from 'constants/storeStates';

class CollectionStore {
  @observable state = STATES.READY; // ready / pending / error

  @action
  async getCollection(collectionSlug) {
    return await statefulApiCall(this, () =>
      apiGet(`collection/${collectionSlug}`)
    );
  }

  @action
  async addDesignToCollection(collectionSlug, id) {
    return await statefulApiCall(this, () =>
      apiPost(`collection/${collectionSlug}/${id}`)
    );
  }

  @action
  async removeDesignFromCollection(collectionSlug, id) {
    return await statefulApiCall(this, () =>
      apiDelete(`collection/${collectionSlug}/${id}`)
    );
  }
}

export default new CollectionStore();
