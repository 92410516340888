import { observable, action } from 'mobx';

const HIDE_TIMEOUT = 5000;
const TRANSITION_DURATION = 500;

class NotificationStore {
  @observable message;
  @observable messagePositive = true; // backgroundColor
  @observable messageVisible;
  hideTimer;

  @action
  flash = (message, positive = true) => {
    this.cancelHideTimer();

    this.message = message;
    this.messageVisible = true;
    this.messagePositive = positive;

    this.initialiseHideTimer();
  };

  cancelHideTimer = () => {
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
      this.hideTimer = undefined;
    }
  };

  initialiseHideTimer = () => {
    this.hideTimer = setTimeout(() => {
      this.closeMessage();
    }, HIDE_TIMEOUT);
  };

  closeMessage = () => {
    this.messageVisible = false;
    setTimeout(() => {
      this.message = '';
      this.cancelHideTimer();
    }, TRANSITION_DURATION);
  };
}

export default new NotificationStore();
