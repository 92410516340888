import { MATERIAL_OPTIONS } from 'constants/systemOptions';

export const getMaterialAbbr = (material) => {
  const opt = MATERIAL_OPTIONS.find((options) => options.value == material);
  return opt ? opt.abbr : undefined;
};

export const getMaterialLabel = (material) => {
  const opt = MATERIAL_OPTIONS.find((options) => options.value == material);
  return opt ? opt.label : undefined;
};

export const getRenderMaterial = (material) => {
  const opt = MATERIAL_OPTIONS.find((options) => options.value == material);
  return opt ? opt.renderMaterial : undefined;
};

export const formatDimensions = ({ width, height }) =>
  `${width}mm × ${height}mm`;
