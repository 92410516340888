import { observable, action, computed } from 'mobx';
import DesignStore from './DesignStore';
import CollectionStore from './CollectionStore';
import { MATERIALS } from 'constants/systemOptions';

class ShopStore {
  shopCollectionSlug = 'ss-2018';

  @observable designIds = [];
  @observable designsHaveLoaded = false;
  @observable materialFilter = undefined;
  @observable sortOrder = undefined;

  @action
  setMaterialFilter = (material) => {
    this.materialFilter = material;
  };

  @action
  setSortOrder = (order) => {
    this.sortOrder = order;
  };

  @computed
  get sortedDesigns() {
    // shallow copy array to force a rerender
    let designs = DesignStore.shopDesigns.slice(0);
    // if no material filter is selected sort by sterling silver because it's most likely to be the lowest value
    const materialComparison = this.materialFilter || MATERIALS.STERLING;

    switch (this.sortOrder) {
      case 'name':
        designs = designs.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        break;

      case 'priceDescending':
        designs = designs.sort((a, b) => {
          const compare =
            b.prices[materialComparison] - a.prices[materialComparison];
          if (compare !== 0) return compare;
          // if they're the same price sort by name
          if (a.name < b.name) return -1;
          return 1;
        });
        break;

      case 'priceAscending':
        designs = designs.sort((a, b) => {
          const compare =
            a.prices[materialComparison] - b.prices[materialComparison];
          if (compare !== 0) return compare;
          // if they're the same price sort by name
          if (a.name < b.name) return -1;
          return 1;
        });
        break;
    }

    return designs;
  }

  // used in DesignStore
  @action
  setDesignIds = (ids) => {
    this.designIds = ids;
  };

  @action
  async loadShopDesigns() {
    // don't reload if they're already there
    if (this.designsHaveLoaded) return;

    return await this.getShopDesigns().then((collection) => {
      if (!collection?.designs) return;

      this.setDesignIds(collection.designs.map((design) => design._id));
      this.designsHaveLoaded = true;
      const scrabbledDesigns = collection.designs.sort(
        () => Math.random() - 0.5
      );
      DesignStore.setLoadedDesigns(scrabbledDesigns);
    });
  }

  @action
  async getShopDesigns() {
    return await CollectionStore.getCollection(this.shopCollectionSlug);
  }

  @action
  async addDesignToShop(id) {
    return await CollectionStore.addDesignToCollection(
      this.shopCollectionSlug,
      id
    ).then((result) => {
      // add to id list
      if (this.designIds.indexOf(id) === -1) this.designIds.push(id);
      return result;
    });
  }

  @action
  async removeDesignFromShop(id) {
    return await CollectionStore.removeDesignFromCollection(
      this.shopCollectionSlug,
      id
    ).then((result) => {
      // remove from id list
      const deletedIndex = this.designIds.indexOf(id);
      if (deletedIndex >= 0) this.designIds.splice(deletedIndex, 1);
      return result;
    });
  }

  @action designIsInShop = (designId) => this.designIds.indexOf(designId) >= 0;
}

export default new ShopStore();
