import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Loader from 'components/common/Loader';

@observer
class LoadingButton extends Component {
  @observable buttonDimensions = undefined;
  buttonElement = undefined;

  constructor(props) {
    super(props);
  }

  onRef = (element) => {
    this.buttonElement = element;
    this.calculateDimensions();
  };

  calculateDimensions = () => {
    if (this.buttonElement)
      this.buttonDimensions = this.buttonElement.getBoundingClientRect();
  };

  onButtonClicked = (e) => {
    if (!this.props.isLoading) {
      // calculate button dimensions for when loader is showing
      this.calculateDimensions();
    }

    if (this.props.onClick) this.props.onClick(e);
  };

  render() {
    const { isLoading, children, ...props } = this.props;

    return (
      <button
        onClick={this.onButtonClicked}
        ref={this.onRef}
        style={
          isLoading
            ? {
              width: this.buttonDimensions.width,
              height: this.buttonDimensions.height,
            }
            : null
        }
        {...props}
      >
        {isLoading ? <Loader small={true} /> : <span>{children}</span>}
      </button>
    );
  }
}

LoadingButton.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default LoadingButton;
