import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { shuffle } from 'utils/arrayUtils';

import ProductGrid from 'components/common/ProductGrid';

import styles from './CollectionBanner.sass';

@inject('DesignStore', 'ShopStore', 'UIStore')
@observer
class CollectionBanner extends Component {
  @observable productOrder = undefined;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { ShopStore } = this.props;
    if (!ShopStore.designsHaveLoaded) {
      ShopStore.loadShopDesigns().then(this.scrabbleIndexes);
    } else {
      this.scrabbleIndexes();
    }
  }

  scrabbleIndexes = () => {
    const length = this.props.DesignStore.shopDesigns.length;
    let numberArray = Array.from({ length: length }, (v, k) => k);
    this.productOrder = shuffle(numberArray);
  };

  render() {
    const { DesignStore, UIStore } = this.props;
    const { shopDesigns } = DesignStore;

    if (!shopDesigns || !shopDesigns.length || !this.productOrder) return null;

    const productCount = UIStore.onMobile ? 4 : 6;
    const columns = UIStore.onMobile ? 2 : 3;
    const designs = this.productOrder
      .slice(0, productCount)
      .map((index) => shopDesigns[index]);

    return (
      <div className={styles.wrapper}>
        {!!designs.length &&
          this.productOrder && (
            <ProductGrid
              designs={designs}
              columns={columns}
              marginless={true}
            />
          )}
      </div>
    );
  }
}

CollectionBanner.propTypes = {
  DesignStore: PropTypes.object,
  ShopStore: PropTypes.object,
  UIStore: PropTypes.object,
};

export default CollectionBanner;
