import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import classNames from 'classnames';

import styles from './Header.sass';

import Logo from 'components/common/icons/Logo';
// import CartIcon from 'components/common/icons/CartIcon';
import HamburgerMenu from './HamburgerMenu';
import FlashMessage from './FlashMessage';

@inject('UIStore', 'CartStore')
@observer
class Header extends Component {
  @observable shouldAnimate = false;

  constructor(props) {
    super(props);
  }

  render() {
    const { children, UIStore } = this.props;
    const { headerInverted, headerLinksVisible, scrollY } = UIStore;

    const headerClasses = classNames({
      [styles.header]: true,
      [styles.headerInverted]: headerInverted,
    });
    const linksClasses = classNames({
      [styles.linkWrapper]: true,
      [styles.linkWrapperVisible]:
        headerLinksVisible && (scrollY < 10 || headerInverted),
    });
    // const cartIconInnerClasses = classNames({
    //   [styles.cartIconInner]: true,
    //   [styles.cartIconInnerEmpty]: CartStore.isEmpty,
    // });

    return (
      <div className={headerClasses}>

        <div className={styles.banner}>
          <span>The shop is closed indefinitely while I pursue other projects</span>
        </div>

        <header className={styles.navbar}>
          <nav className={styles.logoWrapper}>
            <Link to={'/'} className={styles.logoLink}>
              <Logo className={styles.logo} />
            </Link>

            <div className={linksClasses}>
              <Link to={'/app'} className={styles.link}>
                App
              </Link>
              <Link to={'/shop'} className={styles.link}>
                Shop
              </Link>
              <Link to={'/about'} className={styles.link}>
                About
              </Link>
            </div>
          </nav>
        </header>

        <FlashMessage />

        <div className={styles.asideMenu}>
          <HamburgerMenu />
          {/* <Link to={'/cart'}>
            <CartIcon
              className={styles.cartIcon}
              innerClassName={cartIconInnerClasses}
            />
          </Link> */}
        </div>

        {children}
      </div>
    );
  }
}

Header.propTypes = {
  UIStore: PropTypes.object,
  CartStore: PropTypes.object,
  children: PropTypes.node,
};

export default Header;
