import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

@inject('UserStore')
@observer
class IsAdmin extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { UserStore, children, redirectTo } = this.props;

    return UserStore.userIsAdmin ? (
      <div>{children}</div>
    ) : redirectTo ? (
      <Redirect to={redirectTo} />
    ) : null;
  }
}

IsAdmin.propTypes = {
  UserStore: PropTypes.object,
  children: PropTypes.node,
  redirectTo: PropTypes.string,
};

export default IsAdmin;
