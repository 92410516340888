import { observable, action, computed } from 'mobx';

class UIStore {
  @observable windowWidth = 0;
  @observable windowHeight = 0;
  @observable scrollY = 0;
  @observable mouseX = 0;
  @observable mouseY = 0;
  @observable onTouchDevice = false;

  @computed
  get onMobile() {
    return this.windowWidth < 700;
  }

  @computed
  get onTablet() {
    return this.windowWidth < 1000;
  }

  constructor() {
    if (typeof window !== 'undefined') {
      this.createListeners();
      this.updateDimensions();
    }
  }

  createListeners() {
    window.addEventListener('resize', this.onWindowResized);
    window.addEventListener('scroll', this.onWindowScrolled);
    window.addEventListener('mousemove', this.onMouseMoved);
    window.addEventListener('touchmove', this.onTouchMoved, { passive: false });
  }

  @action
  updateDimensions = () => {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = undefined;
    }
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  };

  @action
  updateScrollPosition = () => {
    this.scrollY = window.pageYOffset;
  };

  @action
  updateMousePosition = (e) => {
    this.mouseX = e.pageX;
    this.mouseY = e.pageY;
  };

  onWindowResized = () => this.updateDimensions();
  onWindowScrolled = () => this.updateScrollPosition();
  onMouseMoved = (e) => this.updateMousePosition(e);

  @action // this is also used inside of CanvasWrapper for touchStart
  onTouchMoved = (e) => {
    this.onTouchDevice = true; // irreversable because clicks on touch devices cause a mousemove event :(
    this.updateMousePosition(e.touches[0]);
  };

  @observable headerInverted = false;
  @observable headerLinksVisible = true;

  @action
  invertHeader = () => {
    this.headerInverted = true;
  };

  @action
  uninvertHeader = () => {
    this.headerInverted = false;
  };

  @action
  showHeaderLinks = () => {
    this.headerLinksVisible = true;
  };

  @action
  hideHeaderLinks = () => {
    this.headerLinksVisible = false;
  };

  @observable footerVisible = true;

  @action
  hideFooter = () => {
    this.footerVisible = false;
  };

  @action
  showFooter = () => {
    this.footerVisible = true;
  };

  @action
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: window.scrollX,
    });
  };
}

export default new UIStore();
