import { getCurvesData, getTotalLength } from './curveUtils';
import { estimatePrice } from 'utils/pricing';

export const getDesignPrice = (store, material, curvesData) =>
  Math.round(getPriceFromCurves(store, material, curvesData, 3.5));

export const getMetalVolume = (store, curvesData) => {
  const curves = curvesData || getCurvesData(store);
  return getTotalLength(curves) / store.config.wireLengthPerCm3;
};

const getPricingDataFromStore = (store, curvesData) => ({
  metalVolume: getMetalVolume(store, curvesData),
  pieceArea: (store.ui.pieceDimensions.x * store.ui.pieceDimensions.y) / 100, // in cm2 instead of mm2
});

const getPriceFromCurves = (store, material, curvesData, markup = 1) => {
  material = material || store.settings.material;
  return estimatePrice(
    material,
    markup,
    getPricingDataFromStore(store, curvesData)
  );
};
