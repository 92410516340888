import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Typekit from 'react-typekit';
import TagManager from 'react-gtm-module';
import { inject, observer } from 'mobx-react';
import { GOOGLE_TAG_MANAGER_ID, TYPEKIT_KIT_ID } from 'constants/external';

import asyncComponent from 'components/common/routing/AsyncComponent';
import DiscountWrapper from 'components/common/routing/DiscountWrapper';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';

const Admin = asyncComponent(() =>
  import(/* webpackChunkName: "Admin" */ 'components/Admin')
);
import Home from 'components/Home';
import System from 'components/System';
import ShopList from 'components/Shop/List';
import ShopProduct from 'components/Shop/Product';
import Cart from 'components/Cart';
import Checkout from 'components/Checkout';
import Terms from 'components/pages/Terms';
import PrivacyPolicy from 'components/pages/PrivacyPolicy';
import Delivery from 'components/pages/Delivery';
import Returns from 'components/pages/Returns';
import About from 'components/pages/About';
import Contact from 'components/pages/Contact';

import styles from 'styles/application.sass';

const discountCodeUrls = {
  hannah: 'HANNAH',
  'affiliate-gift': 'AFFILIATEGIFT',
  'kickstarter-gift': 'KICKSTARTERGIFT',
};

@inject('GTMTracking')
@observer
class App extends Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
      this.props.GTMTracking.initialize(TagManager);
    }
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <div className={styles.pageWrapper}>
            <Header />
            <Switch>
              <Route path={'/'} exact component={Home} />
              <Route path={'/admin'} component={Admin} />

              <Route path={'/app'} exact component={System} />
              <Route path={'/app/:designSlug'} exact component={System} />

              <Route path={'/shop'} exact component={ShopList} />
              <Route
                path={'/shop/:productType/:productSlug'}
                exact
                component={ShopProduct}
              />

              <Route path={'/cart'} exact component={Cart} />
              <Route path={'/checkout'} exact component={Checkout} />
              <Route path={'/about'} exact component={About} />
              <Route path={'/contact'} exact component={Contact} />
              <Route path={'/terms'} exact component={Terms} />
              <Route path={'/privacy-policy'} exact component={PrivacyPolicy} />
              <Route path={'/delivery'} exact component={Delivery} />
              <Route path={'/returns'} exact component={Returns} />

              {Object.keys(discountCodeUrls).map((slug, i) => (
                <Route
                  path={`/${slug}`}
                  key={i}
                  exact
                  render={() => (
                    <DiscountWrapper
                      code={discountCodeUrls[slug]}
                      Component={Home}
                    />
                  )}
                />
              ))}

              <Route
                path={'/collection'}
                exact
                render={() => <Redirect to={'/shop'} />}
              />
              <Route
                path={'/collection/:slug'}
                exact
                render={({ match }) => (
                  <Redirect to={`/shop/pendants/${match.params.slug}`} />
                )}
              />
            </Switch>
            <Footer />
          </div>
        </BrowserRouter>
        <Typekit kitId={TYPEKIT_KIT_ID} />
      </div>
    );
  }
}

App.propTypes = {
  GTMTracking: PropTypes.object,
};

export default App;
