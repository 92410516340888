import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  downloadPNG,
  downloadSTL,
  downloadGIF,
  exportImages,
} from 'system/utils/assetUtils';
// import { exportCanvasPreviewData } from '../utils/exportUtils';
import STATES from 'constants/storeStates';

import styles from './AdminPanel.sass';

@inject('DesignStore', 'SystemStore')
@observer
class AdminPanel extends Component {
  @observable designName;
  @observable previews = [];

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener('paste', this.onPasted);
    // window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this.onPasted);
    // window.removeEventListener('keydown', this.onKeyDown);
  }

  onPasted = (e) => {
    const json = JSON.parse(e.clipboardData.getData('Text'));
    if (!json || !json.canvas) return;

    this.props.SystemStore.importDesign(json);
  };

  // only used when exporting keyframes
  // onKeyDown = (e) => {
  //   // space bar
  //   if (e.keyCode === 32) {
  //     this.previews.push(exportCanvasPreviewData(this.props.store));
  //   }

  //   // enter key
  //   if (e.keyCode === 13) {
  //     console.log(JSON.stringify(this.previews).replace(/"/g, '\''));
  //   }
  // }

  onSprueButtonClicked = () => {
    if (this.props.SystemStore.ui.isSpruing) {
      this.props.SystemStore.demo.removeSprues();
    } else {
      this.props.SystemStore.demo.initialiseSprues();
    }
  };

  onDownloadJSONButtonClicked = (withSettings) => {
    this.props.SystemStore.downloadJSON(withSettings);
  };

  onSVGButtonClicked = () => {
    this.props.SystemStore.canvas.downloadSVG();
  };

  onPNGButtonClicked = () => {
    downloadPNG(this.props.SystemStore.demo);
  };

  onGIFButtonClicked = () => {
    downloadGIF(this.props.SystemStore.demo);
  };

  onSTLButtonClicked = () => {
    downloadSTL(this.props.SystemStore.demo);
  };

  onSaveButtonClicked = () => {
    this.props.DesignStore.saveDesign(
      this.props.SystemStore,
      exportImages(this.props.SystemStore.demo, false),
      this.designName
    );
  };

  onUpdateButtonClicked = () => {
    this.props.DesignStore.updateDesign(
      this.props.SystemStore,
      exportImages(this.props.SystemStore.demo, false),
      this.designName
    );
  };

  onDesignNameInputChanged = (e) => {
    this.designName = e.target.value;
  };

  render() {
    // { this.props.DesignStore.loadedDesign &&
    //   this.props.DesignStore.loadedDesign.slug !== this.props.designSlug &&
    //   <Redirect to={`/app/${this.props.DesignStore.loadedDesign.slug}`} />
    // }
    return (
      <div className={styles.wrapper}>
        <section className={styles.panel} data-title={'Exports'} tabIndex={-1}>
          <div className={styles.buttonGroup}>
            <button
              className={styles.button}
              onClick={this.onSprueButtonClicked}
            >
              Sprue
            </button>
            <button
              className={styles.button}
              onClick={() => this.onDownloadJSONButtonClicked(true)}
            >
              Export Design JSON
            </button>
            <button
              className={styles.button}
              onClick={() => this.onDownloadJSONButtonClicked(false)}
            >
              Export Canvas JSON
            </button>
            <button className={styles.button} onClick={this.onSVGButtonClicked}>
              Download SVG
            </button>
            <button className={styles.button} onClick={this.onPNGButtonClicked}>
              Download PNG
            </button>
            <button className={styles.button} onClick={this.onGIFButtonClicked}>
              Download GIF
            </button>
            <button className={styles.button} onClick={this.onSTLButtonClicked}>
              Download STL
            </button>
          </div>
        </section>
        <section className={styles.panel} data-title={'Saving'} tabIndex={-1}>
          {this.props.DesignStore.loadedDesign ? (
            <div className={styles.buttonGroup}>
              <input
                type={'text'}
                onChange={this.onDesignNameInputChanged}
                placeholder={
                  this.props.DesignStore.loadedDesign.name
                    ? `Rename ${this.props.DesignStore.loadedDesign.name}`
                    : 'Name this design'
                }
                value={this.designName}
                className={styles.input}
              />
              <button
                className={styles.button}
                onClick={this.onUpdateButtonClicked}
              >
                Update
              </button>
              <button
                className={styles.button}
                onClick={this.onSaveButtonClicked}
              >
                Fork
              </button>
              {this.props.DesignStore.state !== STATES.READY && (
                <span>{this.props.DesignStore.state}</span>
              )}
            </div>
          ) : (
            <div className={styles.buttonGroup}>
              <input
                type={'text'}
                onChange={this.onDesignNameInputChanged}
                value={this.designName}
                className={styles.input}
                placeholder={'Name this design'}
              />
              <button
                className={styles.button}
                onClick={this.onSaveButtonClicked}
              >
                Save Design ({this.props.DesignStore.state})
              </button>
            </div>
          )}
        </section>
      </div>
    );
  }
}

AdminPanel.propTypes = {
  designSlug: PropTypes.string,
  DesignStore: PropTypes.object,
  SystemStore: PropTypes.object,
};

export default AdminPanel;
