import React from 'react';

import styles from './Checkout.sass';

import GenericHeader from 'components/common/banners/GenericHeader';
import StripeProvider from './StripeProvider';
import CheckoutForm from './CheckoutForm';

const Checkout = () => (
  <div>
    <GenericHeader title={'Checkout'} />

    <div className={styles.wrapperWithSidebar}>
      <StripeProvider>
        <CheckoutForm />
      </StripeProvider>
    </div>
  </div>
);

export default Checkout;
