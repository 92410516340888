import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import CountriesList from 'country-list';
import { formatAsGBP } from 'utils/currencyUtils';

import styles from './OrderOptions.sass';

import InputElement from 'components/common/forms/InputElement';

const countryData = new CountriesList().getData();

@inject('CartStore')
@observer
class OrderOptions extends Component {
  constructor(props) {
    super(props);
  }

  onShippingCountrySelected = (e) => {
    this.props.CartStore.setShippingCountry(e.target.value);
  };

  onDiscountCodeChanged = (e) => {
    this.props.CartStore.updateDiscountCode(e.target.value);
  };

  onDiscountFormSubmitted = (e) => {
    e.preventDefault();
    this.props.CartStore.testDiscountCode();
  };

  render() {
    const { CartStore } = this.props;
    const { shippingCountry, discount } = CartStore;

    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <form
            className={styles.discountDetails}
            onSubmit={this.onDiscountFormSubmitted}
          >
            <InputElement
              name={'discount'}
              placeholder={'Discount Code'}
              inline={true}
              value={discount.code}
              disabled={!!discount.id}
              className={styles.discountCodeField}
              onChange={this.onDiscountCodeChanged}
              darkBorder={true}
            />
            {!discount.id && (
              <button className={styles.applyDiscountButton}>Apply</button>
            )}
          </form>
          {!!discount.id &&
            discount.percentageOff > 0 && (
              <div className={styles.discountPrice}>
                -{discount.percentageOff}%
              </div>
            )}
        </div>

        <div className={styles.row}>
          <div className={styles.totalDetails}>Subtotal</div>
          <div>
            {!!discount.id && (
              <span className={styles.totalPriceDiscountedFrom}>
                {formatAsGBP(CartStore.subtotalWithoutDiscount)}
              </span>
            )}
            {formatAsGBP(CartStore.subtotal)}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.shippingDetails}>
            <p className={styles.countrySelectLabel}>Shipping to</p>
            <InputElement
              type={'select'}
              name={'country'}
              value={shippingCountry}
              required={true}
              className={styles.countrySelect}
              onChange={this.onShippingCountrySelected}
              darkBackground={true}
            >
              {countryData.map((country, i) => (
                <option key={i} value={country.code}>
                  {country.name}
                </option>
              ))}
            </InputElement>
          </div>

          <div className={styles.shippingPrice}>
            {CartStore.shippingCost === 0
              ? 'FREE'
              : formatAsGBP(CartStore.shippingCost)}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.totalDetails}>Total</div>
          <div>{formatAsGBP(CartStore.totalCost)}</div>
        </div>
      </div>
    );
  }
}

OrderOptions.propTypes = {
  CartStore: PropTypes.object,
};

export default OrderOptions;
