import { observable, action, computed } from 'mobx';
import {
  statefulApiCall,
  apiPost,
  apiGet,
  apiDelete,
  apiPut,
} from 'utils/httpUtils';
import STATES from 'constants/storeStates';

class UserStore {
  @observable state = STATES.READY; // ready / pending / error
  @observable errorMessage = undefined;
  @observable user = undefined;

  constructor() {
    // attempt to login to current session
    this.getCurrentUser();
  }

  @computed
  get userIsLoggedIn() {
    return this.user && this.user.email;
  }

  @computed
  get userIsAdmin() {
    // we use this to show secret UI elements but actual permsission to make api changes
    // is done with express sessions and will fail if not properly authed
    return (
      this.user && this.user.roles && this.user.roles.indexOf('admin') >= 0
    );
  }

  @computed
  get userIsAffiliate() {
    return (
      this.user && this.user.roles && this.user.roles.indexOf('affiliate') >= 0
    );
  }

  @computed
  get userHasRoles() {
    return this.user && this.user.roles && !!this.user.roles.length;
  }

  @action
  async getCurrentUser() {
    return await statefulApiCall(this, () => apiGet('user/session')).then(
      (user) => {
        this.user = user;
      }
    );
  }

  @action
  async getAllUsers() {
    return await statefulApiCall(this, () => apiGet('user/all'));
  }

  @action
  async login(data) {
    return await statefulApiCall(this, () =>
      apiPost('user/session', data)
    ).then((user) => {
      this.user = user;
    });
  }

  @action
  async logout() {
    return await statefulApiCall(this, () => apiDelete('user/session')).then(
      (success) => {
        this.user = success ? undefined : this.user;
      }
    );
  }

  @action
  async signup(data) {
    return await statefulApiCall(this, () => apiPost('user/new', data)).then(
      (user) => {
        this.user = user;
      }
    );
  }

  @action
  async updateUser(user, fieldsToUpdate) {
    return await statefulApiCall(this, () =>
      apiPut(`user/${user._id}`, fieldsToUpdate)
    ).then((user) => {
      // update current user if that's the one we're updating
      if (this.user && user && this.user._id === user._id) this.user = user;
      return user;
    });
  }
}

export default new UserStore();
