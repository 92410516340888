import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { TWITTER_URL, INSTAGRAM_URL, FACEBOOK_URL } from 'constants/urls';

import styles from './Footer.sass';

import NewsletterForm from './NewsletterForm';
import TwitterIcon from 'components/common/icons/social/TwitterIcon';
import InstagramIcon from 'components/common/icons/social/InstagramIcon';
import FacebookIcon from 'components/common/icons/social/FacebookIcon';

@inject('UIStore')
@observer
class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.UIStore.footerVisible ? (
      <footer className={styles.wrapper}>
        <div className={styles.preFooter}>
          <p>
            Hexatope is on the up and up
            <br />
            Join our mailing list to hear about new products
            and&nbsp;other&nbsp;gems
          </p>
          <div className={styles.newsletterFormWrapper}>
            <NewsletterForm />
          </div>
        </div>
        <div className={styles.mainFooter}>
          <nav className={styles.linkWrapper}>
            <ul className={styles.primaryLinkList}>
              <li className={styles.linkItem}>
                <Link to={'/shop'} className={styles.link}>
                  Shop
                </Link>
              </li>
              <li className={styles.linkItem}>
                <Link to={'/app'} className={styles.link}>
                  App
                </Link>
              </li>
              <li className={styles.linkItem}>
                <Link to={'/about'} className={styles.link}>
                  About
                </Link>
              </li>
              <li className={styles.linkItem}>
                <Link to={'/contact'} className={styles.link}>
                  Contact
                </Link>
              </li>
            </ul>

            <ul className={styles.secondaryLinkList}>
              <li className={styles.linkItem}>
                <Link to={'/delivery'} className={styles.link}>
                  Delivery
                </Link>
              </li>
              <li className={styles.linkItem}>
                <Link to={'/returns'} className={styles.link}>
                  Returns
                </Link>
              </li>
              <li className={styles.linkItem}>
                <Link to={'/privacy-policy'} className={styles.link}>
                  Privacy Policy
                </Link>
              </li>
              <li className={styles.linkItem}>
                <Link to={'/terms'} className={styles.link}>
                  Terms of Service
                </Link>
              </li>
            </ul>

            <ul className={styles.socialLinkList}>
              <li className={styles.linkItem}>
                <a
                  href={TWITTER_URL}
                  className={styles.socialLink}
                  title={'Follow Hexatope on Twitter'}
                >
                  <TwitterIcon className={styles.socialLogo} />
                </a>
              </li>
              <li className={styles.linkItem}>
                <a
                  href={INSTAGRAM_URL}
                  className={styles.socialLink}
                  title={'Follow Hexatope on Instagram'}
                >
                  <InstagramIcon className={styles.socialLogo} />
                </a>
              </li>
              <li className={styles.linkItem}>
                <a
                  href={FACEBOOK_URL}
                  className={styles.socialLink}
                  title={'Follow Hexatope on Facebook'}
                >
                  <FacebookIcon className={styles.socialLogo} />
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.postFooter}>
          &copy; 2021 Hexatope. All rights reserved.
        </div>
      </footer>
    ) : null;
  }
}

Footer.propTypes = {
  UIStore: PropTypes.object,
};

export default Footer;
