import Hexagon from '../Hexagon';
import Vector2 from './Vector2';
import { wrap6 } from 'utils/numberUtils';
import config from 'system/config';

// Store functions
//=====================================

export const clearHexagons = (store) => {
  store.eachHexagon((hex) => {
    hex.clear();
  });
  store.ui.canvasShouldRender();
};

export const updateHexagons = (store) => {
  store.eachHexagon((hex) => {
    hex.update();
  });
};

export const initialiseHexagons = (store, rows, columns) => {
  let hexagons = store.hexagonsInitialised ? store.hexagons : [];

  // if rows/cols aren't spec'd we use the window dimesions
  if (!rows || !columns) {
    const { windowWidth, windowHeight } = store.globalUi;

    // determnine size by width if width is bigger
    if (windowWidth / 2 > windowHeight) {
      columns = Math.ceil(windowWidth / 2 / (store.config.hexRadius * 3));
      rows =
        Math.ceil(
          windowWidth / 2 / ((Math.sqrt(3) * store.config.hexRadius) / 2)
        ) + 1;
    }
    // otherwise determine it by height
    else {
      columns = Math.ceil(windowHeight / (store.config.hexRadius * 3));
      rows =
        Math.ceil(
          windowHeight / ((Math.sqrt(3) * store.config.hexRadius) / 2)
        ) + 1;
    }
    // make sure rows are odd for symetry's sake
    rows = rows % 2 ? rows : rows + 1;
  }

  // if there are no hexagons or we need to add rows or columns
  if (
    !store.hexagonsInitialised ||
    columns > store.columns ||
    rows > store.rows
  ) {
    // initialise 2D array of hexagons
    for (let x = 0; x < columns + 1; x++) {
      if (!hexagons[x]) hexagons.push([]);
      for (let y = 0; y < rows; y++) {
        // only if starting column doesn't exist
        if (!hexagons[x][y]) {
          // add half column to balance
          if (x < columns || y % 2 === 0) {
            hexagons[x][y] = new Hexagon(x, y, store);
          } else {
            hexagons[x][y] = undefined;
          }
        }
      }
    }
  }

  if (store.hexagonsInitialised && columns < store.columns) {
    // remove some columns
    hexagons.splice(columns + 1);
    hexagons[columns].forEach((hex, y) => {
      if (y % 2) hexagons[columns][y] = undefined;
    });
  }

  if (store.hexagonsInitialised && rows < store.rows) {
    // remove some rows
    hexagons.forEach((row) => {
      row.splice(rows);
    });
  }

  store.columns = columns;
  store.rows = rows;
  store.hexagons = hexagons;

  // neighbouring needs to be done after they're all initialised
  store.eachHexagon((hex, x, y) => {
    hex.initialiseNeighbours(x, y, hexagons);
  });

  store.hexagonsInitialised = true;
};

export const eachHexagon = ({ columns, rows, hexagons }, each) => {
  // neighbouring needs to be done after they're all initialised
  for (let x = 0; x < columns + 1; x++) {
    for (let y = 0; y < rows; y++) {
      if (hexagons[x][y]) each(hexagons[x][y], x, y);
    }
  }
};

// Helper functions
//=====================================

export const findMouseHexagon = (store) => {
  let relativeX = store.ui.canvasRelativeMousePos.x / config.hexRadius;
  let relativeY =
    (store.ui.canvasRelativeMousePos.y * ((Math.sqrt(3) * 2) / 3)) /
    config.hexRadius;

  // find column
  let column = Math.floor(relativeX / 3);
  if (
    relativeX % 3 < 0.5 &&
    Math.abs((relativeY % 2) - 1) > (relativeX % 3) * 2
  ) {
    column--;
  }

  // find row
  let inMiddleHex = false;
  let flippedY = Math.abs((relativeY % 2) - 1);
  let modX = relativeX % 3;
  if (modX < 0.5) {
    inMiddleHex = modX * 2 > flippedY;
  } else if (modX < 1.5) {
    inMiddleHex = true;
  } else if (modX < 2) {
    inMiddleHex = 4 - modX * 2 > flippedY;
  }
  let row = 2 * Math.floor(relativeY / 2);
  row = inMiddleHex ? row : relativeY % 2 < 1 ? row - 1 : row + 1;

  // return hexagon if it's there
  if (
    column >= 0 &&
    column < store.columns + 1 &&
    row >= 0 &&
    row < store.rows &&
    store.hexagons[column][row]
  ) {
    return store.hexagons[column][row];
  }
  return undefined;
};

export const drawFilledHexagon = (c, pixelPos, pixelRatio) => {
  const radius = pixelRatio * config.hexRadius;
  drawHexagon(c, pixelPos, pixelRatio, radius);
  c.fill();
};

export const drawOutlinedHexagon = (c, pixelPos, pixelRatio) => {
  const radius = pixelRatio * config.hexRadius;
  drawHexagon(c, pixelPos, pixelRatio, radius);
  c.stroke();
};

const drawHexagon = (c, pixelPos, pixelRatio, radius) => {
  // draws hexagon with the center pixelPos
  c.save();
  c.translate(pixelPos.x * pixelRatio, pixelPos.y * pixelRatio);
  c.beginPath();
  for (let i = 0; i < 6; i++) {
    c.lineTo(
      radius * Math.cos((i * Math.PI) / 3),
      radius * Math.sin((i * Math.PI) / 3)
    );
  }
  c.closePath();
  c.restore();
};

export const getEdgePoint = (i, offset) => {
  // return position of this edge of the hexagon
  // if (offset == 1) clockwise from middle edge
  // if (offset == 0) middle of edge
  // if (offset == -1) anti-clockwise from middle edge
  const halfHexHeight = Math.sqrt(3) / 2;
  let point = new Vector2(
    (offset * config.hexDoubleLineOffset) / 2,
    -halfHexHeight
  );
  point.rotate((i * Math.PI) / 3);
  point.edge = i;
  return point;
};

// following functions use a cheeky formula for estimating a circle arc with cubic beziers
// magnitude of control point as perpendicular to radius =
// (4 / 3) * Math.tan(Math.PI / 2n)
// where n is the amount of segments of the circle

export const getControlMagnitudeAdjacent = (offset) =>
  // the magnitude of the control point for 1/3rd of a circle, give or take offset
  (4 / 9) * Math.sqrt(3) * ((1 - offset * config.hexDoubleLineOffset) / 2);

export const getControlMagnitudeWide = (offset) =>
  // the magnitude of the control point for 1/6th of a circle, give or take offset
  ((8 - 4 * Math.sqrt(3)) / 3) *
  ((3 - offset * config.hexDoubleLineOffset) / 2);

export const rotateLayout = (layout, rotation) => {
  const rotatePairs = (pairs) =>
    (pairs || []).map((pair) => [
      wrap6(pair[0] + rotation),
      wrap6(pair[1] + rotation),
    ]);
  const rotateArray = (group) =>
    (group || []).map((edge) => wrap6(edge + rotation));

  // create a new object so you don't fuck up the actual layout
  return {
    pairs: rotatePairs(layout.pairs),
    pushUp: rotateArray(layout.pushUp),
    pushDown: rotateArray(layout.pushDown),
    forceUp: rotateArray(layout.forceUp),
    forceDown: rotateArray(layout.forceDown),
  };
};
