import { observable, computed, action } from 'mobx';
import { CAN_USE_LOCAL_STORAGE } from 'constants/featureAvailibility';

const HISTORY_CAP = 10;

class SettingsStore {
  @observable histories = [];
  @observable current = undefined;
  @observable futures = [];

  @computed
  get historyExists() {
    return !!this.histories.length;
  }

  @computed
  get futureExists() {
    return !!this.futures.length;
  }

  @action
  addToHistory = (store) => {
    // don't trim the fat on the export
    const newData = store.exportCanvasData(false);

    // ignore if it's the same data as the current history
    if (
      this.current &&
      JSON.stringify(this.current) === JSON.stringify(newData)
    )
      return;

    // clear futures so we don't have lingering branches
    this.futures = [];

    // move current to history array
    // remove last element if there are more than the cap
    if (this.current && this.histories.unshift(this.current) > HISTORY_CAP)
      this.histories.pop();

    // save in local storage for cross-session memory
    if (CAN_USE_LOCAL_STORAGE) {
      localStorage.setItem('design', JSON.stringify(newData));
    }

    this.current = newData;
  };

  @action
  undo = (store) => {
    if (!this.historyExists) return;

    // move first history to current and current to first future
    const newCurrent = this.histories.shift();
    this.futures.unshift(this.current);
    this.current = newCurrent;

    // import current
    store.importCanvasData(newCurrent);
  };

  @action
  redo = (store) => {
    if (!this.futureExists) return;

    // move first future to current and current to first history
    const newCurrent = this.futures.shift();
    this.histories.unshift(this.current);
    this.current = newCurrent;

    // import current
    store.importCanvasData(newCurrent);
  };
}

export default SettingsStore;
