import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Helmet } from 'react-helmet';

import styles from './Home.sass';

import ArrowLink from 'components/common/ArrowLink';
import CloudinaryImage from 'components/common/CloudinaryImage';
import VideoBanner from './VideoBanner';
import AppBanner from './AppBanner';
import CollectionBanner from './CollectionBanner';
import InstagramBanner from './InstagramBanner';

@inject('UIStore')
class Home extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.UIStore.scrollToTop();
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <Helmet>
          <title>Hexatope | Design your own unique jewellery</title>
        </Helmet>
        <VideoBanner />

        <div className={styles.sidebarWrapper}>
          <section className={styles.section}>
            <div className={styles.sectionText}>
              <h2 className={styles.sectionTitle}>The App</h2>
              <p className={styles.sectionDescription}>
                Hexatope is a web app that enables anyone to become a designer
                through intuitive interaction with a hexagonal&nbsp;grid
              </p>
              <p className={styles.sectionDescription}>
                We 3D print and cast your finished designs into precious metal,
                creating a completely unique piece of&nbsp;jewellery
              </p>
              <nav className={styles.sectionButtons}>
                <ArrowLink to={'/app'}>Explore the App</ArrowLink>
              </nav>
            </div>
            <AppBanner />
          </section>

          <section className={styles.section}>
            <div className={styles.sectionText}>
              <h2 className={styles.sectionTitle}>The Collection</h2>
              <p className={styles.sectionDescription}>
                The Collection explores the versatility of the Hexatope&nbsp;app
                with an eclectic range of pre-designed&nbsp;pendants
              </p>
              <p className={styles.sectionDescription}>
                Each piece can be purchased as is, or used as a jumping off
                point for your&nbsp;own creations
              </p>
              <nav className={styles.sectionButtons}>
                <ArrowLink to={'/shop'}>Go to the Shop</ArrowLink>
              </nav>
            </div>
            <CollectionBanner />
          </section>

          <section className={styles.lookbook}>
            <div className={styles.lookbookGrid}>
              <Link to={'/app/effie'} className={styles.lookbookImage1}>
                <CloudinaryImage
                  data={{
                    version: 1519674356,
                    public_id: 'marketing/plants14',
                    ext: 'jpg',
                  }}
                />
              </Link>
              <Link to={'/app/frances'} className={styles.lookbookImage2}>
                <CloudinaryImage
                  data={{
                    version: 1519674344,
                    public_id: 'marketing/plants17',
                    ext: 'jpg',
                  }}
                />
              </Link>
              <Link to={'/app/marion'} className={styles.lookbookImage3}>
                <CloudinaryImage
                  data={{
                    version: 1519674173,
                    public_id: 'marketing/plants15',
                    ext: 'jpg',
                  }}
                />
              </Link>
              <Link to={'/app/alma'} className={styles.lookbookImage4}>
                <CloudinaryImage
                  data={{
                    version: 1519674368,
                    public_id: 'marketing/plants16',
                    ext: 'jpg',
                  }}
                />
              </Link>
            </div>
          </section>

          <InstagramBanner />
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  UIStore: PropTypes.object,
};

export default Home;
