import * as THREE from 'three';
import { calculateEdgePoints } from './utils/threeUtils';

const CHAIN_DIAMETER = 1.1;
const JUMP_RING_DIAMETER = 3.3;
const JUMP_RING_WIRE_DIAMETER = 0.7;

class DemoChain {
  constructor(store, demo) {
    this.store = store;
    this.demo = demo;
    this.mesh = undefined;

    this.setup();
  }

  setup() {
    let group = new THREE.Group();

    const jumpRing = new THREE.TorusGeometry(
      JUMP_RING_DIAMETER / 2,
      JUMP_RING_WIRE_DIAMETER / 2,
      10,
      40
    );
    let jumpRingMesh = new THREE.Mesh(jumpRing, this.demo.getMaterial(false));
    jumpRingMesh.rotation.y = Math.PI / 2;

    // curve formula for the chain
    const curve = new THREE.Curve();
    curve.getPoint = (t) => {
      const d = 59;
      let r = 1;
      // bottom half
      if ((t + 0.25) % 1 > 0.5) {
        // peak at 0.5
        const ramp = 1 - Math.abs(t - 0.5) * 4;
        // positive part of sine
        const s = Math.sin((t - 0.25) * Math.PI * 2);
        // whole normalised 2pi sine
        const s2 = (Math.sin((t + 0.125) * Math.PI * 4) + 1) / 2;
        r = 1 - s ** 3 * 0.5 + ramp * s2 + s2 ** 3 * 0.2;
        // make the point at the bottom a bit less harsh
        if (t > 0.4975 && t < 0.5025) {
          r -= Math.sin(t * Math.PI * 200 + Math.PI / 2) * 0.005;
        }
      }
      r *= d;
      const x = r * Math.sin(t * 2 * Math.PI) * 0.8;
      const y = r * Math.cos(t * 2 * Math.PI) + d * 1.7 + 0.5;
      return new THREE.Vector3(x, y, 0);
    };
    const chain = new THREE.TubeBufferGeometry(
      curve,
      400,
      CHAIN_DIAMETER / 2,
      12,
      false
    );
    const chainMesh = new THREE.Mesh(chain, this.demo.getMaterial(true));

    group.add(jumpRingMesh);
    group.add(chainMesh);
    group.visible = false;

    this.mesh = group;
    this.demo.scene.add(this.mesh);
  }

  setVisibility(visibility) {
    this.mesh.visible = visibility;
  }

  hide() {
    this.setVisibility(false);
  }

  show() {
    this.setVisibility(true);
  }

  setPosition(y, z, visibility) {
    this.mesh.visible = visibility;
    this.mesh.position.y =
      y +
      (JUMP_RING_DIAMETER - JUMP_RING_WIRE_DIAMETER) / 2 -
      this.store.settings.mmWireDiameter;
    this.mesh.position.z = z;
  }

  updateMaterial() {
    // jump ring
    this.mesh.children[0].material = this.demo.getMaterial(false);
    // chain
    this.mesh.children[1].material = this.demo.getMaterial(true);
  }

  updatePosition(angle, raycast) {
    // if there's no mesh return
    // this could happen when importing settings
    if (!this.demo.mesh) return;

    let y = 0;
    let z = 0;
    let visibility = false;

    // get live position
    if (raycast) {
      // copied from calculateEdgePoints with finer detail
      // optimised for performance of new instances there
      // too hard to make common
      const RAY_DISTANCE_FROM_ORIGIN = 100;
      let raycaster = new THREE.Raycaster(startingPoint, direction);
      let startingPoint = new THREE.Vector3(
        Math.sin(angle) * RAY_DISTANCE_FROM_ORIGIN,
        Math.cos(angle) * RAY_DISTANCE_FROM_ORIGIN,
        0
      );
      const direction = new THREE.Vector3(
        -Math.sin(angle),
        -Math.cos(angle),
        0
      );
      let closestIntersection = undefined;
      for (let posZ = -3; posZ < 3; posZ += 0.1) {
        startingPoint.setZ(posZ);
        raycaster.set(startingPoint, direction);
        const intersection = raycaster.intersectObject(this.demo.mesh, true);
        if (
          intersection.length &&
          intersection[0].distance < RAY_DISTANCE_FROM_ORIGIN &&
          (!closestIntersection ||
            intersection[0].distance < closestIntersection.distance)
        ) {
          closestIntersection = intersection[0];
        }
      }
      if (closestIntersection) {
        visibility = true;
        y = RAY_DISTANCE_FROM_ORIGIN - closestIntersection.distance;
        z = closestIntersection.point.z;
      }
    }

    // use objectEdgePoints array
    else {
      if (!this.demo.objectEdgePoints || !this.demo.objectEdgePoints.length)
        calculateEdgePoints(this.demo);

      const edgePointIndex = Math.round((angle * 50) / Math.PI) % 100;
      const edgePoint = this.demo.objectEdgePoints[edgePointIndex];

      if (edgePoint) {
        visibility = true;
        y = edgePoint.distanceFromCenter;
        z = edgePoint.z;
      }
    }

    this.setPosition(y, z);
    this.setVisibility(
      this.store.settings.hangingPointAngle !== undefined ? visibility : false
    );
  }
}

export default DemoChain;
