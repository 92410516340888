import { observable, action } from 'mobx';
import { statefulApiCall, apiPost, apiGet, apiPut } from 'utils/httpUtils';
import STATES from 'constants/storeStates';

class OrderStore {
  @observable state = STATES.READY; // ready / pending / error

  @action
  async createOrder(data) {
    return await statefulApiCall(this, () => apiPost('order', data));
  }

  @action
  async getOrder(orderNumber) {
    return await statefulApiCall(this, () => apiGet(`order/${orderNumber}`));
  }

  @action
  async getAllOrders() {
    return await statefulApiCall(this, () => apiGet('order/all'));
  }

  @action
  async updateOrderStatus(orderNumber, status) {
    return await statefulApiCall(this, () =>
      apiPut(`order/${orderNumber}/status/${status}`)
    );
  }
}

export default new OrderStore();
