import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './CheckboxElement.sass';

const CheckboxElement = ({
  name,
  value,
  className,
  children,
  dark,
  darkBorder,
  ...props
}) => {
  const wrapperClasses = classNames({
    [styles.wrapper]: true,
    [className]: !!className,
  });
  const labelClasses = classNames({
    [styles.label]: true,
    [styles.labelWithValue]: value,
    [styles.labelDark]: dark,
    [styles.labelDarkBorder]: darkBorder,
  });

  return (
    <div className={wrapperClasses}>
      <input
        type={'checkbox'}
        checked={value}
        name={name}
        {...props}
        className={styles.input}
      />
      <label className={labelClasses} htmlFor={name} />
      <div className={styles.caption}>{children}</div>
    </div>
  );
};

CheckboxElement.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dark: PropTypes.bool,
  darkBorder: PropTypes.bool,
};

CheckboxElement.defaultProps = {
  darkBorder: true,
};

export default CheckboxElement;
