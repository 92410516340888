import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Loader.sass';

const Loader = ({ small, className }) => {
  const wrapperClasses = classNames({
    [styles.loaderWrapper]: true,
    [className]: !!className,
  });

  return (
    <div className={wrapperClasses}>
      <svg
        className={small ? styles.loaderSmall : styles.loader}
        version={'1.1'}
        x={'0px'}
        y={'0px'}
        viewBox={'0 0 52 12'}
        enableBackground={'new 0 0 0 0'}
      >
        <circle stroke={'none'} cx={6} cy={6} r={6}>
          <animate
            attributeName={'opacity'}
            dur={'1s'}
            values={'0;1;0'}
            repeatCount={'indefinite'}
            begin={-0.5}
          />
        </circle>
        <circle stroke={'none'} cx={26} cy={6} r={6}>
          <animate
            attributeName={'opacity'}
            dur={'1s'}
            values={'0;1;0'}
            repeatCount={'indefinite'}
            begin={-0.3}
          />
        </circle>
        <circle stroke={'none'} cx={46} cy={6} r={6}>
          <animate
            attributeName={'opacity'}
            dur={'1s'}
            values={'0;1;0'}
            repeatCount={'indefinite'}
            begin={-0.1}
          />
        </circle>
      </svg>
    </div>
  );
};

Loader.propTypes = {
  small: PropTypes.bool,
  className: PropTypes.string,
};

export default Loader;
