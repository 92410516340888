import React from 'react';

import styles from '../page.sass';

import GenericHeader from 'components/common/banners/GenericHeader';

import PrivacyPolicyContent from './PrivacyPolicy';

const PPSections = [
  'What Do We Do With Your Information?',
  'Consent',
  'Disclosure',
  'Stripe',
  'Third-Party Services',
  'Security',
  'Age Of Consent',
  'Changes To This Privacy Policy',
  'Questions And Contact Information',
];

const PrivacyPolicy = () => (
  <div>
    <GenericHeader title={'Privacy Policy'} />

    <div className={styles.wrapper}>
      <ol className={styles.jumpList}>
        {PPSections.map((sectionName, i) => (
          <li key={i}>
            <a href={`#pp-${i}`}>{sectionName}</a>
          </li>
        ))}
      </ol>

      <PrivacyPolicyContent />
    </div>
  </div>
);

export default PrivacyPolicy;
