import { observable, action, computed } from 'mobx';
import { statefulApiCall, apiGet } from 'utils/httpUtils';
import STATES from 'constants/storeStates';
import { CAN_USE_SESSION_STORAGE } from 'constants/featureAvailibility';

const DISCOUNT_PATTERN = {
  code: '',
  percentageOff: 0,
  freeShipping: false,
};

class CartStore {
  GTMTracking = undefined;
  @observable state = STATES.READY; // ready / pending / error
  @observable shippingCountry = 'GB';
  @observable products = [];
  @observable discount = Object.assign({}, DISCOUNT_PATTERN);

  constructor(tracker) {
    this.fetchCart();
    this.GTMTracking = tracker;
  }

  @computed
  get isEmpty() {
    return this.products.length === 0;
  }

  @computed
  get productsWithQuantities() {
    return this.products
      .slice(0)
      .reduce((items, currentProduct) => {
        // group items together by slug and material
        let uniqueKey = `${currentProduct.design.slug}${
          currentProduct.material
        }`;
        let el = items.find((item) => item && item.key === uniqueKey);
        if (el) {
          el.values.push(currentProduct);
        } else {
          items.push({ key: uniqueKey, values: [currentProduct] });
        }
        return items;
      }, [])
      .map((item) => {
        // reduce to one items with a summative quantity
        return Object.assign({}, item.values[0], {
          quantity: item.values.length,
        });
      })
      .sort((a, b) => {
        // alphabetically sort by slug
        if (a.design.slug < b.design.slug) return -1;
        if (a.design.slug > b.design.slug) return 1;
        return 0;
      });
  }

  @computed
  get complimentaryOrder() {
    return (
      this.discount &&
      this.discount.percentageOff === 100 &&
      this.discount.freeShipping
    );
  }

  @computed
  get shippingCost() {
    return this.shippingCountry === 'GB' || this.discount.freeShipping ? 0 : 9;
  }

  @computed
  get subtotalWithoutDiscount() {
    return this.products.reduce((sum, product) => sum + product.price, 0);
  }

  @computed
  get subtotal() {
    return this.discount.id
      ? (this.subtotalWithoutDiscount * (100 - this.discount.percentageOff)) /
          100
      : this.subtotalWithoutDiscount;
  }

  @computed
  get totalCost() {
    return this.shippingCost + this.subtotal;
  }

  @computed
  get orderDescription() {
    return this.products
      .map(
        (product) =>
          `${product.design.name ? product.design.name : 'Custom'} (${
            product.material
          })`
      )
      .join(', ');
  }

  @action
  addToCart = (product) => {
    // add a copy of the product to the products array
    this.products.push(Object.assign({}, product));

    this.GTMTracking.trackEvent('addToCart', {
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [
            {
              name: product.design.name,
              id: product.design._id,
              price: product.price.toString(),
              category: product.design.productType,
              variant: product.material,
              quantity: 1,
            },
          ],
        },
      },
    });

    // window.fbq('track', 'AddToCart', {
    //   content_name: '{{AddToCart Product 1 Name}}',
    //   content_category: '{{AddToCart Product 1 Category}}',
    //   content_type: 'product',
    //   value: parseFloat('{{ AddToCart Product 1 Price }}'),
    //   currency: 'GBP',
    //   content_ids: ['{{AddToCart Product 1 ID}}-{{AddToCart Product 1 Variant}}'],
    // });

    this.saveCart();
  };

  @action
  removeFromCart = (product) => {
    const removalIndex = this.products.findIndex(
      (findingProduct) =>
        findingProduct.design._id === product.design._id &&
        findingProduct.material === product.material
    );

    this.products.splice(removalIndex, 1);

    this.GTMTracking.trackEvent('removeFromCart', {
      ecommerce: {
        currencyCode: 'EUR',
        remove: {
          products: [
            {
              name: product.design.name,
              id: product.design._id,
              price: product.price.toString(),
              category: product.design.productType,
              variant: product.material,
              quantity: 1,
            },
          ],
        },
      },
    });

    this.saveCart();
  };

  @action
  setShippingCountry = (countryCode) => {
    this.shippingCountry = countryCode;
    this.saveCart();
  };

  @action
  updateDiscountCode = (value) => {
    this.discount.code = value;
  };

  @action
  testDiscountCode = async (code) => {
    code = (code || this.discount.code).toUpperCase();
    return await statefulApiCall(this, () => apiGet(`discount/${code}`)).then(
      (discount) => {
        if (!discount || !discount.code) return;
        this.discount = {
          code: discount.code,
          percentageOff: discount.percentageOff,
          freeShipping: discount.freeShipping,
          id: discount._id,
        };
        this.saveCart();
        return discount;
      }
    );
  };

  @action
  saveCart = () => {
    if (!CAN_USE_SESSION_STORAGE) return;

    // destructure store data for just the bits we want
    const cartData = (({ products, shippingCountry }) => ({
      products,
      shippingCountry,
    }))(this);

    sessionStorage.setItem('cart', JSON.stringify(cartData));
  };

  @action
  fetchCart = () => {
    if (!CAN_USE_SESSION_STORAGE) return;

    const cartSessionData = sessionStorage.getItem('cart');
    if (!cartSessionData) return;

    const cartData = JSON.parse(cartSessionData);
    // map data keys to store keys
    Object.keys(cartData).forEach((key) => {
      this[key] = cartData[key];
    });
  };

  @action
  emptyCart = () => {
    this.products = [];
    this.discount = Object.assign({}, DISCOUNT_PATTERN);
  };

  getCartSummary = () => {
    const cartData = (({
      productsWithQuantities,
      subtotalWithoutDiscount,
      subtotal,
      shippingCost,
      totalCost,
      discount,
    }) => ({
      productsWithQuantities,
      subtotalWithoutDiscount,
      subtotal,
      shippingCost,
      totalCost,
      discount,
    }))(this);

    return cartData;
  };
}

export default CartStore;
