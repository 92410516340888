import {
  initialiseHexagons,
  clearHexagons,
  updateHexagons,
  eachHexagon,
} from '../utils/hexagonUtils';
import { downloadJSON, exportCanvasData } from '../utils/exportUtils';
import { importDesign, importCanvasData } from '../utils/importUtils';
import { PRODUCT_TYPES } from 'constants/productTypes';

import SystemSettingsStore from './SystemSettingsStore';
import SystemSprueStore from './SystemSprueStore';
import SystemUIStore from './SystemUIStore';
import SystemHistoryStore from './SystemHistoryStore';
import UIStore from 'stores/ui/UIStore';
import config from '../config';

class SystemStore {
  hexagonsInitialised = false;
  columns = undefined;
  rows = undefined;
  hexagons = [];

  canvas = undefined;
  demo = undefined;

  settings = new SystemSettingsStore();
  sprues = new SystemSprueStore();
  ui = new SystemUIStore();
  globalUi = UIStore;
  history = new SystemHistoryStore();
  config = config;

  curveMappingId = '5a65cfe68d2fe42750471d97';
  productType = PRODUCT_TYPES.PENDANT;

  mouseTargetHex = undefined;
  mouseTargetHexLast = undefined;
  setMouseTargetHex = (hex) => {
    this.mouseTargetHex = hex;
  };
  updateLastMouseTargetHex = () => {
    this.mouseTargetHexLast = this.mouseTargetHex;
  };
  resetLastMouseTargetHex = () => {
    this.mouseTargetHexLast = undefined;
  };

  initialiseHexagons = (rows, columns) =>
    initialiseHexagons(this, rows, columns);
  clearHexagons = () => clearHexagons(this);
  updateHexagons = (checkMouse) => updateHexagons(this, checkMouse);
  eachHexagon = (each) => eachHexagon(this, each);

  downloadJSON = (withSettings) => downloadJSON(this, withSettings);
  exportCanvasData = (trimFat) => exportCanvasData(this, trimFat);
  importDesign = (data) => importDesign(this, data);
  importCanvasData = (data) => importCanvasData(this, data);

  addToHistory = () => this.history.addToHistory(this);
  undo = () => this.history.undo(this);
  redo = () => this.history.redo(this);

  calculatePrice = (curves) => this.ui.calculatePrice(this, curves);
}

export default SystemStore;
