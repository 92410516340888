import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';

import SystemDemo from 'system/Demo';

import styles from './Demo.sass';

@inject('SystemStore')
@observer
class Demo extends Component {
  constructor(props) {
    super(props);
    this.demoElement = undefined;
    this.demoWrapperElement = undefined;
  }

  componentDidMount() {
    const store = this.props.SystemStore;

    store.demo = new SystemDemo(store);
    store.demo.setup(this.demoElement, this.demoWrapperElement);

    // resize demo when window size is changed
    // and mobile tabs are changed
    // debounced by 50ms
    this.windowSizeReaction = reaction(
      () => [
        store.globalUi.windowWidth,
        store.globalUi.windowHeight,
        store.ui.demoVisibleOnMobile,
      ],
      () => this.resizeDemo(),
      {
        delay: 50,
      }
    );

    // render demo and update chain position when settings are changed
    this.settingsReaction = reaction(
      () => [
        store.settings.depthOverlapScalar,
        store.settings.depthCurvatureScalar,
      ],
      () => this.reactToSettingsChange()
    );

    // render demo and update chain material when settings are changed
    this.materialReaction = reaction(
      () => [store.settings.material],
      () => this.reactToMaterialChange()
    );

    // check mouse position
    // toggle hover state and update hanging point
    this.mouseReaction = reaction(
      () => [store.globalUi.mouseY, store.globalUi.mouseX],
      () => this.checkMousePosition()
    );

    // for import triggers
    this.importTriggerReaction = reaction(
      () => [store.ui.shouldDemoRenderFromImport],
      () => {
        if (!store.ui.shouldDemoRenderFromImport) return;
        this.renderCanvasFromImport();
        store.ui.demoHasRenderedFromImport();
      }
    );
  }

  componentWillUnmount = () => {
    const { SystemStore } = this.props;

    SystemStore.ui.demoHasBeenEmptied();

    // dispose of reactions
    this.windowSizeReaction();
    this.settingsReaction();
    this.materialReaction();
    this.mouseReaction();
    this.importTriggerReaction();

    SystemStore.demo.destroy();
    SystemStore.demo = undefined;
  };

  checkMousePosition = () => {
    // sometimes this tries to happen before there is anything
    if (!this.demoWrapperElement) return;

    const store = this.props.SystemStore;

    const boundingBox = this.demoWrapperElement.getBoundingClientRect();
    if (
      boundingBox.left <= store.globalUi.mouseX &&
      store.globalUi.mouseX <= boundingBox.right &&
      boundingBox.top <= store.globalUi.mouseY &&
      store.globalUi.mouseY <= boundingBox.bottom
    ) {
      if (!store.ui.isMouseOverDemo) store.ui.mouseIsOverDemo();
    } else {
      if (store.ui.isMouseOverDemo) store.ui.mouseNotOverDemo();
    }

    if (store.ui.isChosingHangingPoint) store.demo.updateHangingPointAngle();
  };

  reactToSettingsChange = () => {
    const { SystemStore } = this.props;
    // don't update the chain or render anything if we're importing
    if (!SystemStore.demo || SystemStore.ui.isImporting) return;
    // use raycasting to position chain instead of edge points
    SystemStore.demo.chain.updatePosition(0, true);
    this.renderDemo(false);
  };

  reactToMaterialChange = () => {
    const { SystemStore } = this.props;
    // don't do anything if we're importing
    if (!SystemStore.demo || SystemStore.ui.isImporting) return;
    SystemStore.demo.chain.updateMaterial();
    this.renderDemo(false);
  };

  renderDemo = (animate) => {
    const { demo } = this.props.SystemStore;
    if (!demo) return;

    if (animate) {
      demo.updateAndAnimateCurves();
    } else {
      demo.updateCurves();
    }
  };

  renderCanvasFromImport = () => {
    const { demo } = this.props.SystemStore;
    if (!demo) return;
    demo.updateCurvesFromImport();
  };

  resizeDemo = () => {
    const { demo } = this.props.SystemStore;
    if (!demo || !this.demoWrapperElement) return;
    demo.updateDimensions(this.demoWrapperElement);
  };

  render() {
    return (
      <div
        className={styles.demoWrapper}
        ref={(element) => (this.demoWrapperElement = element)}
      >
        <canvas
          className={styles.canvas}
          ref={(element) => (this.demoElement = element)}
        />
      </div>
    );
  }
}

Demo.propTypes = {
  SystemStore: PropTypes.object,
};

export default Demo;
