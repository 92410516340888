import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import Dropdown from 'components/common/Dropdown';

import styles from './FilterNav.sass';

import { LIVE_MATERIAL_OPTIONS } from 'constants/systemOptions';
const SORT_OPTIONS = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Price Ascending',
    value: 'priceAscending',
  },
  {
    label: 'Price Descending',
    value: 'priceDescending',
  },
];

@inject('UIStore', 'ShopStore', 'SystemStore')
@observer
class FilterNav extends Component {
  constructor(props) {
    super(props);
  }

  onMaterialSelected = (i) => {
    this.materialFilterIndex = i;
    this.props.SystemStore.settings.setMaterial(LIVE_MATERIAL_OPTIONS[i].value);
    this.props.ShopStore.setMaterialFilter(LIVE_MATERIAL_OPTIONS[i].value);
    this.checkLocation();
  };

  onSortOrderSelected = (i) => {
    this.props.ShopStore.setSortOrder(SORT_OPTIONS[i].value);
    this.checkLocation();
  };

  checkLocation = () => {
    const { location, history } = this.props;
    if (location.pathname !== '/shop') history.push('/shop');
  };

  render() {
    const { ShopStore, UIStore } = this.props;

    const materialButtonClasses = (material) =>
      classNames({
        [styles.navButton]: true,
        [styles.navButtonActive]: material === ShopStore.materialFilter,
      });
    const sortButtonClasses = (order) =>
      classNames({
        [styles.navButton]: true,
        [styles.navButtonActive]: order === ShopStore.sortOrder,
      });

    const materialIndex = ShopStore.materialFilter
      ? LIVE_MATERIAL_OPTIONS.findIndex(
          (material) => material.value === ShopStore.materialFilter
        )
      : -1;
    const sortOptionIndex = ShopStore.sortOrder
      ? SORT_OPTIONS.findIndex((order) => order.value === ShopStore.sortOrder)
      : -1;

    return UIStore.onMobile ? (
      <div className={styles.mobileNav}>
        <Dropdown
          options={LIVE_MATERIAL_OPTIONS}
          currentOptionIndex={materialIndex}
          unselectedLabel={'Material'}
          onChange={this.onMaterialSelected}
          labelClassName={styles.dropdown}
        />
        <Dropdown
          options={SORT_OPTIONS}
          currentOptionIndex={sortOptionIndex}
          unselectedLabel={'Sort'}
          onChange={this.onSortOrderSelected}
          labelClassName={styles.dropdown}
        />
      </div>
    ) : (
      <nav className={styles.nav}>
        <ol className={styles.navList}>
          <h2 className={styles.navListTitle}>Material</h2>
          {LIVE_MATERIAL_OPTIONS.map((option, i) => (
            <li key={i} className={styles.navListItem}>
              <button
                className={materialButtonClasses(option.value)}
                onClick={() => this.onMaterialSelected(i)}
              >
                {option.label}
              </button>
            </li>
          ))}
        </ol>

        <ol className={styles.navList}>
          <h2 className={styles.navListTitle}>Sort</h2>
          {SORT_OPTIONS.map((option, i) => (
            <li key={i} className={styles.navListItem}>
              <button
                className={sortButtonClasses(option.value)}
                onClick={() => this.onSortOrderSelected(i)}
              >
                {option.label}
              </button>
            </li>
          ))}
        </ol>
      </nav>
    );
  }
}

FilterNav.propTypes = {
  UIStore: PropTypes.object,
  ShopStore: PropTypes.object,
  SystemStore: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(FilterNav);
