import { map, clamp } from 'utils/numberUtils';

export const getSpinSpeed = (angle, config, completeSpin) => {
  // ramp down from spin speed to rotate speed
  const normalSpeed = config.cameraRotateSpeed * config.cameraDampingFactor;
  const speed = map(
    angle,
    Math.PI,
    0,
    config.cameraSpinSpeed * config.cameraDampingFactor,
    normalSpeed,
    true
  );

  // end spin when speed is clamped to normal
  if (speed === normalSpeed) {
    completeSpin();
  }

  return speed;
};

export const initialiseAnimation = (demo, curves, centralCurve) => {
  demo.animatingCurves = curves;
  demo.animationStep =
    demo.store.config.animationStep / demo.store.config.animationRangeMax;
  demo.animationRangeMax = demo.store.config.animationRangeMax;

  // run through the animation to get number of frames for complete animation
  if (!demo.store.globalUi.onMobile) {
    startAnimation(demo, centralCurve);
    let animationFrames = 0;
    let isAnimating = true;
    while (isAnimating) {
      animationFrames++;
      updateAnimation(demo, false, () => {
        isAnimating = false;
      });
    }

    // if speed = 1 it's 12s per round when fps is 60
    const rounds =
      animationFrames / ((60 * 12) / demo.controls.autoRotateSpeed);
    // rotation goes from -PI to +PI, front-facing at 0
    const rotation = ((rounds + 0.5) % 1) * 2 * Math.PI - Math.PI;
    demo.controls.resetAtAngle(rotation);
  }

  // actually start animation
  startAnimation(demo, centralCurve);
};

const startAnimation = (demo, startingCurve) => {
  if (demo.animatingCurves.length === 0) return;

  demo.store.ui.demoAnimationStarted();

  demo.animatingCurves.forEach((curve) => {
    // start at invisible
    curve.tubeMesh.geometry.setDrawRange(0, 0);

    curve.animationProgress = 0;
    curve.isAnimating = false;
    curve.isAnimatingFromStart = false;
    curve.isAnimatingFromEnd = false;
    curve.isAnimatingFromMiddle = false;
    curve.finishedAnimating = false;

    // hide any capping spheres
    if (curve.start.sphereMesh) curve.start.sphereMesh.visible = false;
    if (curve.end.sphereMesh) curve.end.sphereMesh.visible = false;
  });

  startingCurve.isAnimating = true;
  startingCurve.isAnimatingFromMiddle = true;
};

export const updateAnimation = (
  demo,
  setDrawRange,
  completionCallback = endAnimation
) => {
  // race conditions ?
  if (demo.animatingCurves === undefined) return;

  demo.animatingCurves.forEach((curve) => {
    // skip if it's not animating at all
    if (!curve.isAnimating) return;

    // increase progress according to speed and curve length
    curve.animationProgress = clamp(
      curve.animationProgress +
        (demo.animationStep * demo.store.config.animationSpeed) /
          curve.estLength,
      0,
      1
    );
    // round the progress to the nearest step
    const steppedProgress =
      Math.round(curve.animationProgress / demo.animationStep) *
      demo.animationStep;

    // set the ranges
    if (setDrawRange) {
      if (curve.isAnimatingFromStart) {
        curve.tubeMesh.geometry.setDrawRange(
          0,
          Math.round(steppedProgress * demo.animationRangeMax)
        );
      } else if (curve.isAnimatingFromEnd) {
        curve.tubeMesh.geometry.setDrawRange(
          Math.round((1 - steppedProgress) * demo.animationRangeMax),
          demo.animationRangeMax
        );
      } else if (curve.isAnimatingFromMiddle) {
        curve.tubeMesh.geometry.setDrawRange(
          Math.round(((1 - steppedProgress) * demo.animationRangeMax) / 2),
          Math.round(steppedProgress * demo.animationRangeMax)
        );
      }
    }

    if (curve.animationProgress >= 1)
      finishCurveAnimation(curve, demo.animationRangeMax);
  });

  // end animation if there aren't any curves left animating
  if (demo.animatingCurves.filter((curve) => curve.isAnimating).length === 0) {
    completionCallback(demo);
  }
};

const endAnimation = (demo) => {
  demo.store.ui.demoAnimationEnded();

  // start chosing hanging point on mobile
  if (demo.store.globalUi.onMobile) {
    demo.startChosingHangingPoint();
  }
};

const finishCurveAnimation = (curve, animationRangeMax) => {
  // start the dominoes
  if (curve.isAnimatingFromStart) {
    triggerAnimationFromCap(curve.end);
    curve.isAnimatingFromStart = false;
  } else if (curve.isAnimatingFromEnd) {
    triggerAnimationFromCap(curve.start);
    curve.isAnimatingFromEnd = false;
  } else if (curve.isAnimatingFromMiddle) {
    triggerAnimationFromCap(curve.start);
    triggerAnimationFromCap(curve.end);
    curve.isAnimatingFromMiddle = false;
  }

  // make sure it's set at max
  curve.tubeMesh.geometry.setDrawRange(0, animationRangeMax);
  curve.isAnimating = false;
  curve.finishedAnimating = true;
};

const triggerAnimationFromCap = (cap) => {
  if (cap.extenders.length === 0) {
    // make the capping sphere visible
    cap.sphereMesh.visible = true;
    return;
  }

  cap.extenders.forEach((extenderCap) => {
    // don't try if it's already been hit
    if (extenderCap.curve.isAnimating || extenderCap.curve.finishedAnimating)
      return;
    if (extenderCap === extenderCap.curve.start) {
      extenderCap.curve.isAnimatingFromStart = true;
    } else {
      extenderCap.curve.isAnimatingFromEnd = true;
    }
    extenderCap.curve.isAnimating = true;
  });

  // we have to set off the aligners as well or we might miss something
  cap.aligners.forEach((alignerCap) => {
    // don't try if it's already been hit
    if (alignerCap.curve.isAnimating || alignerCap.curve.finishedAnimating)
      return;
    if (alignerCap === alignerCap.curve.start) {
      alignerCap.curve.isAnimatingFromStart = true;
    } else {
      alignerCap.curve.isAnimatingFromEnd = true;
    }
    alignerCap.curve.isAnimating = true;
  });
};
