import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject('UIStore')
@observer
class HeaderInvertBanner extends Component {
  wrapperElement = undefined;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { UIStore } = this.props;

    this.scrollReaction = reaction(() => UIStore.scrollY, this.windowScrolled);

    // make header white
    UIStore.invertHeader();
  }

  componentWillUnmount() {
    const { UIStore } = this.props;
    UIStore.uninvertHeader();
    UIStore.showHeaderLinks();
    // dispose of reactions
    this.scrollReaction();
  }

  windowScrolled = () => {
    const { UIStore } = this.props;
    if (UIStore.scrollY + 24 > this.wrapperElement.clientHeight) {
      UIStore.uninvertHeader();
      UIStore.hideHeaderLinks();
    } else {
      UIStore.invertHeader();
      UIStore.showHeaderLinks();
    }
  };

  render() {
    const { children } = this.props;

    return (
      <div ref={(element) => (this.wrapperElement = element)}>{children}</div>
    );
  }
}

HeaderInvertBanner.propTypes = {
  UIStore: PropTypes.object,
  children: PropTypes.node,
};

export default HeaderInvertBanner;
