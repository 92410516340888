import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

const asyncComponent = (importComponentFunction) => {
  @observer
  class AsyncComponent extends Component {
    @observable TargetComponent = null;

    constructor(props) {
      super(props);
    }

    async componentDidMount() {
      const { default: component } = await importComponentFunction();
      this.TargetComponent = component;
    }

    render() {
      return this.TargetComponent ? (
        <this.TargetComponent {...this.props} />
      ) : null;
    }
  }

  return AsyncComponent;
};

export default asyncComponent;
