import React from 'react';
import PropTypes from 'prop-types';

import styles from './Header.sass';

const BelowHeader = ({ children }) => (
  <div className={styles.belowHeader}>{children}</div>
);

BelowHeader.propTypes = {
  children: PropTypes.node,
};

export default BelowHeader;
